import { RacemapV2APIClient } from '@racemap/sdk';
import { FC } from 'react';
import useSWR from 'swr';
import { SuggestionsList } from '../BasicComponents/AdvancedSearchBar';

const apiClient = RacemapV2APIClient.fromWindowLocation();
const getPossibleQuestions = () => apiClient.getPossibleQuestions();

interface Props {
  onQuestionClick: (question: string) => void;
}

export const PossibleQuestionsList: FC<Props> = ({ onQuestionClick }) => {
  const { data: suggestions, isLoading } = useSWR(
    '/api/v1/support/questions',
    getPossibleQuestions,
  );

  if (!suggestions) return null;

  return (
    <SuggestionsList
      suggestions={suggestions.questions}
      title="You might be interested in"
      isLoading={isLoading}
      onSuggestionsClick={(suggestion) => onQuestionClick(suggestion)}
      limit={10}
    />
  );
};
