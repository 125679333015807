import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { isNotEmptyString } from '@racemap/utilities/functions/utils';
import { Input, Modal } from 'antd';
import type { InputRef } from 'antd';
import React, { FC, PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import { useEventListener } from 'usehooks-ts';
import { useControlled } from '../../../lib/customHooks';
import { IconSearch } from '../../Icon';
import { LoadingIndicator } from '../LoadingIndicator';
import { Center } from '../MetaComponent';
import { ErrorMessage } from './ErrorMessage';
import { PseudoInput } from './PseudoInput';

interface Props {
  show?: boolean;
  fetching?: boolean;
  errorMessage?: string;
  query: string;
  onQueryChange: (query: string) => void;
  children?: Array<React.ReactNode>;
  size?: 'small' | 'large';
  reactOnShortcut?: boolean;
}

export const AdvancedSearchBar: FC<PropsWithChildren<Props>> = ({
  show,
  fetching = false,
  errorMessage = '',
  size = 'large',
  query,
  onQueryChange,
  children,
  reactOnShortcut = true,
}) => {
  const [showControlled, setShow] = useControlled({
    controlledValue: show,
    initialValue: false,
    name: 'show',
  });
  const inputRef = useRef<InputRef>(null);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!reactOnShortcut) return;
      if (e.key === 'k' && e.metaKey) {
        e.preventDefault();
        setShow(true);
      }
    },
    [showControlled, reactOnShortcut],
  );

  useEventListener('keydown', handleKeyDown);

  useEffect(() => {
    if (!showControlled) return;
    inputRef.current?.focus({ cursor: 'start' });
  }, [showControlled]);

  return (
    <>
      <PseudoInput
        onClick={() => setShow(true)}
        text={size === 'large' ? 'Ask for help...' : 'Help'}
      />
      <SearchModal
        open={showControlled}
        onCancel={() => setShow(false)}
        closable={false}
        footer={null}
      >
        <SearchInput
          bordered={false}
          placeholder="Ask or search..."
          value={query}
          onChange={(e) => onQueryChange(e.target.value)}
          prefix={<IconSearch color={RacemapColors.PaleBlue} />}
          size="large"
          ref={inputRef}
        />
        {isNotEmptyString(errorMessage) ? (
          <ErrorMessage message={errorMessage} />
        ) : fetching ? (
          <Center>
            <LoadingIndicator borderless text="Fetching Search Results..." />
          </Center>
        ) : (
          children
        )}
      </SearchModal>
    </>
  );
};

const SearchModal = styled(Modal)`
.ant-modal-content {
    padding: 5px 10px;
}
`;

const SearchInput = styled(Input)`
    box-shadow: none !important;
    
    input {
        color: rgba(0, 0, 0, 0.85);
    }
`;
