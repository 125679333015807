import humanizeDuration from 'humanize-duration';
import { Duration } from 'luxon';

const shortHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'yr',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'hr',
      m: () => 'min',
      s: () => 'sec',
      ms: () => 'ms',
    },
  },
});

export const getHumanReadableIntervalMillis = (
  millis: number,
  options = { short: false },
): string => {
  const humanizer = options.short ? shortHumanizer : humanizeDuration;
  return humanizer(millis, {
    largest: 2,
    units: ['y', 'mo', 'd', 'h', 'm'],
    round: true,
    language: options.short ? 'shortEn' : 'en',
  });
};

export const getHumanReadableIntervalDuration = (
  duration: Duration,
  options = { short: false },
): string => {
  return getHumanReadableIntervalMillis(duration.toMillis(), options);
};
