import styled from '@emotion/styled';
import { MaxMobileWindowWidth } from '@racemap/utilities/consts/common';
import { EventTypes } from '@racemap/utilities/consts/events';
import TestSelectors from '@racemap/utilities/consts/test_selectors';
import { isActivated } from '@racemap/utilities/functions/event';
import {
  eventIsNeitherFreeNorPaid,
  isAtomicEvent,
  isChildEvent,
  isGroupEvent,
} from '@racemap/utilities/functions/utils';
import { Space } from 'antd';
import { Immutable } from 'immer';
import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getMonitorUrl, getPlayerUrl, getTimingUrl } from '../../../lib/EventUtils';
import { useCurrentUser } from '../../../lib/customHooks';
import { CurrentEvent } from '../../../store/events/events_reducers';
import { CopyButton } from '../../BasicComponents/CopyButton';
import { PopoverHint } from '../../BasicComponents/PopoverHint';
import { IconLeaderboard, IconMap, IconMonitor } from '../../Icon';
import { PayButton } from './PayButton';

interface Props {
  event: Immutable<CurrentEvent>;
}

export const Buttons: FC<Props> = ({ event }) => {
  const user = useCurrentUser();

  if (user == null) return <></>;
  const {
    modules: { timing, monitor },
  } = event;

  return (
    <Container>
      {isChildEvent(event) && <GroupReturnButton event={event} />}
      <PlayerButton event={event} />

      {isActivated(timing) && <LeaderboardButton event={event} />}
      {isActivated(monitor) && <MonitorButton event={event} />}

      {isAtomicEvent(event) && eventIsNeitherFreeNorPaid(event) && (
        <PayButton event={event} user={user} />
      )}
    </Container>
  );
};

export const PlayerButton: FC<{ event: Immutable<CurrentEvent> }> = ({ event }) => {
  const label = (
    <Space size={4}>
      {eventIsNeitherFreeNorPaid(event) ? 'Preview' : 'Tracking Map'}
      {isGroupEvent(event) && (
        <PopoverHint placement="bottom">
          On desktop applications, the group URL preselects specific single events, automatically.
          During the event, ALL RUNNING single events in the group are selected, automatically.
          After the event, the 1st single event in group settings is selected, automatically.
        </PopoverHint>
      )}
    </Space>
  );
  return (
    <CopyButton
      link={getPlayerUrl(event)}
      icon={IconMap}
      label={label}
      className={TestSelectors.admin.eventEditor.titleBar.previewButton.toString()}
    />
  );
};

const LeaderboardButton: FC<{ event: Immutable<CurrentEvent> }> = ({ event }) => (
  <CopyButton icon={IconLeaderboard} link={getTimingUrl(event)} label="Leaderboard" />
);

const MonitorButton: FC<{ event: Immutable<CurrentEvent> }> = ({ event }) => (
  <CopyButton link={getMonitorUrl(event)} icon={IconMonitor} label="Monitor" />
);

const GroupReturnButton: FC<{ event: Immutable<CurrentEvent> }> = ({ event }) => {
  const navigate = useNavigate();

  return (
    <Button
      variant="outline-primary"
      style={{ display: 'flex', alignItems: 'center' }}
      onClick={() => navigate(`/admin/events/${event.parent}`)}
    >
      Go to {event.type === EventTypes.STAGE ? ' stage ' : ' contest '} group
    </Button>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  @media (max-width: ${MaxMobileWindowWidth}px) {
    margin: 2px 0;
    .btn {
      height: 25px;
    }

    .btn-group {
      height: 25px;
    }

    .hint {
      display: none;
    }

    .copy-button-action-button {
      display: none;
    }

    .copy-button-link-button {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
  }
`;
