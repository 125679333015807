import { ModulesStates, VisibilityStates } from '@racemap/utilities/consts/events';
import { OneMinuteInMillis, OneSecondInMillis } from '@racemap/utilities/consts/time';
import { Spin } from 'antd';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router';
import { useInterval } from 'usehooks-ts';
import { useDocumentTitle } from '../lib/customHooks/useDocumentTitle';
import { useMonitorStore } from '../store/monitor/monitorStore';

type Params = {
  eventSlug: string;
};

export function MonitorView() {
  const { loadData, loadLiveData, resetStore } = useMonitorStore((s) => s.actions);
  const { eventSlug } = useParams<Params>();
  const event = useMonitorStore((s) => s.event);
  const isLoading = useMonitorStore((s) => s.isLoadingData);
  useDocumentTitle(`${event?.name != null ? event?.name : 'Racemap'} | Monitor`);

  useEffect(() => {
    if (eventSlug == null) return;
    if (event != null) {
      resetStore();
    }
    loadData(eventSlug);
  }, [eventSlug]);

  useInterval(async () => {
    if (eventSlug == null) return;
    loadData(eventSlug);
  }, OneMinuteInMillis);

  useInterval(async () => {
    loadLiveData();
  }, OneSecondInMillis * 10);

  if (isLoading || event == null) return <Spin size="large" />;

  if (event.visibility === VisibilityStates.ARCHIVED) {
    return <div>This Monitor has been archived.</div>;
  }
  if (event.modules.monitor.state !== ModulesStates.ACTIVATED) {
    return <div>This Monitor is disabled.</div>;
  }

  return <Outlet />;
}
