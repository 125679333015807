import { RacemapAPIClient } from '@racemap/utilities/api-client';
import { ID } from '@racemap/utilities/types/utils';
import React, { useState } from 'react';
import { CSSProperties } from 'react';
import { Navigate } from 'react-router';
import { toast } from 'react-toastify';
import Button from './Button';
import { IconTrash } from './Icon';

const apiClient = RacemapAPIClient.fromWindowLocation();

type RemoveContestGroupProps = {
  contestGroupId: ID;
  pureIcon?: boolean;
  className?: string;
  style?: CSSProperties;
};

const RemoveContestGroup = function ({
  contestGroupId,
  pureIcon = false,
  ...props
}: RemoveContestGroupProps) {
  const [isRemoving, setIsRemoving] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);

  const handleRemoveContestGroup = async () => {
    if (confirm('Do you really want to delete this contest group?')) {
      try {
        setIsRemoving(true);
        await apiClient.removeEvent(contestGroupId);
        setIsRemoving(false);
        setIsRemoved(true);
      } catch (e) {
        toast.error(e.message);
        setIsRemoving(false);
      }
    }
  };

  if (isRemoved) {
    return <Navigate to="/admin" />;
  }

  if (pureIcon) return <IconTrash onClick={() => handleRemoveContestGroup()} {...props} />;

  return (
    <Button.Trash
      label="Remove"
      disabled={isRemoving}
      onClick={() => handleRemoveContestGroup()}
      {...props}
    />
  );
};

export default RemoveContestGroup;
