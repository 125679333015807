import styled from '@emotion/styled';
import React, { FC } from 'react';
import { IconCopy } from '../../Icon';
import { copyIntoClipBoard } from '../../utils/Utils';

interface Props {
  copyValue: string;
  title?: string;
}

export const MiniCopyButton: FC<Props> = ({ copyValue, title }) => {
  const handleCopyClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    copyIntoClipBoard(copyValue);
  };

  return (
    <Container
      onClick={(event) => handleCopyClick(event)}
      title={title}
      className="mini-copy-button"
    >
      <IconCopy />
    </Container>
  );
};

const Container = styled.div`
  cursor: pointer;
  z-index: 10;

  svg:hover {
    transform: scale(1.2);
  }
`;
