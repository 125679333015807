import { ObjectId } from '@racemap/sdk/schema/base';
import { Document } from 'mongoose';
import { UserDocument } from './users';

export enum AlertTypes {
  SOS = 'SOS',
  NO_MOVEMENT = 'NO_MOVEMENT',
  AWAY_FROM_TRACK = 'AWAY_FROM_TRACK',
  LOW_BATTERY = 'LOW_BATTERY',
  UNKNOWN = 'UNKNOWN',
  POWER_OFF = 'POWER_OFF',
  CRASH = 'CRASH',
}

export const AlertLabels: Record<AlertTypes, string> = {
  [AlertTypes.SOS]: 'SOS',
  [AlertTypes.NO_MOVEMENT]: 'No movement',
  [AlertTypes.AWAY_FROM_TRACK]: 'Away from track',
  [AlertTypes.LOW_BATTERY]: 'Low battery',
  [AlertTypes.POWER_OFF]: 'Power off',
  [AlertTypes.UNKNOWN]: 'Unknown',
  [AlertTypes.CRASH]: 'Crash/Fall Accident',
};

export interface AlertRaw {
  type: AlertTypes;
  location?: {
    lat?: number;
    lng?: number;
  };
  information?: string;
}

export type AlertDocument = AlertRaw &
  Document & {
    creatorId?: ObjectId;
    updaterId?: ObjectId;
    eventId?: ObjectId;
    starterId?: ObjectId;
    deviceId?: ObjectId;
    occuredAt: Date;
    createdAt?: Date;
    updatedAt?: Date;
    solvedAt?: Date | null;
    solvedBy?: ObjectId;

    canEdit: (this: AlertDocument, user?: UserDocument | null) => Promise<boolean>;
    canRead: (this: AlertDocument, user?: UserDocument | null) => Promise<boolean>;
    canSolve: (this: AlertDocument, user?: UserDocument | null) => Promise<boolean>;
    solve: (this: AlertDocument, user?: UserDocument) => Promise<void>;
  };
export interface Alert extends AlertRaw {
  id: string;
  updaterId: string;
  occuredAt: string;
  createdAt: string;
  updatedAt: string;
  solvedAt: string | null;
  solvedBy: string | null;
  starterId?: string;
  deviceId?: string;
  eventId?: string;
  creatorId: string;
}

export type Alerts = Array<Alert>;
