import styled from '@emotion/styled';
import { RacemapV2APIClient } from '@racemap/sdk';
import { isNotEmptyString } from '@racemap/utilities/functions/utils';
import { FC, useState } from 'react';
import useSWR from 'swr';
import { useIsMobile } from '../../lib/customHooks';
import {
  AdvancedSearchBar,
  AskQuestionPanel,
  ResultsList,
} from '../BasicComponents/AdvancedSearchBar';
import { PossibleQuestionsList } from './PossibleQuestionsList';

const apiClient = RacemapV2APIClient.fromWindowLocation();
const search = ([_, query]: [string, string]) => apiClient.search({ queries: { query } });

interface Props {
  reactOnShortcut?: boolean; // prevent that multiple search bars are opened if you use multiple on page
}

export const SupportSearchBar: FC<Props> = ({ reactOnShortcut = true }) => {
  const [query, setQuery] = useState('');
  const [question, setQuestion] = useState<string | null>(null);
  const isMobile = useIsMobile();
  const {
    isLoading,
    error,
    data: searchData,
  } = useSWR(isNotEmptyString(query) ? ['search', query] : null, search);

  const searchResults = searchData?.items || [];

  const handleSuggestionClick = (suggestion: string) => {
    if (isNotEmptyString(suggestion)) {
      setQuery(suggestion);
    }
    setQuestion(suggestion);
  };

  const handleQueryChangeBySearchBar = (newQuery: string) => {
    setQuery(newQuery);
    setQuestion(null);
  };

  const handleQuestionChangeByAskQuestionPanel = (newQuestion: string | null) => {
    setQuestion(newQuestion);
    setQuery(newQuestion || '');
  };

  return (
    <Container className="support-search-bar">
      <AdvancedSearchBar
        query={query}
        size={isMobile ? 'small' : 'large'}
        onQueryChange={handleQueryChangeBySearchBar}
        errorMessage={error?.message}
        reactOnShortcut={reactOnShortcut}
      >
        {query.length > 3 && (
          <AskQuestionPanel
            question={question}
            query={query}
            onChange={handleQuestionChangeByAskQuestionPanel}
          />
        )}
        {query.length > 0 && (searchData != null || isLoading) && (
          <ResultsList results={searchResults} isLoading={isLoading} />
        )}
        {query.length === 0 && <PossibleQuestionsList onQuestionClick={handleSuggestionClick} />}
      </AdvancedSearchBar>
    </Container>
  );
};

const Container = styled.div`
  max-width: 220px;
`;
