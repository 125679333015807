import { ObjectId } from '@racemap/sdk/schema/base';
import { BillableItemSchema, EventDayBasedBillableItem } from '@racemap/sdk/schema/billing';
import { BillableItemTypes } from '@racemap/sdk/schema/user';
import { AddOns, AuthorizationStates, ModulesStates } from '@racemap/utilities/consts/events';
import {
  getCurrentPricingVersion,
  getEventCyclesOfTimeRange,
  getPricingRulesOfEvent,
  isEventDayBasedBillableItem,
} from '@racemap/utilities/functions/billing';
import { isGroupEvent } from '@racemap/utilities/functions/utils';
import { isDefined } from '@racemap/utilities/functions/validation';
import { BillableItemObject, RacemapEvent } from '@racemap/utilities/types/events';
import { OptionModules } from '@racemap/utilities/types/events';
import { Immutable } from 'immer';
import { DateTime, Interval } from 'luxon';
import { ConfirmationModal } from '../../components/EventEditor/PaymentModal/ConfirmationModal';
import { EventIsLockedModal } from '../../components/EventEditor/PaymentModal/EventIsLockedModal';
import { presentDialogModal } from '../../components/Modal';
import {
  CurrentEvent,
  FrontendBillableItem,
  FrontendEventDayBasedBillableItem,
} from './events_reducers';

export const isCurrentEvent = (event: unknown): event is CurrentEvent => {
  return (
    event != null &&
    typeof event === 'object' &&
    'id' in event &&
    'name' in event &&
    'childEvents' in event &&
    'creator' in event &&
    typeof event.creator === 'object' &&
    event.creator != null &&
    'id' in event.creator &&
    'editors' in event &&
    'billableItems' in event &&
    Array.isArray(event.billableItems)
  );
};

export const isEventDayBillableItem = (
  item: FrontendBillableItem,
): item is EventDayBasedBillableItem => {
  return item.type === BillableItemTypes.EVENT_DAY;
};

export const isFrontendEventDayBasedBillableItem = (
  item: Immutable<FrontendBillableItem | EventDayBasedBillableItem>,
): item is Immutable<FrontendEventDayBasedBillableItem> => {
  return (
    item.type === BillableItemTypes.EVENT_DAY &&
    'activatedAddons' in item &&
    'startNewCycle' in item &&
    'cycle' in item
  );
};

export const prepareFrontendBillableItems = (
  items: Array<Immutable<BillableItemObject>>,
  event: Immutable<RacemapEvent | CurrentEvent>,
): Array<Immutable<FrontendBillableItem>> => {
  const frontendItems = items
    .sort((a, b) => {
      return Date.parse(b.time) - Date.parse(a.time);
    })
    .map((bI) => BillableItemSchema.parse(bI));
  const eventDayItems = frontendItems.filter(isEventDayBillableItem);
  const pricing = getPricingRulesOfEvent(event);
  const eventCycles = getEventCyclesOfTimeRange(eventDayItems, pricing.numDaysEventCycle);

  return frontendItems.map((item) => {
    if (item.type === BillableItemTypes.EVENT_DAY) {
      const cycle = eventCycles.eventCycles.find((c) => c.startTimeKey === item.timeKey);

      return {
        ...item,
        cycle,
        startNewCycle: cycle?.startTimeKey === item.timeKey,
      };
    }

    return item;
  });
};

export const eventIsLocked = (
  patch: Partial<RacemapEvent>,
  currentEvent: Immutable<RacemapEvent | CurrentEvent>,
): boolean => {
  // group events have no pricing, so can't be locked
  if (isGroupEvent(currentEvent)) return false;

  // event seems to be a overview, we can't check, if it is locked
  if (currentEvent.payments?.invariants?.pricingVersion == null) return false;

  // if pricing is already the current version we can change the event
  if (currentEvent.payments.invariants.pricingVersion === getCurrentPricingVersion()) return false;

  // same like confirmation, but we ignore the modules
  return needsAConfirmation({ startTime: patch.startTime, endTime: patch.endTime }, currentEvent);
};

export const needsAConfirmation = (
  patch: Partial<RacemapEvent>,
  currentEvent: Immutable<RacemapEvent | CurrentEvent>,
): boolean => {
  if (!isCurrentEvent(currentEvent)) return false;

  // unpaid events are like drafts and can be changed without confirmation
  if (currentEvent.authorization !== AuthorizationStates.PAID) return false;

  // we change no billable properties
  if (patch.startTime == null && patch.endTime == null && patch.modules == null) return false;
  const patchObject = {
    startTime: patch.startTime ?? currentEvent.startTime,
    endTime: patch.endTime ?? currentEvent.endTime,
    modules: patch.modules ?? currentEvent.modules,
  };

  const billableItems = currentEvent.billableItems;
  const pricingRules = getPricingRulesOfEvent(currentEvent);

  const billableEvents = billableItems.filter(isEventDayBasedBillableItem);
  const { eventCycles: oldExpectedEventCycles } = getEventCyclesOfTimeRange(
    [...billableEvents, ...getBillableEventsForEvent(currentEvent)],
    pricingRules.numDaysEventCycle,
  );
  const { eventCycles: expectedNewEventCycles } = getEventCyclesOfTimeRange(
    [...billableEvents, ...getBillableEventsForEvent({ ...currentEvent, ...patchObject })],
    pricingRules.numDaysEventCycle,
  );

  const oldTimeKeys = new Set(oldExpectedEventCycles.map((e) => e.startTimeKey));
  const newTimeKeys = new Set(expectedNewEventCycles.map((e) => e.startTimeKey));

  // if the number of time keys increase we expect one or more billing cycles for the new event configuration
  if (newTimeKeys.size > oldTimeKeys.size) return true;

  // search for addons that were added
  for (const cycle of oldExpectedEventCycles) {
    const belongingNewCycle = expectedNewEventCycles.find(
      (eC) => eC.startTimeKey === cycle.startTimeKey,
    );
    if (belongingNewCycle == null) continue;
    if (belongingNewCycle.activatedAddons.length > cycle.activatedAddons.length) return true;
    if (belongingNewCycle.activatedAddons.some((a) => !cycle.activatedAddons.includes(a)))
      return true;
  }

  return false;
};

export const getActivatedAddOnList = (
  modules: Immutable<OptionModules>,
  onlyNonFreeModules = true,
): Array<AddOns> => {
  const activatedAddons: Array<AddOns> = [];

  for (const [key, module] of Object.entries(modules)) {
    if (Array.isArray(module)) continue;
    if (onlyNonFreeModules && !nonFreeModules.includes(key as keyof OptionModules)) continue;
    if ('enabled' in module && module.enabled) activatedAddons.push(key as AddOns);
    if ('state' in module && module.state === ModulesStates.ACTIVATED)
      activatedAddons.push(key as AddOns);
  }

  return activatedAddons;
};

export const getBillableEventsForEvent = (
  event: Immutable<CurrentEvent>,
): EventDayBasedBillableItem[] => {
  if (isGroupEvent(event)) return [];
  if (event.startTime == null || event.endTime == null)
    throw new Error('Event has no start or end time');
  const start = DateTime.fromISO(event.startTime).toUTC().startOf('day');
  const end = DateTime.fromISO(event.endTime).toUTC().endOf('day');
  const interval = Interval.fromDateTimes(start, end);

  return interval
    .splitBy({ days: 1 })
    .map((d) => d.start)
    .filter(isDefined)
    .map((start) => ({
      id: new ObjectId(),
      type: BillableItemTypes.EVENT_DAY,
      eventId: new ObjectId(event.id),
      userId: new ObjectId(event.creatorId),
      timeKey: start.toFormat('yyyy-MM-dd') || '',
      time: start.toJSDate(),
      createdAt: new Date(),
      updatedAt: new Date(),
      activatedAddons: getActivatedAddOnList(event.modules),
      itemId: null,
    }));
};

export const presentConfirmationDialog = async (
  patch: Partial<RacemapEvent>,
  previousVersion: Immutable<RacemapEvent | CurrentEvent>,
): Promise<void> => {
  if (!isCurrentEvent(previousVersion)) return;

  await presentDialogModal({
    title: 'Confirm usage',
    dialog: <ConfirmationModal event={previousVersion} patch={patch} />,
    size: 'lg',
    aktionButtonLabel: 'Confirm',
  });
};

export const presentEventLockedDialog = async (
  previousVersion: Immutable<RacemapEvent | CurrentEvent>,
): Promise<void> => {
  if (!isCurrentEvent(previousVersion)) return;

  await presentDialogModal({
    title: 'Event is locked',
    dialog: <EventIsLockedModal />,
    size: 'lg',
    showActionButton: false,
    cancelErrorMessage: 'LOCKED',
  });

  throw new Error('LOCKED');
};

export const nonFreeModules: Array<keyof OptionModules> = [
  'monitor',
  'sponsor',
  'timing',
  'dataFeed',
];

export const addonWasActivated = (
  newModules: OptionModules,
  previousModules: OptionModules,
  onlyNonFreeModules = false,
): boolean => {
  for (const [key, module] of Object.entries(newModules)) {
    if (onlyNonFreeModules && !nonFreeModules.includes(key as keyof OptionModules)) continue;
    const previousModule = previousModules[key as keyof OptionModules];

    // branded app ids (skip)
    if (Array.isArray(module) || Array.isArray(previousModule)) continue;

    // check if module with enabled flag
    if (
      'enabled' in module &&
      'enabled' in previousModule &&
      module.enabled &&
      module.enabled !== previousModule.enabled
    )
      return true;

    // check if module with state flag
    if (module.state === ModulesStates.ACTIVATED && module.state !== ModulesStates.ACTIVATED)
      return true;
  }

  return false;
};
