import styled from '@emotion/styled';
import { StripeProducts } from '@racemap/sdk/schema/billing';
import { BillableItemTypes } from '@racemap/sdk/schema/user';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { AuthorizationStates } from '@racemap/utilities/consts/events';
import {
  type CostlyAddons,
  getEventCyclesOfTimeRange,
  getPricingRulesOfEvent,
  getProductsOfEventCycles,
  isBillableItem,
  isEventDayBasedBillableItem,
  isFreeBillableItem,
} from '@racemap/utilities/functions/billing';
import { getHumanReadableIntervalDuration } from '@racemap/utilities/functions/getHumanReadableInterval';
import { userNeedsVAT } from '@racemap/utilities/functions/userNeedsVAT';
import { isRegularUser } from '@racemap/utilities/functions/utils';
import { Button, Card, Flex } from 'antd';
import { Immutable } from 'immer';
import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import { useGetProductPrice, useUser } from '../../../lib/customHooks';
import { formatPriceWithVAT, getLabelForBillingItemType } from '../../../misc/pricing';
import { getBillableEventsForEvent } from '../../../store/events/events_reducer_helper';
import { CurrentEvent } from '../../../store/events/events_reducers';
import { PopoverHint } from '../../BasicComponents/PopoverHint';
import { DurationAndCyclesInfo } from './DurationAndCyclesInfo';
import { EventCycleHint } from './EventCycleHint';

interface Props {
  event: Immutable<CurrentEvent>;
  title?: string;
  expandable?: boolean;
  defaultShort?: boolean;
}

export const PayLaterList: FC<Props> = ({
  event,
  title,
  expandable = false,
  defaultShort = false,
}) => {
  const { user: creator } = useUser({ userId: event.creatorId });
  const getPrice = useGetProductPrice({ isReseller: creator?.isReseller });
  const [isShortVersion, setIsShortVersion] = useState(defaultShort);

  if (creator == null) return null;

  const needsVAT = userNeedsVAT(creator.checkout?.address, creator.checkout?.taxIds);
  const { startTime, endTime } = event;
  const isExtension = event.billableItems.filter(isBillableItem).length > 0;
  const pricing = getPricingRulesOfEvent(event);
  const isFirstActivation = event.authorization === AuthorizationStates.NONE;
  const billedEvents = event.billableItems.filter(isEventDayBasedBillableItem);
  const freeItems = event.billableItems.filter(isFreeBillableItem);
  const hasPayedBasePrice = event.billableItems.some(
    (item) => item.type === BillableItemTypes.BASE_PRICE,
  );
  const willPayBasePrice = isFirstActivation && isRegularUser(creator);
  const possibleBillableEvents = getBillableEventsForEvent(event);
  const billingCyclesInfo = getEventCyclesOfTimeRange(
    possibleBillableEvents,
    pricing.numDaysEventCycle,
  );

  if (startTime == null || endTime == null || creator == null) return null;
  if (DateTime.fromISO(startTime) < DateTime.now() && DateTime.fromISO(endTime) < DateTime.now())
    return null;

  const startTimeObj = DateTime.fromISO(startTime).toUTC().startOf('day');
  const endTimeObj = DateTime.fromISO(endTime).toUTC().endOf('day');
  const duration = endTimeObj.diff(startTimeObj);

  const payedProducts = getProductsOfEventCycles(
    billedEvents,
    pricing.numDaysEventCycle,
    hasPayedBasePrice,
  );
  const newProducts = getProductsOfEventCycles(
    possibleBillableEvents,
    pricing.numDaysEventCycle,
    hasPayedBasePrice || willPayBasePrice,
    freeItems,
  );

  const productsPrepared = Object.entries(newProducts)
    .map(([key, amount]) => [key, Math.max(amount - (payedProducts[key as CostlyAddons] || 0), 0)])
    .filter(([, amount]) => amount !== 0) as Array<[StripeProducts, number]>;

  const areNewProductsPossible = productsPrepared.length > 0;

  if (!areNewProductsPossible) return null;

  if (isShortVersion) {
    return (
      <Flex vertical align="center">
        <DurationAndCyclesInfo />
        {expandable && (
          <Button type="link" onClick={() => setIsShortVersion(false)}>
            Show detailed billing info
          </Button>
        )}
      </Flex>
    );
  }

  return (
    <Flex vertical align="center">
      <PayLaterContainer isFirstActivation={isFirstActivation}>
        {(isExtension || title != null) && <h5>{title || 'After extension'}:</h5>}

        <section>
          Start and end times can be adjusted{' '}
          {isExtension ? 'at any point' : 'as needed even after activation'}. This flexibility can
          impact both the event duration and the number of{' '}
          <PopoverHint
            variant="description-light"
            underlineText
            text="event cycles"
            suffix="."
            triggerByHover="both"
            width={600}
          >
            <EventCycleHint />
          </PopoverHint>{' '}
          {isExtension
            ? 'From now until the set end time the duration and number of cycles is determined as below:'
            : 'The set times determine the duration and number of cycles as below:'}
        </section>

        <table>
          <tbody>
            <tr>
              <td>
                <strong>Start:</strong>{' '}
                {startTimeObj.toLocaleString(DateTime.DATETIME_FULL, { locale: 'en-GB' })}
              </td>
              <td>
                <strong>End:</strong>{' '}
                {endTimeObj.toLocaleString(DateTime.DATETIME_FULL, { locale: 'en-GB' })}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Duration:</strong> {getHumanReadableIntervalDuration(duration)}
              </td>
              <td>{billingCyclesInfo.eventCycles.length}x Event Cycles</td>
            </tr>
          </tbody>
        </table>

        <>
          <section>
            Possible further chargeable usage of Map and Add-Ons according to specified start and
            end times and activated Add-Ons:
          </section>

          <table>
            <thead>
              <tr>
                <th className="labelColumn">Product</th>
                <th className="unitColumn">Units</th>
                <th>Price per Unit</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {productsPrepared.map(([productKey, amount]) => (
                <tr key={productKey}>
                  <td className="labelColumn">{getLabelForBillingItemType(productKey)}</td>
                  <td className="unitColumn">{amount}</td>
                  <td>{formatPriceWithVAT(getPrice?.(productKey, 1) || 0, needsVAT)}</td>
                  <td>{formatPriceWithVAT(getPrice?.(productKey, amount) || 0, needsVAT)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      </PayLaterContainer>
      {expandable && (
        <Button type="link" onClick={() => setIsShortVersion(true)}>
          Show less
        </Button>
      )}
    </Flex>
  );
};

const PayLaterContainer = styled(Card)<{ isFirstActivation: boolean }>`
    border-color: ${({ isFirstActivation }) =>
      isFirstActivation ? RacemapColors.DarkGray : RacemapColors.PaleBlue};
    background-color: ${({ isFirstActivation }) =>
      isFirstActivation ? RacemapColors.LightLightGray : RacemapColors.LightBlue};

    .ant-card-body {
        padding: 8px 15px;
    }

    h5 {
        font-size: 1.1rem;
    }

    section {
        margin-bottom: 0.6rem;
    }

    table {
        width: 100%;
        margin-bottom: 0.6rem;

        th {
            font-weight: 600;
            border-bottom: 1px solid ${RacemapColors.Gray};
            width: 25%;
        }

        td {
          width: 25%;
        }

        .labelColumn {
          width: 40%;
        }

        .unitColumn {
          width: 10%;
          text-align: center;
        }
    }
`;
