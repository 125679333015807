import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import { useStore } from 'src/store/reducers';

export const AccountView: FC = () => {
  const location = useLocation();
  const currentUser = useStore((s) => s.users.getter.currentUser());

  if (currentUser == null) return <></>;
  const search = location.search;

  if (location.pathname === '/admin/account') {
    return <Navigate to={`/admin/account/${currentUser.id}${search}`} />;
  }
  if (location.pathname === '/admin/account/billing') {
    return <Navigate to={`/admin/account/${currentUser.id}/billing${search}`} />;
  }

  return <Outlet />;
};
