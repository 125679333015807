import { apiClient as apiClientV2 } from '@racemap/sdk';
import { BufferSchema, ObjectId, ObjectIdSchema } from '@racemap/sdk/schema/base';
import {
  MessageTemplateArgumentSchema,
  MessageTemplateSchema,
} from '@racemap/sdk/schema/messageTemplates';
import { TrackerTag, TrackerTagPrototype } from '@racemap/sdk/schema/trackerTags';
import {
  PublicTracker,
  TRACKER_MESSAGE_HISTORY_RECORD_STATE,
  TRACKER_MESSAGE_TRANSPORT,
  TRACKER_PARTICIPANT_PAIRING_OPTION,
  TRACKER_TYPES,
  TrackerMessageSchema,
  TrackerMessageTemplateBasedPrototypeSchema,
  TrackerMessagesSchema,
  TrackerOverviewSchema,
  TrackerPrototype,
} from '@racemap/sdk/schema/trackers';
import { UserOverview, UserOverviewSchema } from '@racemap/sdk/schema/user';
import { HTTPFetchError, RacemapAPIClient } from '@racemap/utilities/api-client';
import { USER_ROLE } from '@racemap/utilities/consts/trackers';
import { deepUpdateRecord } from '@racemap/utilities/functions/deepUpdateRecord';
import { generateHexColorCode } from '@racemap/utilities/functions/random';
import { isAtomicEvent } from '@racemap/utilities/functions/utils';
import { isEmptyString, sleep, uuid4 } from '@racemap/utilities/functions/utils';
import { isDefined, isValidEmail } from '@racemap/utilities/functions/validation';
import RacemapMQTTClient from '@racemap/utilities/rm-mqtt-client';
import { RacemapEvent } from '@racemap/utilities/types/events';
import {
  ReaderConnections,
  SCAN_SESSION_MESSAGE_TYPES,
  ScanSessionMessage,
  TrackerLegacy,
} from '@racemap/utilities/types/trackers';
import { DeepPartial } from '@racemap/utilities/types/utils';
import * as Comlink from 'comlink';
import { Immutable, castDraft, produce } from 'immer';
import { DateTime } from 'luxon';
import moment from 'moment';
import { OnMessageCallback } from 'mqtt';
import { toast } from 'react-toastify';
import z from 'zod';
import { StoreApi } from 'zustand';
import { Tristate } from '../../components/ButtonWithSpinner';
import {
  Filters,
  getEmptyFilterCounts,
} from '../../components/TrackerManager/Filter/FilterTreeFunctions';
import { MessageType, TimePeriod } from '../../components/TrackerManager/constants';
import { toastInfo, toastSuccess } from '../../components/utils/Utils';
import { FilterTreePreparation } from '../../worker/filtertree-preparation';
import { DraftState, State } from '../reducers';
import {
  combineMessages,
  filterTrackers,
  getAllUserIDsFromTracker,
  getMessageCounts,
  getTrackerWithMessageCounts,
  getUserIdsFromFilterSet,
  prepareMessageTemplates,
  testScanSessionConnectionMessage,
  testScanSessionDisconnectionMessage,
  testScanSessionReadMessage,
  updateUsersWithRoleAndTrackers,
  validateTrackerPrototypes,
} from './trackers_helper';

const apiClient = RacemapAPIClient.fromWindowLocation();

export type FilterTreePreparationRemote = Comlink.Remote<FilterTreePreparation>;
export type TrackerTags = Map<string, TrackerTag>;

export const MessageCountsSchema = z.object({
  errorCount: z.number(),
  pendingCount: z.number(),
  scheduledCount: z.number(),
});
export type MessageCounts = z.infer<typeof MessageCountsSchema>;

export const ManagmentTrackerMessageSchema = TrackerMessageSchema.extend({
  payload: BufferSchema.optional(),
});
export const ManagedTrackerSchema = TrackerOverviewSchema.extend({
  messagesCounts: MessageCountsSchema.optional(),
  messages: z.array(ManagmentTrackerMessageSchema),
});
export type ManagedTracker = z.infer<typeof ManagedTrackerSchema>;
export type ManagedTrackers = Map<string, ManagedTracker>;

export const ManagedSampleTrackerSchema = ManagedTrackerSchema.extend({
  index: z.number(),
});
export type ManagedSampleTracker = z.infer<typeof ManagedSampleTrackerSchema>;
export type ManagedSampleTrackers = Array<ManagedSampleTracker>;

export type ManagedTrackerMessages = z.infer<typeof ManagmentTrackerMessageSchema>;
export type Role = 'owner' | 'borrower' | 'editor';

export const ManagedMessageTemplateArgumentSchema = MessageTemplateArgumentSchema.extend({
  trackerTypes: z.array(z.nativeEnum(TRACKER_TYPES)).optional(),
});
export type ManagedMessageTemplateArgument = z.infer<typeof ManagedMessageTemplateArgumentSchema>;

export const ManagedMessageTemplateSchema = MessageTemplateSchema.extend({
  arguments: z.array(ManagedMessageTemplateArgumentSchema),
});
export type ManagedMessageTemplate = z.infer<typeof ManagedMessageTemplateSchema>;
export type ManagedMessageTemplates = Map<string, ManagedMessageTemplate>;

export enum TrackerColumnKeys {
  INDEX = 'index',
  NAME = 'trackerName',
  TYPE = 'trackerType',
  CON = 'state.online',
  LOCATION = 'state.gpsFix',
  LAST_DATA = 'state.lastDataAt',
  ERR = 'messagesCounts.errorCount',
  PEN = 'messagesCounts.pendingCount',
  SCH = 'messagesCounts.scheduledCount',
  FIRMWARE = 'meta.firmwareVersion',
  BATTERY = 'state.battery',
  SHIPPING = 'shipping',
  STATE = 'activation.type',
  TAGS = 'tags',
  GPS_MODE = 'setting.gpsMode',
  MOVEMENT = 'setting.reportMode',
  NON_MOVEMENT = 'setting.nmdReportMode',
  INFO_REPORT = 'setting.infoReportEnabled',
  HEARTBEAT = 'setting.heartbeatEnabled',
  SERVER_ACK = 'setting.serverAckEnabled',
  CHART = 'chart',
  SOS = 'setting.functionKeyMode',
  REPORT_DISTANCE = 'setting.reportDistance',
  LAST_MESSAGE = 'messages',
}

declare const __RM_DEVICE_USER__: string;
const racemapDeviceUserId: string = __RM_DEVICE_USER__ ?? process.env.RM_DEVICE_USER;

export type ShippingInfo = {
  lastConnectedAtTimePeriod: TimePeriod;
  lastDataAtTimePeriod: TimePeriod;
  minBattery: number;
  invalidMessageTypes: MessageType[];
};

export interface TrackersState {
  trackers: {
    items: ManagedTrackers;
    publicTrackers: Map<string, PublicTracker>;
    trackerTags: TrackerTags;
    messageTemplates: ManagedMessageTemplates;
    filterTreePreparation: FilterTreePreparationRemote | null;
    filterCounts: Map<Filters, Map<string, number>>;
    users: Map<string, UserOverview>;

    // time at the tracker was last updated
    lastTrackerLoadedAt: Date | undefined;

    // the sample is the view in the upper table
    sampleTrackerIds: Set<string>;

    inspectedTrackerId: ObjectId | null;
    selectedTrackerIds: {
      sample: Set<string>;
      total: Set<string>;
    };

    // filter set
    filterSet: Set<string>;
    searchValue: string;

    events: Map<string, RacemapEvent>;
    usersWithRoleAndTrackers: Map<string, Map<Role, Set<string>>>;
    savingState: Tristate;
    loadingState: 'INITIAL' | 'LOADING' | 'LOADED' | 'ERROR';

    // scanner session
    sessionId: string | null;
    mqttClient: RacemapMQTTClient | null;
    connectedReader: ReaderConnections;
    sessionState: 'UNKNOWN' | 'CONNECTED' | 'DISCONNECTED' | 'ERROR';

    // selected cols
    selectedCols: Set<TrackerColumnKeys>;

    // shipping info
    shippingInfo: ShippingInfo;
    showShippingCol: boolean;

    getter: {
      selectedTracker: (selectionId: 'sample' | 'total') => Array<Immutable<ManagedTracker>>;
      filteredTrackers: () => Immutable<Array<ManagedTracker>>;
      inspectedTracker: () => Immutable<ManagedTracker> | null;
      sampleTrackers: () => Immutable<Array<ManagedTracker>>;
      countSearchedTrackers: () => number;
    };
    actions: {
      loadData: (isMobile: boolean) => Promise<void>;

      // inspection actions
      changeInspectedTrackerId: (id: ObjectId | null) => void;

      // sample actions
      moveTrackersToSample: (ids: Array<ObjectId>) => void;
      replaceSample: (ids: Immutable<Array<ObjectId>>) => void;
      removeTrackersFromSample: (ids: Array<ObjectId>) => void;
      removeAllTrackersFromSample: () => void;

      // selection actions
      deselectTrackers: (ids: Array<ObjectId>, selectionId: 'sample' | 'total') => void;
      updateCompleteSelection: (ids: ReadonlySet<string>, selectionId: 'sample' | 'total') => void;
      deselectAllTrackers: (selectionId: 'sample' | 'total') => void;

      // tracker tags actions
      removeTrackerTag: (tagId: ObjectId) => Promise<void>;
      updateTrackerTag: (tagId: ObjectId, trackerTagUpdate: Partial<TrackerTag>) => Promise<void>;
      addTrackerTag: (trackerIds: Array<ObjectId>, tagLabel: string) => Promise<void>;

      // shipping info actions
      setShippingInfo: (shippingInfo: ShippingInfo) => void;
      setShowShippingCol: (show: boolean) => void;

      // tracker actions
      loadTracker: (trackerId: ObjectId) => Promise<void>;
      loadPublicTracker: (trackerId: string) => Promise<void>;
      updateTracker: (trackerId: ObjectId, trackerUpdate: Partial<ManagedTracker>) => Promise<void>;
      updateTrackers: (
        trackerIds: Array<ObjectId>,
        trackerUpdates: DeepPartial<ManagedTracker>,
      ) => Promise<void>;
      removeTrackers: (trackerIds: Array<ObjectId>) => Promise<void>;
      addTrackers: (trackersToAdd: Array<TrackerPrototype>) => Promise<void>;

      // batch actions
      addTrackersToEvent: (
        trackerIds: Array<ObjectId>,
        eventId: string,
        pairingOption: TRACKER_PARTICIPANT_PAIRING_OPTION,
      ) => Promise<{ success: boolean; count: number }>;
      removeUserFromTracker: (
        trackerId: ObjectId,
        userId: ObjectId,
        userRole: USER_ROLE,
        dates?: { startTime: Date; endTime: Date },
      ) => Promise<void>;
      removeBorrowersAndEditorsFromTracker: (trackerId: ObjectId) => Promise<void>;
      addUserToTracker: (params: {
        trackerIds: Array<ObjectId>;
        userId: ObjectId;
        role: USER_ROLE;
        startTime?: DateTime;
        endTime?: DateTime;
      }) => Promise<void>;

      // message actions
      addMessageToTracker: (options: {
        trackerIds: Array<ObjectId>;
        plannedAt: Date;
        closeAfter: Date;
        transport: TRACKER_MESSAGE_TRANSPORT;
        templateId: ObjectId;
        parameters: Record<string, string>;
      }) => Promise<void>;
      removeTrackerMessage: (trackerId: ObjectId, messageId: ObjectId) => Promise<void>;
      revokeTrackerMessage: (trackerId: ObjectId, messageId: ObjectId) => Promise<void>;
      revokeAllTrackerMessages: (trackerIds: Array<ObjectId>) => Promise<void>;
      clearTrackerMessage: (trackerId: ObjectId, messageId: ObjectId) => Promise<void>;
      clearAllTrackerMessages: (trackerIds: Array<ObjectId>) => Promise<void>;
      updateTrackerMessage: (
        messageId: ObjectId,
        newTrackerMessage: Immutable<ManagedTrackerMessages>,
      ) => Promise<void>;

      // filter actions
      selectTrackers: (ids: Array<ObjectId>, selectionId: 'sample' | 'total') => void;
      setFilterSet: (newSet: Immutable<Set<string>>) => void;
      setSearchValue: (newSearchValue: string) => Promise<void>;
      resetFilterSelection: () => void;

      // message col actions
      changeSelectedCols: (selectedCols: Set<TrackerColumnKeys>) => void;

      // scan session actions
      startSession: () => Promise<void>;
      stopSession: () => Promise<void>;
      _handleReaderSessionMessage: OnMessageCallback;
      _handleReaderConnectAttempt: (readerId: string, readerName?: string) => void;
      _handleReadMessage: (readerId: string, trackerId: ObjectId) => void;
      _handleReaderDisconnectAttempt: (readerId: string) => void;
    };
  };
}

export const createTrackerStore = (
  set: StoreApi<State>['setState'],
  get: StoreApi<State>['getState'],
): TrackersState => ({
  trackers: {
    items: new Map(),
    publicTrackers: new Map(),
    trackerTags: new Map(),
    usersWithRoleAndTrackers: new Map(),
    users: new Map(),
    messageTemplates: new Map(),
    lastTrackerLoadedAt: undefined,
    filterTreePreparation:
      window.Worker != null
        ? Comlink.wrap(
            new Worker(new URL('../../worker/filtertree-preparation.ts', import.meta.url), {
              type: 'module',
            }),
          )
        : null,
    filterCounts: getEmptyFilterCounts(),
    sampleTrackerIds: new Set(),
    selectedTrackerIds: {
      sample: new Set(),
      total: new Set(),
    },
    inspectedTrackerId: null,
    savingState: Tristate.NORMAL,
    loadingState: 'INITIAL',
    filterSet: new Set([]),
    events: new Map(),
    searchValue: '',

    sessionId: null,
    mqttClient: null,
    connectedReader: new Map(),
    sessionState: 'UNKNOWN',

    selectedCols: new Set([
      TrackerColumnKeys.INDEX,
      TrackerColumnKeys.NAME,
      TrackerColumnKeys.CON,
      TrackerColumnKeys.LOCATION,
      TrackerColumnKeys.LAST_DATA,
      TrackerColumnKeys.ERR,
      TrackerColumnKeys.BATTERY,
    ]),

    shippingInfo: {
      minBattery: 90,
      lastConnectedAtTimePeriod: TimePeriod.lastOneHour,
      lastDataAtTimePeriod: TimePeriod.lastOneHour,
      invalidMessageTypes: [MessageType.Pending, MessageType.Error, MessageType.Scheduled],
    },
    showShippingCol: false,

    getter: {
      selectedTracker: (selectionId) => {
        const selection = get().trackers.selectedTrackerIds[selectionId];
        const items = get().trackers.items;

        return Array.from(selection)
          .map((s) => items.get(s))
          .filter(isDefined);
      },
      filteredTrackers: () => {
        const { items, searchValue, filterSet, trackerTags } = get().trackers;

        return filterTrackers(items, searchValue, filterSet, trackerTags);
      },
      inspectedTracker: () => {
        const trackerId = get().trackers.inspectedTrackerId;
        const items = get().trackers.items;
        if (trackerId == null) return null;

        return items.get(trackerId.toHexString()) || null;
      },
      sampleTrackers: () => {
        const sampleIds = get().trackers.sampleTrackerIds;
        const items = get().trackers.items;

        return Array.from(sampleIds)
          .map((s) => items.get(s))
          .filter(isDefined);
      },
      countSearchedTrackers: () => {
        const { searchValue } = get().trackers;
        if (isEmptyString(searchValue)) return 0;

        return filterTrackers(get().trackers.items, searchValue, new Set<string>()).length;
      },
    },
    actions: {
      loadData: async (isMobile) => {
        if (get().trackers.loadingState === 'LOADING') return;
        let toastId: string | number | undefined;

        try {
          const startLoading = new Date();
          const isAllTrackerLoad = get().trackers.lastTrackerLoadedAt == null;
          if (isAllTrackerLoad) {
            toastId = toast('Loading trackers', {
              autoClose: false,
              isLoading: true,
              progress: 0,
              type: 'info',
              closeButton: false,
              draggable: false,
            });

            const currentUser = get().users.getter.currentUser();
            if (currentUser?.admin && get().trackers.users.size === 0 && racemapDeviceUserId) {
              set(
                produce((s: DraftState) => {
                  const userPath = `/users/${racemapDeviceUserId}`;
                  [`${userPath}/owner`, `${userPath}/editor`, `${userPath}/borrower`].forEach(
                    (role) => s.trackers.filterSet.add(role),
                  );
                }),
              );
            }
          }
          set(
            produce((s: DraftState) => {
              s.trackers.loadingState = 'LOADING';
            }),
          );

          const filteredUserIds = Array.from(getUserIdsFromFilterSet(get().trackers.filterSet));

          const [trackers, events, tags, { users }, templates] = await Promise.all([
            apiClientV2.listTrackers({
              queries: {
                show: 'archived',
                updatedSince: get().trackers.lastTrackerLoadedAt,
                userIds: filteredUserIds,
              },
              // @ts-expect-error - lib error
              validate: false,
            }),
            apiClient.getEvents({
              filter: 'can-edit',
              show: ['hidden'],
              endingAfter: moment().startOf('day').subtract(14, 'days').toDate(),
            }),
            apiClientV2.listTrackerTags(),
            apiClient.getUsers({ limit: 100 }),
            apiClientV2.listMessageTemplates(),
          ]);

          if (toastId != null) {
            toast.update(toastId, {
              render: 'Prepare trackers (0%)',
              progress: 0.2,
            });
          }

          const preparedMessageTemplates = prepareMessageTemplates(templates);

          set(
            produce((s: DraftState) => {
              s.trackers.events = new Map(events.map((e) => [e.id, e]));
              s.trackers.trackerTags = new Map(tags.map((tT) => [tT.id.toHexString(), tT]));
              s.trackers.lastTrackerLoadedAt = startLoading;
              s.trackers.messageTemplates = preparedMessageTemplates;
            }),
          );

          const trackerParsed: Array<ManagedTracker> = [];
          let i = 0;
          let lastPercent = -1;

          const allUserIDsFromTrackers = new Set<string>();

          for (const trackerRaw of trackers) {
            trackerParsed.push(await ManagedTrackerSchema.parseAsync(trackerRaw));
            i++;

            if (i % 100 === 0 || i === trackers.length) {
              set(
                produce((s: DraftState) => {
                  for (const tracker of trackerParsed) {
                    const existing = s.trackers.items.get(tracker.id.toHexString());

                    if (existing != null) {
                      tracker.messages = castDraft(
                        combineMessages(existing.messages, tracker.messages),
                      );
                    }

                    s.trackers.items.set(
                      tracker.id.toHexString(),
                      getTrackerWithMessageCounts(tracker),
                    );
                    updateUsersWithRoleAndTrackers(tracker, s.trackers.usersWithRoleAndTrackers);
                    const userIDsFromTracker = getAllUserIDsFromTracker(tracker);
                    userIDsFromTracker.forEach((userID) => {
                      allUserIDsFromTrackers.add(userID);
                    });
                  }
                }),
              );
              trackerParsed.length = 0;
            }

            if (i % 10 === 0) {
              await sleep(0);

              const percent = Math.floor((i / trackers.length) * 100);
              if (lastPercent !== percent && percent % 10 === 0) {
                if (toastId != null) {
                  toast.update(toastId, {
                    render: `Prepare trackers (${percent}%)`,
                    progress: 0.2 + percent * (6 / 1000),
                  });
                }
                lastPercent = percent;
              }
            }
          }

          set(
            produce((s: DraftState) => {
              users.forEach((user) => {
                if (user == null) return;
                s.trackers.users.set(user.id, UserOverviewSchema.parse(user));
              });
              const currentUser = get().users.getter.currentUser();

              if (
                racemapDeviceUserId != null &&
                currentUser?.admin &&
                s.trackers.items.get(racemapDeviceUserId) == null
              ) {
                s.trackers.users.set(
                  racemapDeviceUserId,
                  UserOverviewSchema.parse({
                    id: racemapDeviceUserId,
                    name: 'Devices@Racemap',
                    email: 'devices@racemap.com',
                  }),
                );
              }
            }),
          );

          if (!isMobile) {
            if (toastId != null) {
              toast.update(toastId, {
                render: 'Calculate filter counts',
                progress: 0.8,
              });
            }

            const tool = get().trackers.filterTreePreparation;
            const trackersArray = Array.from(get().trackers.items.values());
            const options = {
              events,
              trackers: trackersArray,
              tags,
              filteredUserIds,
            };

            const filterCounts =
              (await tool?.calculateFilterCounts(JSON.stringify(options))) || new Map();
            set(
              produce((s: DraftState) => {
                s.trackers.filterCounts = filterCounts;
                s.trackers.loadingState = 'LOADED';
              }),
            );
          }

          if (toastId != null) {
            toast.update(toastId, {
              render: 'Successfully loaded trackers',
              isLoading: false,
              progress: null,
              hideProgressBar: true,
              autoClose: 2000,
              type: 'success',
            });
          }
        } catch (err) {
          if (err instanceof Error) {
            console.error(err);

            if (toastId != null) {
              toast.update(toastId, {
                render: `Failed to load trackers: ${err.message}`,
                isLoading: false,
                progress: null,
                hideProgressBar: true,
                autoClose: 2000,
                type: 'error',
              });
            } else {
              toast.error(`Failed to load trackers: ${err.message}`);
            }
          }

          set(
            produce((s: DraftState) => {
              s.trackers.loadingState = 'ERROR';
              // reset the last loaded at time to force full reload next time
              s.trackers.lastTrackerLoadedAt = undefined;
            }),
          );
        }
      },

      // inspected tracker actions
      changeInspectedTrackerId: (id) => {
        set(
          produce((s: DraftState) => {
            s.trackers.inspectedTrackerId = id;
          }),
        );

        if (id != null) {
          apiClientV2
            .getTracker({ params: { trackerId: id.toHexString() } })
            .then((tracker) => {
              const oldTracker = get().trackers.items.get(id.toHexString());
              if (oldTracker == null) return;

              set(
                produce((s: DraftState) => {
                  s.trackers.items.set(id.toHexString(), { ...castDraft(oldTracker), ...tracker });
                }),
              );
            })
            .catch((err) => {
              console.error(err);
            });
        }
      },

      // sample actions
      moveTrackersToSample: (ids) => {
        set(
          produce((s: DraftState) => {
            ids.forEach((id) => s.trackers.sampleTrackerIds.add(id.toHexString()));
          }),
        );
      },
      replaceSample: (ids) => {
        set(
          produce((s: DraftState) => {
            s.trackers.sampleTrackerIds = new Set(ids.map((id) => id.toHexString()));
          }),
        );
      },
      removeTrackersFromSample: (ids) => {
        set(
          produce((s: DraftState) => {
            ids.forEach((id) => s.trackers.sampleTrackerIds.delete(id.toHexString()));
          }),
        );
      },
      removeAllTrackersFromSample: () => {
        set(
          produce((s: DraftState) => {
            s.trackers.sampleTrackerIds = new Set();
          }),
        );
      },

      // selection actions
      selectTrackers: (ids, selectionId) => {
        set(
          produce((s: DraftState) => {
            ids.forEach((id) => s.trackers.selectedTrackerIds[selectionId].add(id.toHexString()));
          }),
        );
      },
      deselectTrackers: (ids, selectionId) => {
        set(
          produce((s: DraftState) => {
            ids.forEach((id) =>
              s.trackers.selectedTrackerIds[selectionId].delete(id.toHexString()),
            );
          }),
        );
      },
      updateCompleteSelection: (ids, selectionId) => {
        set(
          produce((s: DraftState) => {
            s.trackers.selectedTrackerIds[selectionId] = new Set(ids);
          }),
        );
      },
      deselectAllTrackers: (selectionId) => {
        set(
          produce((s: DraftState) => {
            s.trackers.selectedTrackerIds[selectionId] = new Set();
          }),
        );
      },

      loadTracker: async (trackerId) => {
        set(
          produce((s: DraftState) => {
            s.trackers.savingState = Tristate.SPINNING;
          }),
        );
        const tracker = await apiClientV2.getTracker({
          params: { trackerId: trackerId.toHexString() },
        });

        set(
          produce((s: DraftState) => {
            s.trackers.items.set(trackerId.toHexString(), getTrackerWithMessageCounts(tracker));
            s.trackers.savingState = Tristate.NORMAL;
          }),
        );
      },
      loadPublicTracker: async (trackerId) => {
        set(
          produce((s: DraftState) => {
            s.trackers.savingState = Tristate.SPINNING;
          }),
        );
        const publicTracker = await apiClientV2.getTrackerPublicInfo({
          params: { trackerId },
        });

        set(
          produce((s: DraftState) => {
            s.trackers.publicTrackers.set(trackerId, publicTracker);
            s.trackers.savingState = Tristate.NORMAL;
          }),
        );
      },
      updateTracker: async (trackerId, trackerUpdate) => {
        const oldTracker = get().trackers.items.get(trackerId.toHexString());
        if (oldTracker == null) return;

        const newTracker = ManagedTrackerSchema.parse({
          ...oldTracker,
          ...trackerUpdate,
        });

        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;
              s.trackers.items.set(trackerId.toHexString(), newTracker);
            }),
          );

          const result = await apiClient.updateTracker(trackerId.toHexString(), trackerUpdate);
          const messages = combineMessages(result.messages, oldTracker.messages);
          const updatedTracker = ManagedTrackerSchema.parse({
            ...result,
            messages,
            messagesCounts: getMessageCounts(messages),
          });

          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.NORMAL;
              s.trackers.items.set(trackerId.toHexString(), updatedTracker);
              toast.success('Tracker updated successfully.');
            }),
          );
        } catch (error) {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.FAILURE;
              s.trackers.items.set(trackerId.toHexString(), castDraft(oldTracker));
            }),
          );
          if (error instanceof Error) {
            console.error('Failed to update a tracker');
            console.error(error);
            toast.error('The attempt to update the tracker failed.');
          }
        }
      },
      updateTrackers: async (trackerIds, trackerUpdates) => {
        const oldTrackers = trackerIds.map((trackerId) =>
          get().trackers.items.get(trackerId.toHexString()),
        );

        if (oldTrackers.includes(undefined)) return;
        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;

              trackerIds.forEach((trackerId) => {
                const oldTracker = s.trackers.items.get(trackerId.toHexString());
                if (oldTracker) {
                  const mergedTracker = deepUpdateRecord(oldTracker, trackerUpdates);
                  const newTracker = ManagedTrackerSchema.parse(mergedTracker);
                  s.trackers.items.set(trackerId.toHexString(), newTracker);
                }
              });
            }),
          );

          const stringTrackerIds = trackerIds.map((id) => id.toHexString());
          const results = await apiClient.updateTrackers(stringTrackerIds, trackerUpdates);

          set(
            produce((s: DraftState) => {
              results.forEach((result) => {
                const oldTracker = oldTrackers.find(
                  (t) => t?.id.toHexString() === String(result.id),
                );
                if (oldTracker) {
                  const messages = combineMessages(result.messages, oldTracker.messages || []);
                  const updatedTracker = ManagedTrackerSchema.parse({
                    ...result,
                    messages,
                    messageCounts: getMessageCounts(messages),
                  });
                  s.trackers.items.set(String(result.id), updatedTracker);
                }
              });
              s.trackers.savingState = Tristate.NORMAL;
              toast.success(`${trackerIds.length} tracker(s) updated successfully.`);
            }),
          );
        } catch (error) {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.FAILURE;
              trackerIds.forEach((trackerId, index) => {
                const oldTracker = oldTrackers[index];
                if (oldTracker) {
                  s.trackers.items.set(trackerId.toHexString(), castDraft(oldTracker));
                }
              });
            }),
          );

          console.error('Failed to update trackers:', error);
          toast.error('The attempt to update the trackers failed.');
        }
      },
      removeUserFromTracker: async (trackerId, userId, userRole, dates) => {
        const tracker = get().trackers.items.get(trackerId.toHexString());
        if (tracker == null) return;

        switch (userRole) {
          case USER_ROLE.EDITOR: {
            const newEditors = tracker.editors.filter((editor) => !editor.equals(userId));
            get().trackers.actions.updateTracker(trackerId, { editors: newEditors });
            break;
          }
          case USER_ROLE.BORROWER: {
            const newBorrowers = tracker.borrowers.filter(
              (borrower) =>
                !(
                  borrower.id.equals(userId) &&
                  borrower.startTime.valueOf() === dates?.startTime.valueOf() &&
                  borrower.endTime.valueOf() === dates?.endTime.valueOf()
                ),
            );
            get().trackers.actions.updateTracker(trackerId, {
              borrowers: newBorrowers,
            });
            break;
          }
          case USER_ROLE.OWNER: {
            // TODO: remove the owner id really here
            const currentUserId = get().users.currentUserId;
            if (currentUserId == null) return;

            get().trackers.actions.updateTracker(trackerId, { owner: new ObjectId(currentUserId) });
            break;
          }
        }
      },
      removeBorrowersAndEditorsFromTracker: async (trackerId) => {
        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;
            }),
          );

          const tracker = get().trackers.items.get(trackerId.toHexString());
          if (tracker == null) return;

          // Remove all editors
          const newEditors: never[] = [];

          // Remove all borrowers
          const newBorrowers: never[] = [];

          await get().trackers.actions.updateTracker(trackerId, {
            editors: newEditors,
            borrowers: newBorrowers,
          });

          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.NORMAL;
            }),
          );

          toast.success('All users removed from the tracker.');
        } catch (error) {
          console.log(error);
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.FAILURE;
            }),
          );
          if (error instanceof Error) {
            console.log(`Failed to remove all users from the tracker: ${error.message}`);
            console.error(error);
            toast.error('The attempt to remove all users from the tracker failed.');
          }
        }
      },
      removeTrackers: async (trackerIds) => {
        const removedTrackers: Array<Immutable<ManagedTracker>> = [];
        const user = get().users.getter.currentUser();
        const items = get().trackers.items;

        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;
              for (const trackerId of trackerIds) {
                const trackerToRemove = items.get(trackerId.toHexString());

                if (trackerToRemove != null) {
                  if (
                    user != null &&
                    !user.admin &&
                    trackerToRemove.owner.toHexString() !== user.id
                  )
                    continue;
                  removedTrackers.push(trackerToRemove);
                  s.trackers.items.delete(trackerId.toHexString());
                }
              }
            }),
          );

          const trackerIdsToRemove = removedTrackers.map((t) => t.id.toHexString());
          await apiClient.deleteTrackers(trackerIdsToRemove);

          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          console.log(error);
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.FAILURE;

              for (const tracker of removedTrackers) {
                s.trackers.items.set(tracker.id.toHexString(), castDraft(tracker));
              }
            }),
          );
          if (error instanceof Error) {
            console.log(`Failed to remove a tracker: ${error.message}`);
            console.error(error);
            toast.error('The attempt to remove the tracker failed.');
          }
        }
      },

      addUserToTracker: async ({ trackerIds, userId, role, startTime, endTime }) => {
        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;
            }),
          );

          const result = (await apiClient.addUserToTrackers(
            trackerIds.map((t) => t.toHexString()),
            userId.toHexString(),
            role,
            startTime?.toISO() || undefined,
            endTime?.toISO() || undefined,
          )) as Array<TrackerLegacy & { messagesCounts?: MessageCounts }>;

          set(
            produce((s: DraftState) => {
              for (const updatedTracker of result) {
                updatedTracker.messagesCounts = getMessageCounts(
                  TrackerMessagesSchema.parse(updatedTracker.messages),
                );

                const trackerParsed = ManagedTrackerSchema.parse(updatedTracker);
                s.trackers.items.set(trackerParsed.id.toHexString(), trackerParsed);
              }
              s.trackers.savingState = Tristate.NORMAL;
            }),
          );
          toast.success('User successfully added to the tracker(s).');
        } catch (error) {
          let errorMessage = "The user can't be added to the tracker(s)!";

          if (error instanceof HTTPFetchError) {
            errorMessage += ` ${error.serverError}`;
          } else if (error instanceof Error) {
            errorMessage += ` ${error.message}`;
          }

          toast.error(errorMessage);
          console.log(error);
        }
      },
      addTrackersToEvent: async (trackerIds, eventId, pairingOption) => {
        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;
            }),
          );

          const event = get().events.items.get(eventId);
          if (event && !isAtomicEvent(event)) {
            toast.error('The event type is not supported for this action.');
            return { success: false, count: -1 };
          }

          const connections = await apiClient.connectDevicesWithStarter(
            trackerIds.map((t) => t.toHexString()),
            eventId,
            {
              pairingOption,
            },
          );

          set(
            produce((s: DraftState) => {
              for (const trackerId of Object.values(connections)) {
                const tracker = s.trackers.items.get(trackerId);
                if (tracker == null) continue;

                tracker.eventIds?.push(new ObjectId(eventId));
              }
              s.trackers.savingState = Tristate.NORMAL;
            }),
          );

          return { success: true, count: Object.keys(connections).length };
        } catch (err) {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.FAILURE;
            }),
          );
          if (err instanceof Error) {
            console.error(err);
            toast.error(`Failed to add trackers to event! ${err.message}`);
          }
          if (err instanceof HTTPFetchError) {
            toast.error(`Failed to add trackers to event! ${err.serverError}`);
            console.log(err);
          }
          return { success: false, count: -1 };
        }
      },
      addTrackers: async (trackersToAdd) => {
        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;
            }),
          );
          validateTrackerPrototypes(trackersToAdd);
          const preparedTracker = prepareTrackerPrototypes(trackersToAdd);
          const createdTrackersRaw = await apiClient.addTrackers(preparedTracker);
          const createdTrackers = createdTrackersRaw.map((t) => ManagedTrackerSchema.parse(t));
          set(
            produce((s: DraftState) => {
              for (const tracker of createdTrackers) {
                s.trackers.items.set(tracker.id.toHexString(), ManagedTrackerSchema.parse(tracker));
              }
              s.trackers.savingState = Tristate.NORMAL;
            }),
          );
          toast.info(`Added ${createdTrackers.length} trackers.`);
        } catch (error) {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.FAILURE;
            }),
          );
          if (error instanceof Error) {
            console.log(`Failed to add a tracker: ${error.message}`);
            toast.error(`The attempt to add the tracker failed. Error: ${error.message}`);
          }
        }
      },
      addMessageToTracker: async ({
        trackerIds,
        plannedAt,
        closeAfter,
        transport,
        templateId,
        parameters,
      }) => {
        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;
            }),
          );
          const template = get().trackers.messageTemplates.get(templateId.toHexString());
          if (template == null) throw new Error('Template not found');

          const message = TrackerMessageTemplateBasedPrototypeSchema.parse({
            label: template.name,
            closeAfter,
            plannedAt,
            transport,
            templateId,
            parameters,
          });
          const createdMessages = await apiClientV2.addTrackerMessages({ ...message, trackerIds });
          set(
            produce((s: DraftState) => {
              for (const createdMessage of createdMessages) {
                const tracker = s.trackers.items.get(createdMessage.trackerId.toHexString());
                if (tracker == null) return;

                tracker.messages.push(createdMessage);
                tracker.messagesCounts = getMessageCounts(tracker.messages);
                s.trackers.savingState = Tristate.NORMAL;
              }
            }),
          );
        } catch (error) {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.FAILURE;
            }),
          );
          if (error instanceof Error) {
            console.error(`Failed to add message to tracker: ${error.message}`);
            toast.error('The send message to tracker.');
          }
        }
      },
      removeTrackerMessage: async (trackerId, messageId) => {
        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;
            }),
          );
          await apiClient.removeTrackerMessage(messageId.toHexString());

          set(
            produce((s: DraftState) => {
              const tracker = s.trackers.items.get(trackerId.toHexString());
              if (tracker == null) return;

              tracker.messages = tracker.messages.filter((m) => m.id !== messageId);
              s.trackers.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.FAILURE;
            }),
          );
          if (error instanceof Error) {
            console.log(`Failed to remove message to tracker: ${error.message}`);
            toast.error('Failed to remove message.');
          }
        }
      },
      revokeTrackerMessage: async (trackerId, messageId) => {
        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;
            }),
          );
          await apiClient.revokeTrackerMessage(messageId.toHexString());

          set(
            produce((s: DraftState) => {
              const tracker = s.trackers.items.get(trackerId.toHexString());
              if (tracker == null) return;

              const message = tracker.messages.find((m) => m.id === messageId);
              // If the message has no history records and has not timed out yet, add a revoked record.
              if (message?.historyRecords.length === 0 && message?.closeAfter >= new Date()) {
                message.historyRecords?.unshift({
                  statusCode: TRACKER_MESSAGE_HISTORY_RECORD_STATE.REVOKED,
                  creatorId: new ObjectId(),
                  statusMessage: null,
                  createdAt: new Date(),
                  updatedAt: new Date(),
                  messageId: message.id,
                  id: new ObjectId(),
                });
              }
              s.trackers.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.FAILURE;
            }),
          );
          if (error instanceof HTTPFetchError) {
            console.log(error.serverError);
            toast.error(`Failed: ${error.serverError}`);
          } else if (error instanceof Error) {
            console.log(`Failed to revoke message to tracker: ${error.message}`);
            toast.error('Failed to revoke message.');
          }
        }
      },
      revokeAllTrackerMessages: async (trackerIds) => {
        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;
            }),
          );
          const trackerIdsToRevoke = trackerIds.map((t) => t.toHexString());
          await apiClient.revokeAllTrackerMessages(trackerIdsToRevoke);

          set(
            produce((s: DraftState) => {
              for (const trackerId of trackerIdsToRevoke) {
                const tracker = s.trackers.items.get(trackerId);
                if (tracker == null) return;

                for (const message of tracker.messages) {
                  // If the message has no history records and has not timed out yet, add a revoked record.
                  if (message?.historyRecords.length === 0 && message?.closeAfter >= new Date()) {
                    message.historyRecords?.unshift({
                      statusCode: TRACKER_MESSAGE_HISTORY_RECORD_STATE.REVOKED,
                      creatorId: new ObjectId(),
                      statusMessage: null,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                      messageId: message.id,
                      id: new ObjectId(),
                    });
                  }
                }
              }
              s.trackers.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.FAILURE;
            }),
          );
          if (error instanceof HTTPFetchError) {
            console.log(error.serverError);
            toast.error(`Failed: ${error.serverError}`);
          } else if (error instanceof Error) {
            console.log(`Failed to revoke all pending messages to tracker: ${error.message}`);
            toast.error('Failed to revoke all pending messages.');
          }
        }
      },
      clearTrackerMessage: async (trackerId, messageId) => {
        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;
            }),
          );
          await apiClient.clearTrackerMessage(messageId.toHexString());

          set(
            produce((s: DraftState) => {
              const tracker = s.trackers.items.get(trackerId.toHexString());
              if (tracker == null) return;

              const message = tracker.messages.find((m) => m.id === messageId);
              message?.historyRecords?.unshift({
                id: new ObjectId(),
                statusCode: TRACKER_MESSAGE_HISTORY_RECORD_STATE.CLEARED,
                creatorId: new ObjectId(),
                statusMessage: 'Error cleared',
                createdAt: new Date(),
                updatedAt: new Date(),
                messageId: messageId,
              });
              s.trackers.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.FAILURE;
            }),
          );
          if (error instanceof HTTPFetchError) {
            console.log(error.serverError);
            toast.error(`Failed: ${error.serverError}`);
          } else if (error instanceof Error) {
            console.log(`Failed to clear message to tracker: ${error.message}`);
            toast.error('Failed to clear message.');
          }
        }
      },
      clearAllTrackerMessages: async (trackerIds) => {
        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;
            }),
          );
          const trackerIdsToClear = trackerIds.map((t) => t.toHexString());
          await apiClient.clearAllTrackerMessages(trackerIdsToClear);

          set(
            produce((s: DraftState) => {
              for (const trackerId of trackerIdsToClear) {
                const tracker = s.trackers.items.get(trackerId);
                if (tracker == null) return;

                for (const message of tracker.messages) {
                  if (
                    message.historyRecords?.[0]?.statusCode !==
                    TRACKER_MESSAGE_HISTORY_RECORD_STATE.ERROR
                  )
                    continue; // skip clear, the message is not an error
                  message.historyRecords?.unshift({
                    id: new ObjectId(),
                    statusCode: TRACKER_MESSAGE_HISTORY_RECORD_STATE.CLEARED,
                    creatorId: new ObjectId(),
                    statusMessage: 'Error cleared',
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    messageId: message.id,
                  });
                }
              }
              s.trackers.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.FAILURE;
            }),
          );
          if (error instanceof HTTPFetchError) {
            console.log(error.serverError);
            toast.error(`Failed: ${error.serverError}`);
          } else if (error instanceof Error) {
            console.log(`Failed to clear all error messages to tracker: ${error.message}`);
            toast.error('Failed to clear all error messages.');
          }
        }
      },
      updateTrackerMessage: async (messageId, newTrackerMessage) => {
        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;
            }),
          );
          const update = TrackerMessageSchema.parse(
            await apiClient.updateTrackerMessage(messageId.toHexString(), newTrackerMessage),
          );
          const trackerId = update.trackerId;

          set(
            produce((s: DraftState) => {
              const tracker = s.trackers.items.get(trackerId.toHexString());
              if (tracker == null) return;

              const messageIndex = tracker.messages.findIndex((m) => m.id.equals(messageId));
              if (messageIndex >= 0) tracker.messages[messageIndex] = update;
              s.trackers.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.FAILURE;
            }),
          );
          if (error instanceof HTTPFetchError) {
            console.log(error.serverError);
            toast.error(`Failed: ${error.serverError}`);
          } else if (error instanceof Error) {
            console.log(`Failed to update message to tracker: ${error.message}`);
            toast.error('Failed to update message.');
          }
        }
      },
      addTrackerTag: async (trackerIds, tagLabel) => {
        const tempObjectId = new ObjectId();
        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;
            }),
          );
          const tag: TrackerTagPrototype = {
            trackerIds,
            label: tagLabel,
            color: generateHexColorCode(),
          };

          const creatorId = get().users.currentUserId;
          set(
            produce((s: DraftState) => {
              s.trackers.trackerTags.set(tempObjectId.toHexString(), {
                ...tag,
                id: tempObjectId,
                creatorId: creatorId != null ? ObjectIdSchema.parse(creatorId) : new ObjectId(),
                createdAt: new Date(),
                updatedAt: new Date(),
              });
              s.trackers.filterCounts
                .get(Filters.Tags)
                ?.set(tempObjectId.toHexString(), trackerIds.length);
            }),
          );

          const createdTrackerTag = await apiClientV2.createTrackerTag(tag);

          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.NORMAL;
              s.trackers.trackerTags.delete(tempObjectId.toHexString());
              s.trackers.trackerTags.set(createdTrackerTag.id.toHexString(), createdTrackerTag);
              s.trackers.filterCounts.get(Filters.Tags)?.delete(tempObjectId.toHexString());
              s.trackers.filterCounts
                .get(Filters.Tags)
                ?.set(createdTrackerTag.id.toHexString(), trackerIds.length);
            }),
          );

          toast.success('Tracker tag added successfully.');
        } catch (error) {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.FAILURE;
              s.trackers.trackerTags.delete(tempObjectId.toHexString());
              s.trackers.filterCounts.get(Filters.Tags)?.delete(tempObjectId.toHexString());
            }),
          );

          if (error instanceof HTTPFetchError) {
            console.log(`Failed to add tracker tag: ${error.serverError}`);
            toast.error(
              `Failed to add tracker tag: ${
                error.serverError?.startsWith('MongoError: E11000')
                  ? `Tag label "${tagLabel}" can only be used once.`
                  : error.serverError
              }`,
            );
          } else if (error instanceof Error) {
            console.log(`Failed to add tracker tag: ${error.message}`);
            toast.error('Failed to add tracker tag.');
          }
        }
      },
      removeTrackerTag: async (tagId) => {
        const tagToRemove = get().trackers.trackerTags.get(tagId.toHexString());
        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;
              s.trackers.trackerTags.delete(tagId.toHexString());
            }),
          );
          await apiClientV2.deleteTrackerTag(undefined, { params: { tagId } });
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.NORMAL;
            }),
          );

          toast.success('Removed tracker tag.');
        } catch (error) {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.FAILURE;
              {
                tagToRemove != null &&
                  s.trackers.trackerTags.set(tagToRemove.id.toHexString(), castDraft(tagToRemove));
              }
            }),
          );
          if (error instanceof Error) {
            console.log(`Failed to remove tracker tag: ${error.message}`);
            toast.error('Failed to remove tracker tag.');
          }
        }
      },

      updateTrackerTag: async (tagId, trackerTagUpdate) => {
        const oldTrackerTag = get().trackers.trackerTags.get(tagId.toHexString());
        if (oldTrackerTag == null) return;

        const newTrackerTag = {
          ...oldTrackerTag,
          ...trackerTagUpdate,
        };

        if (JSON.stringify(oldTrackerTag) === JSON.stringify(newTrackerTag)) {
          toastInfo('Nothing to update.');
          return;
        }

        try {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.SPINNING;
              s.trackers.trackerTags.set(tagId.toHexString(), castDraft(newTrackerTag));
              s.trackers.filterCounts
                .get(Filters.Tags)
                ?.set(tagId.toHexString(), newTrackerTag.trackerIds.length);
            }),
          );

          const updatedTrackerTag = await apiClientV2.updateTrackerTag(trackerTagUpdate, {
            params: { tagId: tagId.toHexString() },
          });

          toast.success('Tracker tag updated successfully.');
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.NORMAL;
              s.trackers.trackerTags.set(tagId.toHexString(), updatedTrackerTag);
            }),
          );
        } catch (error) {
          set(
            produce((s: DraftState) => {
              s.trackers.savingState = Tristate.FAILURE;
              s.trackers.trackerTags.set(tagId.toHexString(), castDraft(oldTrackerTag));
              s.trackers.filterCounts
                .get(Filters.Tags)
                ?.set(tagId.toHexString(), oldTrackerTag.trackerIds.length);
            }),
          );
          if (error instanceof Error) {
            console.log(`Failed to update tracker tag: ${error.message}`);
            toast.error('Failed to update tracker tag.');
          }
        }
      },

      setShippingInfo: (shippingInfo: ShippingInfo) => {
        set(
          produce((s: DraftState) => {
            s.trackers.shippingInfo = shippingInfo;
          }),
        );
      },

      setShowShippingCol: (showShippingColumn: boolean) => {
        set(
          produce((s: DraftState) => {
            s.trackers.showShippingCol = showShippingColumn;
          }),
        );
      },

      setFilterSet: (newSet) => {
        const currentSet = get().trackers.filterSet;
        const newEntries = Array.from(newSet).filter((f) => !currentSet.has(f));
        const removedEntries = Array.from(currentSet).filter((f) => !newSet.has(f));

        set(
          produce((s: DraftState) => {
            s.trackers.filterSet = castDraft(newSet);
          }),
        );

        if (
          newEntries.some((f) => f.startsWith('/users/')) ||
          removedEntries.some((f) => f.startsWith('/users/'))
        ) {
          set(
            produce((s: DraftState) => {
              s.trackers.lastTrackerLoadedAt = undefined;
            }),
          );
          get().trackers.actions.loadData(false);
        }
      },

      setSearchValue: async (newSearchValue) => {
        const searchIsEmail = isValidEmail(newSearchValue);
        const { users } = searchIsEmail
          ? await apiClient.getUsers({
              search: newSearchValue,
            })
          : { users: [] };

        if (users != null && users.length > 0 && users[0].id != null) {
          const userId = users[0].id;

          set(
            produce((s: DraftState) => {
              s.trackers.users.set(
                userId,
                UserOverviewSchema.parse({
                  id: userId,
                  email: users[0].email,
                  name: users[0].name,
                }),
              );
              s.trackers.filterSet.forEach((f) => {
                if (f.startsWith('/users/')) s.trackers.filterSet.delete(f);
              });
              s.trackers.filterSet.add(`/users/${userId}/owner`);
              s.trackers.filterSet.add(`/users/${userId}/editor`);
              s.trackers.filterSet.add(`/users/${userId}/borrower`);
              s.trackers.searchValue = '';
              s.trackers.lastTrackerLoadedAt = undefined;
            }),
          );
          await get().trackers.actions.loadData(false);
          return;
        }

        set(
          produce((s: DraftState) => {
            s.trackers.searchValue = newSearchValue;
          }),
        );
      },

      resetFilterSelection: async () => {
        set(
          produce((s: DraftState) => {
            s.trackers.filterSet = new Set();
          }),
        );
        await get().trackers.actions.loadData(false);
      },

      changeSelectedCols: (newCols) => {
        set(
          produce((s: DraftState) => {
            s.trackers.selectedCols = newCols;
          }),
        );
      },

      startSession: async () => {
        let mqttClient = get().trackers.mqttClient;
        if (mqttClient?.connected) {
          console.info('MQTT Client is already connected. Skip start of new session!');
        }

        const sessionId = uuid4();
        const newMqttClient =
          mqttClient == null
            ? await RacemapMQTTClient.fromWindow({
                clientId: sessionId,
                username: 'trackerScanner',
                password: 'BoCh8DVwohqMWs7DfpDq',
                subscriptionTopics: [`scan/${sessionId}/receiver`],
                onMessage: get().trackers.actions._handleReaderSessionMessage,
              })
            : null;
        mqttClient = newMqttClient || mqttClient;

        if (mqttClient == null || !mqttClient.connected) {
          throw new Error('Failed to connect to MQTT Broker!');
        }
        set(
          produce((s: DraftState) => {
            s.trackers.sessionId = sessionId;
            if (newMqttClient != null) {
              s.trackers.mqttClient = newMqttClient;
            }
            if (mqttClient?.connected) {
              s.trackers.sessionState = 'CONNECTED';
            }
          }),
        );
      },
      stopSession: async () => {
        const mqttClient = get().trackers.mqttClient;
        if (mqttClient?.connected) {
          mqttClient.disconnect();
        }

        set(
          produce((s: DraftState) => {
            s.trackers.sessionId = null;
            s.trackers.sessionState = 'DISCONNECTED';
          }),
        );
      },
      _handleReaderSessionMessage: (topic, payload) => {
        try {
          const sessionId = get().trackers.sessionId;
          if (sessionId == null) return;

          if (topic !== `scan/${sessionId}/receiver`)
            throw new Error('Receive message on wrong topic!');
          const rawMessage = payload.toString('utf-8');
          const message: ScanSessionMessage = JSON.parse(rawMessage);
          if (message.type == null) throw new Error('Receive invalid message!');

          switch (message.type) {
            case SCAN_SESSION_MESSAGE_TYPES.CONNECT:
              testScanSessionConnectionMessage(message);
              get().trackers.actions._handleReaderConnectAttempt(
                message.readerId,
                message.readerName,
              );
              break;
            case SCAN_SESSION_MESSAGE_TYPES.READ:
              testScanSessionReadMessage(message);
              get().trackers.actions._handleReadMessage(
                message.readerId,
                new ObjectId(message.trackerId),
              );
              break;
            case SCAN_SESSION_MESSAGE_TYPES.DISCONNECT:
              testScanSessionDisconnectionMessage(message);
              get().trackers.actions._handleReaderDisconnectAttempt(message.readerId);
            default:
              console.log(`MQTT: Receive unknown message! Payload: ${payload.toString('utf-8')}`);
          }
        } catch (err) {
          console.error(`MQTT: Failed to handle message! ${err}`);
        }
      },
      _handleReaderConnectAttempt: (readerId, readerName) => {
        set(
          produce((s: DraftState) => {
            const connection = s.trackers.connectedReader.get(readerId) || {
              readerId,
              type: 'App',
              state: 'UNKNOWN',
              readerName: undefined,
              connectedAt: new Date(),
            };
            connection.readerId = readerId;
            connection.state = 'CONNECTED';
            connection.readerName = readerName;
            connection.connectedAt = new Date();
            s.trackers.connectedReader.set(readerId, connection);
          }),
        );
        const client = get().trackers.mqttClient;
        if (client == null) return;

        client.sendScanSessionAcceptConnectMessage(readerId);
        toastInfo(`Reader ${readerName} connected!`);
      },
      _handleReadMessage: (readerId, trackerId) => {
        const client = get().trackers.mqttClient;
        if (client == null) return;

        try {
          const connections = get().trackers.connectedReader;
          const sampleTrackerIds = get().trackers.sampleTrackerIds;
          const trackers = get().trackers.items;

          if (!connections.has(readerId))
            throw new Error(`Receive read from a unknown reader ${readerId}.`);

          if (sampleTrackerIds.has(trackerId.toHexString())) {
            throw new Error('Tracker already added');
          }
          const tracker = trackers.get(trackerId.toHexString());
          if (tracker == null) {
            throw new Error('Unknown tracker!');
          }

          console.log(
            `Reader with id ${readerId} read tracker: ${tracker.trackerName || trackerId}!`,
          );
          get().trackers.actions.moveTrackersToSample([trackerId]);
          client.sendScanSessionAcceptReadMessage(readerId, trackerId.toHexString());
          toastSuccess(`Added Tracker ${tracker.trackerName || trackerId}.`);
        } catch (err) {
          if (err instanceof Error) {
            if (err.message !== 'Tracker already added') console.error(err);
            client.sendScanSessionReadFailMessage(readerId, trackerId.toHexString(), err.message);
          }
        }
      },
      _handleReaderDisconnectAttempt: (readerId) => {
        const client = get().trackers.mqttClient;
        if (client == null) return;
        try {
          const connections = get().trackers.connectedReader;
          const reader = connections.get(readerId);

          if (!connections.has(readerId))
            throw new Error(`Receive disconnect from a unknown reader ${readerId}.`);
          set(
            produce((s: DraftState) => {
              const connection = s.trackers.connectedReader.get(readerId);
              if (connection == null) return;

              connection.readerId = readerId;
              connection.state = 'DISCONNECTED';
              s.trackers.connectedReader.set(readerId, connection);
            }),
          );
          client.sendScanSessionAcceptDisconnectMessage(readerId);
          toastInfo(`Reader ${reader?.readerName || readerId} disconnected!`);
        } catch (err) {
          if (err instanceof Error) {
            console.error(err);
          }
        }
      },
    },
  },
});

const prepareTrackerPrototypes = (
  prototypes: Array<TrackerPrototype>,
): Array<
  Partial<TrackerLegacy> & { trackerId: string; trackerType: string; trackerName: string }
> => {
  return prototypes.map((prototype) => ({
    id: prototype.id?.toHexString(),
    trackerId: prototype.trackerId,
    trackerType: prototype.trackerType,
    trackerName: prototype.trackerName,
    meta: {
      iccid: prototype.iccid,
    },
  }));
};
