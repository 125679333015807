import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import {
  getEventCyclesOfTimeRange,
  getPricingRulesOfEvent,
} from '@racemap/utilities/functions/billing';
import { userNeedsVAT } from '@racemap/utilities/functions/userNeedsVAT';
import { isLegacyRegularUser } from '@racemap/utilities/functions/utils';
import { Alert } from 'antd';
import { FC, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  useCurrentEvent,
  useCurrentUser,
  useEventBasePrice,
  useIsChildAccount,
  useIsReseller,
  useUser,
} from '../../../lib/customHooks';
import { formatPriceWithVAT } from '../../../misc/pricing';
import { getBillableEventsForEvent } from '../../../store/events/events_reducer_helper';
import { FormCheckText } from '../../BasicComponents/Form/FormCheckText';
import {
  AnalyticsTabLink,
  BillingLink,
  CostCalculatorLink,
  PricingLink,
} from '../../BasicComponents/Links';
import { HStack } from '../../BasicComponents/MetaComponent';
import { PopoverHint } from '../../BasicComponents/PopoverHint';
import ButtonWithSpinner, { Tristate } from '../../ButtonWithSpinner';
import { IconArrowRight, IconCreditCard } from '../../Icon';
import { EventCycleHint } from './EventCycleHint';
import { PayLaterList } from './PayLaterList';
import { UserRole, getUserRole } from './utils';

interface Props {
  onClose: () => void;
  onNext: () => Promise<void>;
  checks: Checks;
  onChecksChange: (newValue: Checks) => void;
}

export interface Checks {
  acceptTerms: boolean;
  acceptSubscription: boolean;
}

export const OverviewView: FC<Props> = ({ onClose, onNext, checks, onChecksChange }) => {
  const currentUser = useCurrentUser();
  const currentEvent = useCurrentEvent();
  const isReseller = useIsReseller();
  const isChildAccount = useIsChildAccount();

  if (currentUser == null || currentUser.checkout == null || currentEvent == null) return <></>;

  const acceptedChecks = checks.acceptTerms && checks.acceptSubscription;

  const handleNextClick = async () => {
    if (!acceptedChecks) return;
    await onNext();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>1. Activate event</Modal.Title>
      </Modal.Header>

      <ModalBody>
        {isChildAccount && <EventCycleInfo />}
        <Disclaimer />
        {!isReseller && !isChildAccount && <PayNowList />}
        <FurtherInformation userId={currentUser.id} eventId={currentEvent.id} />
        {!isChildAccount && <PayLaterList event={currentEvent} />}

        <Checks value={checks} onChange={onChecksChange} />
      </ModalBody>
      <Modal.Footer>
        <OverviewViewFooter
          onNextClick={handleNextClick}
          onCancelClick={onClose}
          blocked={!acceptedChecks}
        />
      </Modal.Footer>
    </>
  );
};

interface FooterProps {
  onNextClick: () => Promise<void>;
  onCancelClick: () => void;
  blocked: boolean;
}

const OverviewViewFooter: FC<FooterProps> = ({ onNextClick, onCancelClick, blocked }) => {
  const currentEvent = useCurrentEvent();
  const currentUser = useCurrentUser();
  const [isPreparingNexStep, setIsPreparingNextStep] = useState<Tristate>(Tristate.NORMAL);

  if (currentEvent == null || currentUser == null) return <></>;
  const userRole = getUserRole(currentUser, currentEvent);
  const nextLabel = getNextButtonLabel(userRole);

  const handleNextClick = async () => {
    try {
      setIsPreparingNextStep(Tristate.SPINNING);
      await onNextClick();
      setIsPreparingNextStep(Tristate.NORMAL);
    } catch (e) {
      if (e instanceof Error) {
        setIsPreparingNextStep(Tristate.FAILURE);
        toast.error(e.message);
      }
    }
  };

  return (
    <HStack gap="5px">
      <Button onClick={onCancelClick} variant="outline-secondary">
        Cancel
      </Button>
      <ButtonWithSpinner
        label={nextLabel}
        className="btn-primary"
        onClick={handleNextClick}
        disabled={blocked}
        buttonState={isPreparingNexStep}
      />
    </HStack>
  );
};

const getNextButtonLabel = (userRole: UserRole) => {
  switch (userRole) {
    case UserRole.ADMIN:
    case UserRole.RESELLER:
    case UserRole.CHILD:
      return (
        <>
          <IconCreditCard /> Activate Event
        </>
      );
    case UserRole.REGULAR:
      return (
        <>
          Next <IconArrowRight />
        </>
      );
  }
};

const EventCycleInfo = () => {
  const event = useCurrentEvent();
  if (event == null) return null;
  const possibleBillableEvents = getBillableEventsForEvent(event);
  const pricing = getPricingRulesOfEvent(event);
  const billingCyclesInfo = getEventCyclesOfTimeRange(
    possibleBillableEvents,
    pricing.numDaysEventCycle,
  );

  return (
    <EventCycleInfoContainer>
      <Alert
        description={
          <>
            You are about to active a event with properly{' '}
            <b>{billingCyclesInfo.totalCycles} event cycles</b>.
          </>
        }
        showIcon
      />
    </EventCycleInfoContainer>
  );
};

const EventCycleInfoContainer = styled.section`
  margin: 10px;
  margin-bottom: 30px;
  font-size: 1.1rem;
`;

const ModalBody = styled(Modal.Body)`
  padding: 15px;
`;

const Disclaimer = () => {
  const user = useCurrentUser();
  if (user == null) return <></>;
  const isRegular = isLegacyRegularUser(user);

  return (
    <DisclaimerContainer>
      <p>
        {isRegular && 'By paying the Base Price you activate this event.'} The activation results
        in:
      </p>
      <ul>
        <li>Removal of the watermarks.</li>
        <li>Devices and loads become chargeable.</li>
        <li>
          With each event cycle{' '}
          <PopoverHint variant="description-light">
            <EventCycleHint />
          </PopoverHint>{' '}
          map and used add-ons are charged.
        </li>
      </ul>

      <p>
        If you want to test live tracking or a specific feature, we recommend to keep the event as a
        free trial.
      </p>
    </DisclaimerContainer>
  );
};

const DisclaimerContainer = styled.section`
  margin-bottom: 1rem;

  ul {
    margin-bottom: 1rem;
    padding-left: 2rem;
  }
`;

const PayNowList = () => {
  const event = useCurrentEvent();
  const { user: creator } = useUser({ userId: event?.creatorId });
  const eventBasePrice = useEventBasePrice();
  const needsVAT = userNeedsVAT(creator?.checkout?.address, creator?.checkout?.taxIds);

  if (creator == null || eventBasePrice == null) return null;

  return (
    <PayNowContainer
      message={
        <>
          <h5>Pay now</h5>
          <table>
            <thead>
              <tr>
                <th>Product</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Base Price, includes 1x Map</td>
                <td>{formatPriceWithVAT(eventBasePrice, needsVAT)}</td>
              </tr>
            </tbody>
          </table>
        </>
      }
    />
  );
};

const PayNowContainer = styled(Alert)`
  margin-bottom: 1rem;

  h5 {
    font-size: 1.1rem;
  }

  table {
    width: 100%;
    margin-top: 0.6rem;

    th {
      font-weight: 500;
      border-bottom: 1px solid ${RacemapColors.Gray};
    }
  }
`;

const FurtherInformation: FC<{ userId: string; eventId: string }> = ({ userId, eventId }) => {
  const isChild = useIsChildAccount();

  return (
    <FurtherInformationContainer>
      {!isChild && (
        <div>
          Event usage is tracked in <AnalyticsTabLink eventId={eventId} label="analytics" />, and
          the resulting costs are billed monthly. Review your monthly costs for all your events in
          the <BillingLink label="billing dashboard" userId={userId} openInNewTab />. For detailed
          pricing information, visit our <PricingLink label="pricing page" /> and use the{' '}
          <CostCalculatorLink label="calculator" />.
        </div>
      )}

      <div>
        You can archive an active event to halt additional charges. Archived events do not load the
        Map, Add-Ons, or provide any information. You can reactivate an archived event at any time.
      </div>
    </FurtherInformationContainer>
  );
};

const FurtherInformationContainer = styled.section`
  margin-bottom: 1rem;

  &>div:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

const Checks: FC<{
  value: Checks;
  onChange: (newValue: Checks) => void;
}> = ({ value, onChange }) => {
  const handleChange = (key: keyof typeof value, newValue: boolean) => {
    onChange({ ...value, [key]: newValue });
  };

  return (
    <CheckContainer>
      <section>
        <FormCheckText
          id={'accept-terms'}
          value={value.acceptTerms}
          onChange={(value: boolean) => handleChange('acceptTerms', value)}
        >
          I read and understand the pricing for live tracking. I accept the{' '}
          <a href="/terms-of-use" target="_blank" rel="noreferrer noopener">
            terms of use
          </a>
          .
        </FormCheckText>
      </section>
      <section>
        <FormCheckText
          id={'accept-pricing'}
          value={value.acceptSubscription}
          onChange={(value: boolean) => handleChange('acceptSubscription', value)}
        >
          By paying the Base Price, I authorize Racemap to charge me for any potential subsequent
          usage on a monthly schedule.
        </FormCheckText>
      </section>
    </CheckContainer>
  );
};

const CheckContainer = styled.div`
  margin-top: 1rem;
  padding: 5px;

  &>section:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;
