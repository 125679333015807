import { TagEntry, TagsCollection } from '@racemap/sdk/schema/starters';
import { NO_MEMBER_TAG_VALUE } from '../consts/starters';
import { RacemapStarter } from '../types/events';
import { capitalize } from './utils';

export type TagsCollectionIntermediated = Map<string, Map<string, TagEntry>>;

export const extendIntermediatedTagsCollection = (
  starter: Partial<RacemapStarter>,
  tagsCollected: TagsCollectionIntermediated,
  totalNumberOfStarters: number,
): TagsCollectionIntermediated => {
  if (starter.tags == null) return tagsCollected;
  const newCollection = new Map(tagsCollected);

  for (const [tagCategory, groupOfTheStarter] of Object.entries(starter.tags)) {
    const collectionEntry = newCollection.get(tagCategory);
    if (collectionEntry == null) {
      // tag group is new, add it to the collection
      newCollection.set(
        tagCategory,
        new Map([
          [
            NO_MEMBER_TAG_VALUE,
            { value: NO_MEMBER_TAG_VALUE, label: 'None', count: totalNumberOfStarters - 1 },
          ],
          [
            groupOfTheStarter,
            { value: groupOfTheStarter, label: capitalize(groupOfTheStarter), count: 1 },
          ],
        ]),
      );
      continue;
    }

    const entryValue = collectionEntry.get(groupOfTheStarter);
    // if the group is new then the count is 1 otherwise increase the count
    const newCount = (entryValue?.count || 0) + 1;

    // update or create group entry
    collectionEntry.set(groupOfTheStarter, {
      value: groupOfTheStarter,
      label: capitalize(groupOfTheStarter),
      count: newCount,
    });

    // also take the starter from the "None" group and decrease the counter
    collectionEntry.set(NO_MEMBER_TAG_VALUE, {
      value: NO_MEMBER_TAG_VALUE,
      label: 'None',
      count: (collectionEntry.get(NO_MEMBER_TAG_VALUE)?.count || totalNumberOfStarters) - 1,
    });
  }

  return newCollection;
};

export const getTagsWithCountsFromIntermediated = (
  intermediated: TagsCollectionIntermediated,
): TagsCollection => {
  const tags = new Map();

  for (const [tagKey, tagValues] of intermediated.entries()) {
    tags.set(tagKey, Array.from(tagValues.values()));
  }

  return tags;
};
