import { EventTypes } from '@racemap/utilities/consts/events';
import { FC, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ContestGroupEditor } from '../../components/ContestGroupEditor';
import { EventEditor } from '../../components/EventEditor/EventEditor';
import { useCurrentMapContext } from '../../lib/customHooks';
import { useStore } from '../../store/reducers';

export const EventEditorView: FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const currentEvent = useStore((s) => s.events.getter.currentEvent());
  const { loadCurrentEvent, clearCurrentEvent } = useStore((s) => s.events.actions);
  const mapContexts = useStore((s) => s.maps.ctxs);
  const currentMapContext = useCurrentMapContext();
  const { createMapContext, setCurrentMapId } = useStore((s) => s.maps.actions);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    (async function () {
      if (eventId != null && (currentEvent == null || currentEvent.id !== eventId)) {
        await loadCurrentEvent(eventId);
      }
    })();

    return function cleanup() {
      clearCurrentEvent();
      setCurrentMapId(null);
    };
  }, [eventId]);

  useEffect(() => {
    if (currentMapContext == null && currentEvent != null) {
      const mapId = `map-editor-${currentEvent.id}`;
      if (!mapContexts.has(mapId)) {
        createMapContext(mapId, currentEvent);
      }
      setCurrentMapId(mapId);
    }
  }, [currentMapContext, currentEvent]);

  useEffect(() => {
    if (location.pathname.match(/\/admin\/events\/[a-f0-9]+\/?$/)) {
      if (location.pathname.endsWith('/')) {
        navigate(`${location.pathname}basic`, { replace: true });
      } else {
        navigate(`${location.pathname}/basic`, { replace: true });
      }
    }
  }, [location]);

  if (eventId == null) return <></>;

  if (currentEvent != null) {
    switch (currentEvent.type) {
      case EventTypes.REGULAR:
      case EventTypes.STAGE:
      case EventTypes.CONTEST:
      case EventTypes.STAGE_GROUP:
        return <EventEditor />;
      case EventTypes.CONTEST_GROUP:
        return <ContestGroupEditor />;
      default:
        throw new Error('Event with an unknwon type.');
    }
  }
  return <></>;
};
