import { LastGeo } from '@racemap/sdk/schema/trackers';
import { EMPTY_FEATURE_COLLECTION } from '@racemap/utilities/consts/map';
import { TrackerActivationType } from '@racemap/utilities/consts/trackers';
import { isTrackerOnline } from '@racemap/utilities/functions/trackers';
import { ChildEvent, RacemapEvent, ReadOnlyGeojson } from '@racemap/utilities/types/events';
import { TrackerObject } from '@racemap/utilities/types/trackers';
import { ID } from '@racemap/utilities/types/utils';
import { FeatureCollection } from 'geojson';
import { Immutable } from 'immer';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { CSSProperties } from 'react';
import { ManagedTrackers } from '../../store/trackers/trackers_reducers';
import { castDateTime, getRelativeTime } from './timeUtils';

export const findLastDateAndFirstDate = function (
  events: Immutable<Array<ChildEvent>>,
): [Date | null, Date | null] {
  if (events == null) return [null, null];

  // find the last and first date of all events in the group
  let lastDate: Moment | null = null;
  let firstDate: Moment | null = null;

  for (const event of events) {
    if (event == null) continue;

    const endTime = moment(event.endTime);
    const startTime = moment(event.startTime);

    if (lastDate == null) {
      lastDate = endTime.clone();
    }
    if (firstDate == null) {
      firstDate = startTime.clone();
    }

    if (startTime.isBefore(firstDate)) {
      firstDate = startTime.clone();
    }
    if (endTime.isAfter(lastDate)) {
      lastDate = endTime.clone();
    }
  }

  if (firstDate == null || lastDate == null) return [null, null];

  return [firstDate.toDate(), lastDate.toDate()];
};

export const concatIDLists = function (
  eventIds: Set<ID>,
  eventRefs: {
    [key: string]: RacemapEvent;
  },
  field: string,
): Array<ID> {
  let ids = [];
  if (eventIds == null || eventIds.size === 0) {
    return ids;
  }

  for (const eventId of eventIds) {
    const event = eventRefs[eventId];
    if (event != null && event[field] != null) ids = [...ids, ...event[field]];
  }

  return _.uniq(ids);
};

const getLabelBasedColor = function (tracker: TrackerObject): Array<number> {
  const gpsInfos = getGPSLabelInfos(tracker.lastGeo, false, isTrackerOnline(tracker.state));

  switch (gpsInfos.variant) {
    case 'danger':
      return [178, 13, 13, 255];
    case 'success':
      return [91, 218, 6, 255];
    case 'warning':
      return [240, 195, 45, 255];
    default:
      return [128, 128, 128, 255];
  }
};

export const convertTrackersToFeatureCollection = function (
  trackers: ManagedTrackers,
  selectedTrackers: Array<TrackerObject>,
): ReadOnlyGeojson | FeatureCollection {
  if (trackers.size === 0) {
    return EMPTY_FEATURE_COLLECTION;
  }
  const featureCollection: FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };

  for (const tracker of trackers.values()) {
    const { lat, lng } = tracker.lastGeo;

    if (
      typeof lat !== 'number' ||
      typeof lng !== 'number' ||
      tracker.activation.type === TrackerActivationType.NONE
    ) {
      continue;
    }
    const isSelected =
      selectedTrackers.findIndex((selectedTracker) => selectedTracker.id === tracker.id) >= 0;

    featureCollection.features.push({
      type: 'Feature',
      id: tracker.id,
      geometry: {
        type: 'Point',
        coordinates: [lng, lat],
      },
      properties: {
        name: `Tracker ${tracker.trackerName}`,
        lineColor: getLabelBasedColor(tracker),
        fillColor: isSelected ? [54, 115, 154, 255] : undefined,
        lineWidth: 2,
      },
    });
  }

  return featureCollection;
};

export function getGPSLabelInfos(
  lastGeo: LastGeo,
  disabled: boolean,
  online: boolean,
): {
  variant: 'success' | 'danger' | 'default' | 'warning';
  style: Record<string, any>;
  tooltipMessage: string;
  labelText: string;
} {
  const { lastTime, accuracy } = lastGeo;
  const variant = 'danger';
  const style: CSSProperties = null;

  if (disabled) {
    return {
      variant: 'default',
      style,
      tooltipMessage: '',
      labelText: '',
    };
  }

  if (!online) {
    return {
      variant: 'default',
      style,
      tooltipMessage: 'Unknown: Tracker is not online.',
      labelText: 'Unknown',
    };
  }

  if (lastTime == null) {
    return {
      variant: 'default',
      style,
      tooltipMessage: 'Unknown: No position received yet.',
      labelText: 'Unknown',
    };
  }

  // position is considered old if the difference from now is more than 5 minutes
  if (castDateTime(lastTime).diffNow('minutes').minutes > 5) {
    return {
      variant,
      style,
      tooltipMessage: `Too old: Position is ${getRelativeTime(lastTime)} old!`,
      labelText: 'Too old',
    };
  }

  if (accuracy === -1) {
    // position is less than 5 minute old
    return {
      variant: 'success',
      style,
      tooltipMessage: 'Fix: Got Position but without accuracy information.',
      labelText: 'Fix',
    };
  }

  if (accuracy > 0) {
    // case fix with accurrancy

    return {
      variant: 'success',
      style,
      tooltipMessage: `Fix: Got position with accuracy of ${accuracy}/50.`,
      labelText: 'Fix',
    };
  }

  // case no fix
  return {
    variant,
    style,
    tooltipMessage: 'No Fix: Obtained old position, because module has no fix.',
    labelText: 'No fix',
  };
}
