import styled from '@emotion/styled';
import { isLegacyAdmin, isLegacyChild, isLegacyReseller } from '@racemap/utilities/functions/utils';
import { RacemapEvent } from '@racemap/utilities/types/events';
import { User_Legacy } from '@racemap/utilities/types/users';
import { Space } from 'antd';
import { Immutable } from 'immer';
import { FC } from 'react';
import { Button as BSButton } from 'react-bootstrap';
import { useStore } from '../../../store/reducers';
import { IconOk, IconShoppingCart } from '../../Icon';

interface Props {
  event: Immutable<RacemapEvent>;
  user: Immutable<User_Legacy>;
}

export const PayButton: FC<Props> = ({ event, user }) => {
  const { openPaymentModal } = useStore((s) => s.events.actions);
  const isAllowedToActivate = userIsAllowedToActivate(user, event);
  const canActivateDirectly = isLegacyReseller(user) || isLegacyChild(user) || isLegacyAdmin(user);

  const content = canActivateDirectly ? (
    <Space>
      <IconOk />
      <div>Activate</div>
    </Space>
  ) : (
    <Space>
      <IconShoppingCart />
      <div>Pay now</div>
    </Space>
  );

  return (
    <>
      <Button
        variant="primary"
        onClick={openPaymentModal}
        disabled={!isAllowedToActivate}
        title={
          isAllowedToActivate
            ? 'Activate your event now'
            : 'Only the owner of the Tracking Map can pay for it.'
        }
      >
        {content}
      </Button>
    </>
  );
};

// only the owner of the Tracking Map or the child of the owner can activate a event
// and trigger the payment process
const userIsAllowedToActivate = (
  user: Immutable<User_Legacy>,
  event: Immutable<RacemapEvent>,
): boolean => user.id === event.creatorId || user.parentId === event.creatorId;

const Button = styled(BSButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg[data-icon='cart-shopping'] {
    margin-right: 5px;
  }
`;
