import { FC } from 'react';
import { UserChooser } from './BasicComponents/UserChooser/UserChooser';

import styled from '@emotion/styled';
import { ObjectId } from '@racemap/sdk/schema/base';

type Props = {
  value: ObjectId;
  onChange: (arg0: ObjectId) => void;
  disabled?: boolean;
  label?: string;
};

const Container = styled.div`
  margin-top: 10px;
`;

export const ChangeCreator: FC<Props> = ({ value, onChange, disabled }) => {
  function handleChangeCreator(id: ObjectId | null) {
    if (id == null) return;

    if (window.confirm('Do you really want to change the creator of this event?')) {
      onChange(id);
    }
  }

  return (
    <Container>
      <UserChooser
        value={value}
        onChange={handleChangeCreator}
        disabled={disabled}
        allowClear={false}
      />
    </Container>
  );
};
