import { RacemapColors } from '@racemap/utilities/consts/common';
import { UnitType } from '@racemap/utilities/consts/events';
import { isDefined, isNotNull } from '@racemap/utilities/functions/validation';
import { calculateDistanceInMeter } from '@racemap/utilities/point_utils';
import { EventTrackObject } from '@racemap/utilities/types/geos';
import {
  EChartsOption,
  MarkLineComponentOption,
  TooltipComponentFormatterCallbackParams,
} from 'echarts';
import ReactECharts from 'echarts-for-react';
import { Position } from 'geojson';
import { Immutable } from 'immer';
import React, { FC, memo, useMemo } from 'react';
import {
  labelFormatterDistance,
  labelFormatterElevation,
  unitAwareDistance,
} from '../../Prediction/AnalyseComponents/utils';
import { tooltipFormatter } from './Tooltip';

export interface Marker {
  name: string;
  coordinateIndex: number;
  color?: string;
  highlight?: boolean;
  offset?: number;
}
interface Props {
  track: Immutable<EventTrackObject>;
  markers?: Array<Marker>;
  onHoverPosition?: (index: number | null) => void;
  onClickPosition?: (index: number) => void;
  onHoverMarker?: (marker: Marker | null) => void;
  unit?: UnitType;
}

export const ElevationChart: FC<Props> = memo(function ElevationChart({
  track,
  markers = [],
  onHoverPosition = () => {},
  onClickPosition = () => {},
  onHoverMarker = () => {},
  unit = UnitType.METRIC,
}: Props) {
  const eventHandlers = {
    click: (params: TooltipComponentFormatterCallbackParams) => {
      if (Array.isArray(params)) {
        eventHandlers.mouseover(params[0]);
        return;
      }

      const index = params.dataIndex;
      if (params.componentType === 'series' && params.componentSubType === 'line') {
        onClickPosition(index);
      }
    },
    mouseover: (params: TooltipComponentFormatterCallbackParams) => {
      if (Array.isArray(params)) {
        eventHandlers.mouseover(params[0]);
        return;
      }

      const index = params.dataIndex;
      if (params.componentType === 'series' && params.componentSubType === 'line') {
        onHoverPosition(index);
        return;
      }

      if (params.componentType === 'markLine') {
        // @ts-expect-error data field is setted with object
        const marker = markers.find((m) => m.coordinateIndex === params.data.pointIndex);

        if (marker) onHoverMarker(marker);
        return;
      }
    },
    mouseout: (params: TooltipComponentFormatterCallbackParams) => {
      if (Array.isArray(params)) {
        eventHandlers.mouseout(params[0]);
        return;
      }

      if (params.componentType === 'markLine') {
        onHoverMarker(null);
      }
    },
    globalout: () => {
      onHoverPosition(null);
    },
  };
  const option = useMemo(
    () => getOption(track, markers, eventHandlers.mouseover, unit),
    [track, markers, eventHandlers, unit],
  );

  return (
    <ReactECharts
      option={option}
      onEvents={eventHandlers}
      style={{ height: '100%', width: '100%' }}
    />
  );
});

const getOption = (
  track: Immutable<EventTrackObject>,
  markers: Array<Marker>,
  onHoverEvent: (params: TooltipComponentFormatterCallbackParams) => void,
  unit: UnitType,
): EChartsOption => {
  const coordinates = track.geometry.coordinates;
  const heightDistanceData = getData(coordinates, unit);
  const markLinesData = getMarkLines(markers, heightDistanceData);
  const maxValueX = heightDistanceData[heightDistanceData.length - 1][0] + 0.005 || 0;

  return {
    name: track.properties.name,
    grid: {
      left: 70,
      right: 25,
      top: '19%',
      bottom: '15%',
    },
    xAxis: {
      type: 'value',
      name: 'Offset [km]',
      nameLocation: 'middle',
      nameGap: 15,
      axisLine: {
        lineStyle: {
          color: 'white',
        },
      },
      axisLabel: {
        color: 'white',
        formatter: (value: number) => labelFormatterDistance(value, unit, 1, false),
      },
      max: maxValueX,
    },
    animation: false,
    yAxis: {
      type: 'value',
      name: 'Elevation [m]',
      axisLine: {
        lineStyle: {
          color: 'white',
        },
      },
      nameLocation: 'middle',
      nameGap: 45,
      axisLabel: {
        formatter: (value: number) => labelFormatterElevation(value, unit, 0, false),
        color: 'white',
      },
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        onHoverEvent(params);
        return tooltipFormatter(params, markers, unit);
      },
    },
    series: [
      {
        type: 'line',
        id: `track_${track.id}`,
        color: RacemapColors.PaleBlue,
        name: track.properties.name,
        data: heightDistanceData,
        showSymbol: false,
        areaStyle: {
          color: RacemapColors.CloudBlue,
        },
        markLine: {
          symbol: ['none', 'none'],
          data: markLinesData,
        },
      },
    ],
  };
};

const getData = (
  coordinates: Array<Position> | Immutable<Array<Position>>,
  unit: UnitType,
): Array<Array<number>> => {
  let currentDistanceInMeter = 0;

  return coordinates
    .map((cV, i) => {
      if (cV[2] == null) return null;
      if (i === 0) return [currentDistanceInMeter, cV[2]];
      const currentPoint = coordinates[i];
      const prevPoint = coordinates[i - 1];
      const distanceToPrevPoint = calculateDistanceInMeter(
        currentPoint[1],
        currentPoint[0],
        prevPoint[1],
        prevPoint[0],
      );
      currentDistanceInMeter += distanceToPrevPoint;
      return [currentDistanceInMeter, currentPoint[2]];
    })
    .filter(isNotNull)
    .map((e) => [unitAwareDistance(e[0], unit), unitAwareDistance(e[1], unit)]);
};

const getMarkLines = (
  markers: Array<Marker>,
  heightDistanceData: Array<Array<number>>,
): MarkLineComponentOption['data'] => {
  if (heightDistanceData.length === 0) return [];

  return markers
    .map((m) => {
      if (heightDistanceData[m.coordinateIndex] == null) return undefined;
      const isHeighlight = !!m.highlight;

      return {
        name: m.name,
        lineStyle: {
          color: m.color,
          width: isHeighlight ? 2 : 1,
        },
        label: {
          formatter: () => m.name,
          color: m.color,
          backgroundColor: 'white',
          borderColor: m.color,
          fontWeight: isHeighlight ? 600 : undefined,
          borderType: 0,
          borderWidth: isHeighlight ? 2 : 1,
          borderRadius: 3,
          padding: 2,
        },
        pointIndex: m.coordinateIndex,
        xAxis: heightDistanceData[m.coordinateIndex][0],
      };
    })
    .filter(isDefined);
};
