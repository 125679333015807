import { groupBy } from '@racemap/utilities/functions/utils';
import { EventLoadTypes } from '@racemap/utilities/types/events';
import { DailyLoads as Load } from '@racemap/utilities/types/users';
import { Space, Spin } from 'antd';
import { BarSeriesOption } from 'echarts';
import { Immutable } from 'immer';
import { DateTime } from 'luxon';
import { FC, useMemo } from 'react';
import { XYAreachart } from '../XYAreachart';
import { ChartOptions, Series, XAxisOption, YAxisOption } from '../XYAreachart/XYAreachart';
import { getSeriesColor, loadTypeLabels } from './utils';

interface Props {
  loads: Immutable<Array<Load>>;
  markLines?: Array<{ time: Date; label: string }>;
  zoomStart?: Date;
  zoomEnd?: Date;
  showLoading?: boolean;
  interval?: 'daily' | 'hourly';
  onDateRangeChange?: (start: Date | null, end: Date | null) => void;
  eventNameMapping?: Record<string, string>;
}

export const LoadsChart: FC<Props> = ({
  loads,
  markLines,
  zoomEnd,
  zoomStart,
  interval = 'daily',
  showLoading = false,
  onDateRangeChange,
  eventNameMapping,
}) => {
  const sortedLoads = [...loads].sort((a, b) => Date.parse(a.time) - Date.parse(b.time));
  const basicObject = getBasicObject(sortedLoads, interval, zoomStart, zoomEnd);
  const relevantEventIds = new Set(sortedLoads.map((p) => p.eventId));
  const loadsGroupedByTypeAndEventId = groupBy(sortedLoads, (p) => {
    const type = 'type' in p && p.type != null ? p.type : EventLoadTypes.MAP_LOADS;
    return `${type}_${p.eventId || ''}`;
  });
  const zoomRange = getZoomRange(
    basicObject,
    zoomStart || DateTime.now().minus({ month: 1 }).toJSDate(),
    zoomEnd || new Date(),
  );

  const preparedData = Array.from(loadsGroupedByTypeAndEventId.values())
    .map((loads, index) => {
      const type =
        'type' in loads[0] && loads[0].type != null ? loads[0].type : EventLoadTypes.MAP_LOADS;
      const basicData = { ...basicObject };
      for (const load of loads) {
        const key = DateTime.fromISO(load.time)
          .startOf(interval === 'daily' ? 'day' : 'hour')
          .toMillis();
        basicData[key] += load.count || 0;
      }
      const data = Object.entries(basicData).map(([time, count]) => [parseInt(time), count]);
      const markLine: BarSeriesOption['markLine'] = { data: [] };
      const eventId = loads[0].eventId;
      const eventName =
        relevantEventIds.size > 1 ? eventNameMapping?.[eventId || ''] || eventId : '';
      const color = getSeriesColor(loads[0].type, loads[0].eventId, eventNameMapping || {});

      if (index === 0) {
        markLines?.forEach((mark, index) => {
          const xAxis = DateTime.fromJSDate(mark.time).toMillis();
          markLine.data?.push([
            {
              label: {
                show: true,
                formatter: mark.label,
              },
              yAxis: 'min',
              xAxis,
              symbol: 'none',
            },
            {
              y: index % 2 === 0 ? 57 : 70,
              symbol: 'none',
              xAxis,
            },
          ]);
        });
      }

      return {
        data,
        name: `${loadTypeLabels[type]} ${eventName}`,
        color,
        type: 'bar',
        barMinWidth: 2,
        markLine,
        stack: loadTypeLabels[type],
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name)) as Series;

  const xAxisOption: XAxisOption<Load> = useMemo(
    () => ({
      dataKey: 'time',
      type: 'time',
      axisLabel: {
        formatter: {
          day: '{yyyy}-{MM}-{dd}',
        },
      },
      axisPointer: {
        show: true,
        label: {
          show: true,
        },
      },
    }),
    [],
  );
  const yAxisOption: YAxisOption<Load> = useMemo(
    () => ({
      dataKey: 'count',
      name: 'Loads',
    }),
    [],
  );

  const chartOptions: ChartOptions = {
    legend: {
      show: true,
      type: 'scroll',
    },
    grid: {
      top: 70,
      left: 50,
      right: 40,
      bottom: 70,
      show: true,
    },
    dataZoom: [
      {
        type: 'inside',
        startValue: zoomRange.startDate.getTime(),
        endValue: zoomRange.endDate.getTime(),
        filterMode: 'empty',
      },
      {
        type: 'slider',
        startValue: zoomRange.startDate.getTime(),
        endValue: zoomRange.endDate.getTime(),
        filterMode: 'empty',
        labelFormatter: (value) => DateTime.fromMillis(value).toFormat('yyyy-MM-dd HH:mm'),
      },
    ],
  };

  return (
    <Spin spinning={showLoading}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <XYAreachart
          series={preparedData}
          xAxisOption={xAxisOption}
          yAxisOption={yAxisOption}
          chartOptions={chartOptions}
          onDataZoomChange={(start, end) => {
            onDateRangeChange?.(
              start != null ? new Date(start) : null,
              end != null ? new Date(end) : null,
            );
          }}
        />
      </Space>
    </Spin>
  );
};

const getBasicObject = (
  sortedLoads: Array<Load>,
  interval: 'daily' | 'hourly',
  zoomStart?: Date,
  zoomEnd?: Date,
): Record<number, number> => {
  const isDaily = interval === 'daily';
  if (sortedLoads.length < 1) return {};

  const minDate = DateTime.fromMillis(
    Math.min(Date.parse(sortedLoads[0].time), zoomStart?.getTime() || Infinity),
  ).startOf(interval === 'daily' ? 'day' : 'hour');
  const maxDate = DateTime.fromMillis(
    Math.max(Date.parse(sortedLoads[sortedLoads.length - 1].time), zoomEnd?.getTime() || -Infinity),
  ).endOf(interval === 'daily' ? 'day' : 'hour');
  const numberEntries = Math.ceil(
    maxDate.diff(minDate, isDaily ? 'days' : 'hours')[isDaily ? 'days' : 'hours'],
  );
  const basicObject = Object.fromEntries(
    Array.from({ length: numberEntries }, (_, i) => i).map((i) => [
      minDate.plus({ [isDaily ? 'day' : 'hour']: i }).toMillis(),
      0,
    ]),
  );

  return basicObject;
};

const getZoomRange = (
  loads: Record<number, number>,
  defaultMinDate: Date,
  defaultMaxDate: Date,
): { startDate: Date; endDate: Date } => {
  const times = Object.keys(loads).map((time) => parseInt(time));
  if (times.length < 1) return { startDate: defaultMinDate, endDate: defaultMaxDate };
  const rangeMinDate = times[0];
  const rangeMaxDate = times[times.length - 1];

  const startDate = new Date(Math.max(rangeMinDate, defaultMinDate.getTime()));
  const endDate = new Date(Math.min(rangeMaxDate, defaultMaxDate.getTime()));

  return { startDate, endDate };
};
