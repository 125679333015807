// a safe function to compare two numbers. input can be number, NaN, undefined, null, or Infinity.
export function compareNumber(value1?: number | null, value2?: number | null): number {
  if (value1 === value2 || (value1 == null && value2 == null)) {
    return 0;
  } else if (value1 == null || isNaN(value1) || value1 === Infinity) {
    return 1;
  } else if (value2 == null || isNaN(value2) || value2 === Infinity) {
    return -1;
  } else if (value1 < value2) {
    return -1;
  } else if (value1 > value2) {
    return 1;
  } else {
    throw new Error('Unexpected case');
  }
}
