import { downloadBlob } from '@racemap/utilities/functions/download';
import { Load } from '@racemap/utilities/types/events';
import { Button, Flex } from 'antd';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { useCurrentEvent } from '../../../lib/customHooks';
import { CopyButton } from '../../BasicComponents/CopyButton';

interface Props {
  loads: Array<Load> | undefined;
  eventNameMapping?: Record<string, string>;
}

export const Buttons: FC<Props> = ({ loads, eventNameMapping }) => {
  const currentEvent = useCurrentEvent();

  const handleCSVDownload = async () => {
    if (currentEvent == null || loads == null) return;
    const blob = new Blob([buildLoadsCSVFile(loads, eventNameMapping)], {
      type: 'text/csv;charset=utf-8;',
    });
    downloadBlob(blob, `LoadsList_${currentEvent.name}-${DateTime.now().toISO()}.csv`);
  };

  if (currentEvent == null) return null;

  return (
    <Flex justify="flex-end" gap={10} style={{ padding: '0 15px' }}>
      <CopyButton link={`https://racemap.com/api/events/${currentEvent.id}/loads`} label="Loads" />
      <Button onClick={handleCSVDownload} variant="outlined">
        CSV Download Loads
      </Button>
    </Flex>
  );
};

function buildLoadsCSVFile(
  loads: Array<Load> | undefined,
  eventNameMapping?: Record<string, string>,
) {
  let csvString = 'time;loadType;eventName;eventId;count';
  if (loads === undefined) return csvString;

  for (const load of loads) {
    if (load == null) {
      continue;
    }

    let row = [
      load.time,
      load.type ?? 'MAP',
      eventNameMapping?.[load.eventId] || '',
      load.eventId,
      load.count,
    ];
    row = row.map((i) => (i == null ? '' : i));
    if (row.some((i) => i !== '')) {
      csvString += `\r\n${row.join(';')}`;
    }
  }
  return csvString;
}
