import styled from '@emotion/styled';
import { Spin } from 'antd';

export const LoadingSpinner = () => {
  return (
    <Wrapper>
      <Spin size="large" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
