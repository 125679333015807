import { useEffect, useRef } from 'react';

/**
 * Custom hook that updates the document title.
 * @param title - The new title for the document.
 */
export function useDocumentTitle(title: string): void {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    return () => {
      document.title = defaultTitle.current;
    };
  }, []);
}
