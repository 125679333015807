import { RacemapAPIClient } from '@racemap/utilities/api-client';
import { OneMinuteInMillis } from '@racemap/utilities/consts/time';
import { Load } from '@racemap/utilities/types/events';
import useSWR from 'swr';

const apiClient = RacemapAPIClient.fromWindowLocation();

interface Return {
  loads: Array<Load>;
  isLoading: boolean;
}

export const useEventLoads = (eventId?: string | null): Return => {
  const { data, isLoading } = useSWR(eventId ? ['GET', 'EVENT_LOADS', eventId] : null, fetchLoads, {
    refreshInterval: OneMinuteInMillis,
  });

  return { loads: data?.loads || [], isLoading };
};

const fetchLoads = async ([, , eventId]: [string, string, string]): Promise<{
  loads: Array<Load>;
}> => {
  const loads = await apiClient.getEventLoads(eventId);

  return {
    loads: loads.sort((a, b) => new Date(b.time).valueOf() - new Date(a.time).valueOf()),
  };
};
