export enum MessageType {
  Error = 'error',
  Scheduled = 'scheduled',
  Pending = 'pending',
}

export enum TimePeriod {
  lastFiveMinutes = '5min',
  lastTenMinutes = '10min',
  lastOneHour = '60min',
}
