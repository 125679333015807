import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import React, { FC, useState } from 'react';
import { useLocation } from 'react-router';
import { animated, useSpring } from 'react-spring';
import { AnimatedArrow } from '../AnimatedArrow';
import { type Props as SidebarProps, Sections, Sidebar, Tabs } from '../Sidebar';

interface Props extends SidebarProps {
  tabs: Tabs;
  sections: Sections;
}

export const ButtonRouter: FC<Props> = ({ tabs, sections }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const sP = useSpring({
    to: {
      height: isOpen ? 250 : 0,
      opacity: isOpen ? 0.5 : 0,
      borderColor: isOpen ? RacemapColors.PaleBlue : '#e7e7e7',
      borderWidth: isOpen ? 2 : 0,
    },
  });

  const currentTab = tabs.find((t) => location.pathname.startsWith(t.path));

  const handleOverlayClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>,
  ) => {
    if (!isOpen) return;
    e.stopPropagation();
    setIsOpen(false);
  };

  return (
    <>
      <Button onClick={() => setIsOpen(!isOpen)}>
        <AnimatedArrow isDown={isOpen} showClosedToLeft />
        {currentTab?.icon}
      </Button>
      <>
        <Overlay
          style={{
            opacity: sP.opacity,
            display: sP.opacity.to((o: number) => (o === 0 ? 'none' : 'block')),
          }}
          onClick={(e) => handleOverlayClick(e)}
          onTouchStart={(e) => handleOverlayClick(e)}
        />
        <SidebarContainer
          style={{
            height: sP.height,
            display: sP.height.to((o: number) => (o === 0 ? 'none' : 'block')),
            borderColor: sP.borderColor,
            borderWidth: sP.borderWidth,
          }}
        >
          <Sidebar tabs={tabs} sections={sections} onTabChoosing={() => setIsOpen(false)} />
        </SidebarContainer>
      </>
    </>
  );
};

const Button = styled.button`
  width: 100%;
  height: 100%;
  background: white;
  border: unset;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: larger;

  :hover {
    background: ${RacemapColors.CloudBlue};
  }
`;

const SidebarContainer = styled(animated.div)`
  position: fixed;
  left: 0;
  background: white;
  right: 0;
  height: 0;
  overflow: auto;
  border-top: solid 2px ${RacemapColors.PaleBlue};
  border-bottom: solid 2px ${RacemapColors.PaleBlue};
  display: none;
`;

const Overlay = styled(animated.div)`
  position: fixed;
  top: 106px;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  display: none;
`;
