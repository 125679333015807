import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import React, { FC } from 'react';
import { IconFail } from '../../Icon';
import { Center, HStack } from '../MetaComponent';

interface Props {
  message: string;
}

export const ErrorMessage: FC<Props> = ({ message }) => {
  return (
    <Container>
      <HStack gap="5px">
        <IconFail /> {message}
      </HStack>
    </Container>
  );
};

const Container = styled(Center)`
    color: ${RacemapColors.DarkRed};
    padding: 5px;
    font-weight: 500;
`;
