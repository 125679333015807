import {
  Admin,
  RegularUser,
  Reseller,
  UserOverview,
  UserOverviewSchema,
  UserSchema,
} from '@racemap/sdk/schema/user';
import { Immutable } from 'immer';
import moment, { Duration } from 'moment';
import { Moment } from 'moment';
import shortHash from 'shorthash2';
import tinyColor from 'tinycolor2';
import { DefaultColorObject } from '../consts/common';
import eventTypes, { EventType } from '../consts/eventTypes';
import { AtomicEvents, AuthorizationStates, DeviceClasses, EventTypes } from '../consts/events';
import {
  AtomicEvent,
  ChildEvent,
  EventDocument,
  GroupEvent,
  RacemapContestGroup,
  RacemapEvent,
  RacemapEventCommon,
  RacemapRegularEvent,
  RacemapStageGroup,
  RacemapStarter,
  StarterDocument,
} from '../types/events';
import {
  AdminLegacy,
  ChildUserLegacy,
  RegularUserLegacy,
  ResellerLegacy,
  User,
  User_Legacy,
} from '../types/users';
import { ColorArray, EnumType, ObjectId } from '../types/utils';
import { currentEventTimes } from './event';
import { validateNumber, validateString } from './validation';

export function genGenericImportKey(eventId: string, importURL: string, importId: string): string {
  return `${eventId}_${shortHash(importURL)}_${importId}`;
}

export function groupBy<K, V>(array: Array<V>, iteratee: (item: V) => K): Map<K, Array<V>> {
  const output = new Map();
  for (const item of array) {
    const key = iteratee(item);
    if (output.has(key)) {
      output.get(key).push(item);
    } else {
      output.set(key, [item]);
    }
  }
  return output;
}

export function sumBy<T>(arr: Array<T>, func: (item: T) => number): number {
  return arr.reduce((acc, item) => acc + func(item), 0);
}

export function uniqueFilter(value: number, index: number, self: Array<number>): boolean {
  return self.indexOf(value) === index;
}

export function toArray<T>(val: Array<T> | T | undefined | null): Array<T> {
  if (val == null) return [];
  return Array.isArray(val) ? val : [val];
}

export function shuffleArray<T>(array: Array<T>): Array<T> {
  for (let i: number = array.length - 1; i > 0; i--) {
    const j: number = Math.floor(Math.random() * (i + 1));
    const tmp: T = array[i];
    array[i] = array[j];
    array[j] = tmp;
  }
  return array;
}

export function removeUndefined<T>(
  obj: Record<string | number | symbol, T | undefined>,
): Partial<Record<string | number | symbol, T | undefined>> {
  return Object.keys(obj).reduce((acc, key) => {
    const _acc = acc;
    if (obj[key] !== undefined) _acc[key] = obj[key];
    return _acc;
  }, {} as Partial<Record<string | number | symbol, T | undefined>>);
}

export function uuid4(): string {
  return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/x/g, () =>
    ((Math.random() * 16) | 0).toString(16),
  );
}

export function uuid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * Finds the longest common prefix of an array of strings.
 *
 * @param {Array<string>} array - The array of strings to find the longest common prefix of.
 * @returns {string} - The longest common prefix of the input array.
 *
 * source: Player - https://github.com/racemap/player/blob/ae7e4da4044fa660f95de38646a462e8ffcaf59c/src/js/utils.ts#L448
 *
 * @example
 * const arr = ['apple', 'app', 'apartment'];
 * const prefix = longestCommonPrefix(arr); // 'ap'
 */
export function longestCommonPrefix(array: Array<string>): string {
  const A = array.concat().sort();
  if (A.length === 0) return '';
  const a1 = A[0];
  const a2 = A[A.length - 1];
  const L = a1.length;
  let i = 0;
  while (i < L && a1.charAt(i) === a2.charAt(i)) i++;
  return a1.substring(0, i);
}

/*
 * this Aligns a point in time to multiples of aTimeStepWidth
 * aTime           = 79.8 seconds
 * aTimeStepWidth  = 10.0 seconds
 * aResult         = 70.0 seconds
 * Test exists
 */
export function floorTime(aTimeInMillis: number, aTimeStepWidthInMillis = 10000): number {
  return Math.floor(aTimeInMillis / aTimeStepWidthInMillis) * aTimeStepWidthInMillis;
}

/*
 * this Aligns a point in time to multiples of aTimeStepWidth
 * aTime           = 79.8 seconds
 * aTimeStepWidth  = 10.0 seconds
 * aResult         = 80.0 seconds
 * Test exists
 */
export function ceilTime(aTimeInMillis: number, aTimeStepWidthInMillis = 10000): number {
  return Math.ceil(aTimeInMillis / aTimeStepWidthInMillis) * aTimeStepWidthInMillis;
}

export function sleep(millisToSleep: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, millisToSleep);
  });
}

/**
 * Generates a range of numbers from `a` to `b` with a specified step.
 * @param a The starting number of the range.
 * @param b The ending number of the range.
 * @param step The step size between each number in the range. Default is 1.
 * @returns A generator that yields each number in the range.
 */
export function* range(a: number, b: number, step = 1): Generator<number> {
  const isReversed = a - b > 0;
  const resStep = isReversed ? -step : step;

  for (let i = a; (isReversed && i >= b) || (!isReversed && i <= b); i += resStep) {
    yield i;
  }
}

export function* infiniteRange(start: number, step = 1): Generator<number> {
  for (let i = start; true; i += step) {
    yield i;
  }
}

export function unique<T>(array: Array<T>): Array<T> {
  return [...new Set(array)];
}

export function timeIsEqual(a?: number | string | null, b?: number | string | null): boolean {
  if ((a == null || isEmptyString(a)) && (b == null || isEmptyString(b))) return true;

  const dateA = typeof a === 'string' ? Date.parse(a) : a;
  const dateB = typeof b === 'string' ? Date.parse(b) : b;

  return dateA === dateB;
}

export class DefaultMap<K, V> extends Map<K, Set<V>> {
  push(key: K, item: V): void {
    const entry = this.get(key);
    if (entry == null) {
      this.set(key, new Set([item]));
    } else {
      entry.add(item);
    }
  }

  pushMany(key: K, items: Array<V>): void {
    if (!this.has(key)) {
      this.set(key, new Set(items));
    } else {
      const current = this.get(key);
      if (current == null) return;

      for (const item of items) {
        current.add(item);
      }
    }
  }
}

/**
 * build a random string with 9 characters
 * @returns string
 */
export function shortIdBuilder(): string {
  return characterIdBuilder();
}

/**
 * build a random numerical string with 5 characters
 * @returns numerical id
 */
export function numericalIdBuilder(): string {
  return Math.round(Math.random() * 10e4)
    .toString()
    .padStart(5, '0');
}

/**
 * build a character based id with a controlable number of characters
 * @param numberOfCharacter - The number digits the id will have
 * @returns id
 */
export function characterIdBuilder(numberOfCharacter = 9): string {
  return Math.random().toString(36).substr(2, numberOfCharacter);
}

export function getDefaultSpeed(sport: string | null | undefined): number {
  if (sport == null) return -1;
  const eventType = getEventTypeById(sport);
  return eventType?.defaultSpeed || -1;
}

export function getEventTypeById(eventTypeId: string | null): EventType | null {
  const eventType = eventTypes.find((e) => e.id === eventTypeId);
  return eventType || null;
}

export function getEventTypeName(sport: string): string | null {
  const eventType = getEventTypeById(sport);
  return eventType?.name || null;
}

export function mean(items: Array<number>): number {
  if (items.length === 0) {
    return 0;
  }
  return items.reduce((r, a) => r + a) / items.length;
}

export function median(items: Array<number>): number {
  if (items.length === 0) {
    return 0;
  }
  const _items = items.slice(0).sort();
  return _items[Math.round(_items.length / 2) - 1];
}

export const isStarterFree = (starter: RacemapStarter | StarterDocument): boolean =>
  starter.appId == null ||
  starter.appId === '' ||
  (starter.key != null && !starter.keyUsed && starter.deviceClass !== DeviceClasses.Tracker);

export function rgbArrayToObject(rgbArray: ColorArray | null): {
  r: number;
  g: number;
  b: number;
  a: number;
} {
  if (rgbArray == null) {
    return DefaultColorObject;
  }
  const [r, g, b, a] = rgbArray;

  return { r, g, b, a: (a || 255) / 255.0 };
}

export function rgbObjectToArray({
  r,
  g,
  b,
  a,
}: {
  r: number;
  g: number;
  b: number;
  a: number;
}): [number, number, number, number] {
  return [r, g, b, a * 255];
}

export function rgbStringToArray(rgb: string): ColorArray {
  return rgbObjectToArray(tinyColor(rgb).toRgb());
}

export function rgbArrayToString(rgbArray: ColorArray | null): string {
  if (Array.isArray(rgbArray) && rgbArray[3] == null) {
    rgbArray[3] = 255;
  }
  return tinyColor(rgbArrayToObject(rgbArray)).toHexString();
}

export function isColorArray(color: unknown): color is ColorArray {
  return (
    Array.isArray(color) &&
    color.length > 2 &&
    color.length < 5 &&
    color.every((c) => typeof c === 'number' && c <= 255)
  );
}

export function exists<T>(value: T | null | undefined): value is T {
  if (value === null || value === undefined) return false;
  return true;
}

export const isRacemapEvent = (event: any): event is RacemapEvent => {
  return (
    event.id != null &&
    event.type != null &&
    event.name != null &&
    Object.values(EventTypes).includes(event.type)
  );
};

/**
 * return true if the given event is a Contest Group or Stage Group
 * @param event to check
 * @returns
 */
export const isGroupEvent = (event: unknown): event is GroupEvent => {
  if (event == null) return false;
  return isRacemapEvent(event) && !AtomicEvents.includes(event.type);
};

export const isRegularEvent = <T extends { type: RacemapEventCommon['type'] } = RacemapEventCommon>(
  event: Immutable<T> | T,
): event is RacemapRegularEvent<T> => {
  return event.type === EventTypes.REGULAR;
};

/**
 * return true if the given event is a atomic event. For example a
 * regular event or event of a contest or stage group.
 * @param event to test
 * @returns
 */
export const isAtomicEvent = (
  event: Immutable<{ type: RacemapEvent['type'] }>,
): event is AtomicEvent => {
  if (event == null) return false;
  return AtomicEvents.includes(event.type);
};

/**
 * return true if given event is a child event
 * @param event
 * @returns
 */
export const isChildEvent = <T extends { type: RacemapEventCommon['type'] } = RacemapEventCommon>(
  event: T,
): event is ChildEvent<T> => {
  if (event == null) return false;
  return event.type === EventTypes.CONTEST || event.type === EventTypes.STAGE;
};

export const isContestGroupEvent = (event: { type: EventTypes }): event is RacemapContestGroup => {
  if (event == null) return false;
  return event.type === EventTypes.CONTEST_GROUP;
};

export const isStageGroupEvent = (
  event: RacemapEvent | Immutable<RacemapEvent>,
): event is RacemapStageGroup => {
  if (event == null) return false;
  return event.type === EventTypes.STAGE_GROUP;
};

export const isUserOverview = (user: unknown): user is UserOverview => {
  return UserOverviewSchema.safeParse(user).success;
};

export const isUser = (user: unknown): user is User => {
  return UserSchema.safeParse(user).success;
};

export function eventIsNeitherFreeNorPaid(
  event: Immutable<{ authorization: RacemapEvent['authorization'] }>,
): boolean {
  return !eventIsPaid(event) && !eventIsFree(event);
}

export function eventIsPaid(event: { authorization?: AuthorizationStates }): boolean {
  return (
    event.authorization === AuthorizationStates.PAID ||
    event.authorization === AuthorizationStates.PAID_FREE_LOADINGS
  );
}

export function eventIsFree(
  event: Immutable<{ authorization: RacemapEvent['authorization'] }>,
): boolean {
  return event.authorization === AuthorizationStates.FREE;
}

export function isPastEvent(
  event: RacemapEvent | Immutable<RacemapEvent> | EventDocument,
  thresholdDurationInMs = 0,
): boolean {
  const time = event.endTime instanceof Date ? event.endTime.getTime() : Date.parse(event.endTime);
  const timeWithThreshold = time + thresholdDurationInMs;

  return timeWithThreshold < Date.now();
}

export function isRunningEvent(
  event: RacemapEvent | Immutable<RacemapEvent>,
  mindRepeatEvents = false,
): boolean {
  const now = Date.now();
  if (mindRepeatEvents) {
    const [startTime, endTime] = currentEventTimes(event);
    if (startTime == null || endTime == null) return false;
    return startTime < now && endTime > now;
  }
  if (event.startTime == null || event.endTime == null) return false;

  return Date.parse(event.startTime) < Date.now() && Date.parse(event.endTime) > Date.now();
}

export function isFutureEvent(
  event: RacemapEvent | Immutable<RacemapEvent> | EventDocument,
  thresholdDurationInMs = 0,
): boolean {
  const time =
    event.startTime instanceof Date ? event.startTime.getTime() : Date.parse(event.startTime);
  const timeWithThreshold = time - thresholdDurationInMs;

  return timeWithThreshold > Date.now();
}

export const capitalize = (s: string): string => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * V1 201227T005810Z
 * V2 20201227T005810Z
 * @param timeString
 */

export function parseBaseTime(timeString: string): Moment {
  if (timeString == null || typeof timeString !== 'string')
    throw new Error('Need a valid string, to parse base time!');

  const len = timeString.length;
  if (len === 14 && timeString.indexOf('T') === 6 && timeString.indexOf('Z') === 13) {
    return moment.utc(timeString, 'YYMMDDTHHmmssZ');
  }
  if (len === 16 && timeString.indexOf('T') === 8 && timeString.indexOf('Z') === 15) {
    return moment.utc(timeString, 'YYYYMMDDTHHmmssZ');
  }

  throw new Error('Receive wronge time stamp format. Cant parse base time!');
}

export function truncateString(str: string, n: number, useWordBoundary = true): string {
  if (str.length <= n) return str;
  const subString = str.substr(0, n - 1); // the original check

  return `${
    useWordBoundary && (subString.match(/\s/g) || []).length > 0
      ? subString.substr(0, subString.lastIndexOf(' '))
      : subString
  }...`;
}

export function isEmptyString(str: unknown) {
  return str != null && typeof str === 'string' && str === '';
}

export function isNotEmptyString(str: unknown): str is string {
  return str != null && typeof str === 'string' && str !== '';
}

export function isEmptyObject(obj: unknown): obj is object {
  return obj != null && typeof obj === 'object' && Object.keys(obj).length === 0;
}

export function isBoolean(bool: unknown): bool is boolean {
  return bool != null && typeof bool === 'boolean';
}

export function isMap<k = string, v = any>(m: any): m is Map<k, v> {
  return m instanceof Map;
}

export function isNoBoolean<T>(noBool: T): noBool is Exclude<typeof noBool, boolean> {
  return !isBoolean(noBool);
}

export function compareArrays(arrayA: Array<any>, arrayB: Array<any>): boolean {
  return arrayA.length === arrayB.length && arrayA.every((value, index) => value === arrayB[index]);
}

export function isNoString<T>(noString: T): noString is Exclude<typeof noString, string> {
  return typeof noString !== 'string';
}

export function getEnumKeys(e: EnumType): Array<string> {
  return Object.keys(e).filter((v) => !(parseInt(v, 10) >= 0));
}

export function getEnumNumberValues(e: EnumType): Array<number> {
  return Object.values(e).filter(validateNumber);
}

export function getEnumStringValues(e: EnumType): Array<string> {
  return Object.values(e).filter(validateString);
}

export function formatDurationAsHHMMSS(duration: Duration): string {
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return `${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`;
}

export function formatDurationAsHHHMMSS(duration: Duration): string {
  // to abel to have periods longer then 24hourss
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return `${zeroPad(hours, 3)}:${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`;
}

export function zeroPad(num: number, places: number): string {
  return String(num).padStart(places, '0');
}

export const now = () => {
  return new Date().toISOString().split('T')[1].split('Z')[0];
};

export const varRequired = (nameOfEnvironmentVariable: string): string => {
  const value = process.env[nameOfEnvironmentVariable];
  if (value == null) {
    throw new Error(`Environment variable ${nameOfEnvironmentVariable} is required.`);
  }
  return value;
};

// this function enables regex check for mails with '+'
export function formatMailForRegex(email: string): string {
  return email.replace('+', '\\+');
}

export function isAdmin(user: Immutable<User>): user is Immutable<Admin> {
  return user.admin;
}

export function isLegacyAdmin(user: Immutable<User_Legacy>): user is Immutable<AdminLegacy> {
  return !!user.admin;
}

export function isReseller(user: Immutable<User>): user is Immutable<Reseller> {
  return user.isReseller;
}

export function isLegacyReseller(user: Immutable<User_Legacy>): user is Immutable<ResellerLegacy> {
  return !!user.isReseller;
}

export function isChild<T extends { parentId?: ObjectId | string | null }>(
  user: T,
): user is T & { parentId: ObjectId | string } {
  return user.parentId != null;
}

export function isLegacyChild(user: Immutable<User_Legacy>): user is Immutable<ChildUserLegacy> {
  return user.parentId != null;
}

export function isRegularUser(user: Immutable<User>): user is Immutable<RegularUser> {
  return !isReseller(user) && !isChild(user);
}

export function isLegacyRegularUser(
  user: Immutable<User_Legacy> | null,
): user is Immutable<RegularUserLegacy> {
  return user != null && !isLegacyReseller(user) && !isLegacyChild(user) && !isLegacyAdmin(user);
}

/**
 * Checks if a value is a simple object.
 * @param value - The value to check.
 * @returns True if the value is a simple object, false otherwise.
 */
export function isSimpleObject(value: unknown): value is Record<string, unknown> {
  return value != null && value.constructor === Object;
}
