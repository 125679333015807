import { RacemapEvent } from '@racemap/utilities/types/events';
import { Immutable } from 'immer';

/**
 * Checks if a given time is a valid start or finish time for a Racemap event.
 * @param time - The time to check.
 * @param event - The Racemap event object.
 * @returns A boolean indicating whether the time is valid or not.
 */
export const isValidStartOrFinishTime = (
  time: Date | undefined,
  event: Immutable<RacemapEvent>,
): boolean => {
  if (time == null || event.startTime == null || event.endTime == null) return false;
  return (
    time.getTime() >= new Date(event.startTime).getTime() &&
    time.getTime() <= new Date(event.endTime).getTime()
  );
};
