import { RouteObject } from 'react-router';

export const eventEditorRoutes: Array<RouteObject> = [
  {
    path: 'basic',
    lazy: async () => {
      const { BasicTab } = await import('../components/EventEditor/BasicTab/BasicTab');
      return { Component: BasicTab };
    },
  },
  {
    path: 'map/*',
    lazy: async () => {
      const { MapTab } = await import('../components/EventEditor/MapTab');
      return { Component: MapTab };
    },
  },
  {
    path: 'visualisation',
    lazy: async () => {
      const { VisualisationTab } = await import('../components/EventEditor/VisualisationTab');
      return { Component: VisualisationTab };
    },
  },
  {
    path: 'monitor',
    lazy: async () => {
      const { MonitorTab } = await import('../components/EventEditor/MonitorTab');
      return { Component: MonitorTab };
    },
  },
  {
    path: 'prediction/*',
    lazy: async () => {
      const { PredictionTab } = await import('../components/EventEditor/PredictionTab');
      return { Component: PredictionTab };
    },
  },
  {
    path: 'advanced',
    lazy: async () => {
      const { AdvancedTab } = await import('../components/EventEditor/AdvancedTab');
      return { Component: AdvancedTab };
    },
  },
  {
    path: 'dataapi',
    lazy: async () => {
      const { DataApiTab } = await import('../components/EventEditor/DataApiTab');
      return { Component: DataApiTab };
    },
  },
  {
    path: 'passcode_keys',
    lazy: async () => {
      const { PasscodeKeysTab } = await import(
        '../components/EventEditor/PasscodeKeysTab/PasscodeKeysTab'
      );
      return { Component: PasscodeKeysTab };
    },
  },
  {
    path: 'sponsor',
    lazy: async () => {
      const { AdvertisingTab } = await import('../components/EventEditor/AdvertisingTab');
      return { Component: AdvertisingTab };
    },
  },
  {
    path: 'participants/*',
    lazy: async () => {
      const { ParticipantTab } = await import('../components/EventEditor/ParticipantTab');
      return { Component: ParticipantTab };
    },
  },
  {
    path: 'leaderboard',
    lazy: async () => {
      const { LeaderboardTab } = await import('../components/EventEditor/LeaderboardTab');
      return { Component: LeaderboardTab };
    },
  },
  {
    path: 'analytics',
    lazy: async () => {
      const { AnalyticsTab } = await import('../components/EventEditor/AnalyticsTab/AnalyticsTab');
      return { Component: AnalyticsTab };
    },
  },
  {
    path: 'admin',
    lazy: async () => {
      const { AdminTab } = await import('../components/EventEditor/AdminTab');
      return { Component: AdminTab };
    },
  },
];
