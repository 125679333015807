import { clamp } from '@racemap/utilities/math';
import { EChartsOption, XAXisComponentOption, YAXisComponentOption } from 'echarts';
import ReactECharts, { EChartsInstance } from 'echarts-for-react';

export type XAxisOption<T> = XAXisComponentOption & {
  dataKey: keyof T;
};
export type YAxisOption<T> = YAXisComponentOption & {
  dataKey: keyof T;
};
export type ChartOptions = Partial<EChartsOption>;
export type Series = EChartsOption['series'];
export type DataEntry = Record<string, string | number | Date>;
export type Data<T> = Array<{ data: ReadonlyArray<T>; title: string; color: string }>;
interface DataZoomProps {
  start?: number;
  end?: number;
  startValue?: number;
  endValue?: number;
  type: string;
}

export function XYAreachart<T extends DataEntry>({
  series,
  xAxisOption,
  yAxisOption,
  title,
  onDataZoomChange,
  chartOptions,
  showLoading,
}: {
  series: Series;
  xAxisOption: XAxisOption<T>;
  yAxisOption: YAxisOption<T>;
  title?: string;
  onDataZoomChange?: (start: number | null, end: number | null) => void;
  chartOptions?: ChartOptions;
  showLoading?: boolean;
}) {
  const option = getOption(title || 'AreaChart', series, xAxisOption, yAxisOption, chartOptions);
  const firstSerie = Array.isArray(series) ? series?.[0] : series;

  const handleChartReady = (chartInstance: EChartsInstance) => {
    const dataZoom = chartInstance.getOption().dataZoom;
    if (dataZoom == null || dataZoom.length === 0) return;
    const first = dataZoom[0];
    chartInstance.dispatchAction({
      type: 'dataZoom',
      batch: [{ startValue: first.startValue, endValue: first.endValue, type: 'dataZoom' }],
    });
  };

  const handleDataZoom = (
    props:
      | DataZoomProps
      | {
          batch: Array<DataZoomProps>;
        },
  ) => {
    const actionData = 'batch' in props ? props.batch : [props];
    const { start, startValue, end, endValue, type } = actionData[0];

    if (type === 'datazoom' && onDataZoomChange) {
      if (start === 0 && end === 100) {
        onDataZoomChange(null, null);
      } else if (startValue != null && endValue != null) {
        onDataZoomChange(startValue, endValue);
      } else if (start != null && end != null && firstSerie != null) {
        const maxIndex = Array.isArray(firstSerie?.data) ? firstSerie.data.length - 1 : 0;
        const startIndex = clamp(0, Math.floor((start / 100) * maxIndex), maxIndex);
        const endIndex = clamp(0, Math.floor((end / 100) * maxIndex), maxIndex);

        const startValue = (firstSerie?.data as Record<number, Array<number>> | undefined)?.[
          startIndex
        ]?.[0];
        const endValue = (firstSerie?.data as Record<number, Array<number>> | undefined)?.[
          endIndex
        ]?.[0];

        if (startValue == null || endValue == null) return;
        onDataZoomChange(startValue, endValue);
      }
    }
  };

  return (
    <ReactECharts
      option={option}
      style={{ width: '100%' }}
      lazyUpdate={true}
      onEvents={{ dataZoom: handleDataZoom }}
      onChartReady={handleChartReady}
      showLoading={showLoading}
    />
  );
}

const getOption = <T extends DataEntry>(
  title: string,
  series: EChartsOption['series'],
  xAxis: XAxisOption<T>,
  yAxis: YAxisOption<T>,
  chartOptions?: ChartOptions,
): EChartsOption => {
  return {
    name: title,
    label: title,
    grid: {
      show: true,
    },
    xAxis: {
      type: 'time',
      ...xAxis,
    },
    animation: false,
    yAxis: {
      type: 'value',
      ...yAxis,
    },
    tooltip: {
      trigger: 'axis',
    },
    series,
    ...chartOptions,
  };
};
