import { md5 } from '@racemap/utilities/functions/md5';
import React, { CSSProperties, FC } from 'react';
import { Image } from 'react-bootstrap';

export const UserAvatar: FC<{
  email?: string;
  style?: CSSProperties;
  rounded?: boolean;
}> = ({ email, style = {}, rounded = true }) => {
  let imageSrc = 'https://static-cdn.racemap.com/img/blank_profile.png';

  if (email != null) {
    const emailHash = md5(email.toLowerCase()).toString();
    imageSrc = `https://www.gravatar.com/avatar/${emailHash}?s=50&d=https://static-cdn.racemap.com/img/blank_profile.png`;
  }

  return (
    <Image
      src={imageSrc}
      alt="Profile Pic"
      style={{ width: 50, height: 50, ...style }}
      rounded={rounded}
    />
  );
};
