import styled from '@emotion/styled';
import { RacemapV2APIClient } from '@racemap/sdk';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { isNotEmptyString } from '@racemap/utilities/functions/utils';
import { FC } from 'react';
import useSWR from 'swr';
import { IconAI } from '../../Icon';
import { Devider } from '../Devider/Devider';
import { LoadingIndicator } from '../LoadingIndicator';
import { VStack } from '../MetaComponent';
import { ResultsList } from './ResultsList';
import { SuggestionsList } from './SuggestionsList';

interface Props {
  question: string | null;
  query: string;
  onChange: (newQuestion: string | null) => void;
}

const apiClient = RacemapV2APIClient.fromWindowLocation();
const ask = ([, , query]: Array<string>) => apiClient.getAnswer({ query });

export const AskQuestionPanel: FC<Props> = ({ question, onChange, query }) => {
  const { data: answer, isLoading } = useSWR(
    question != null ? ['ASK', 'QUESTION', question] : null,
    ask,
  );
  const questionPossible = !isLoading && query !== question;

  const foundNoAnswer = !answer?.answer;
  const answerText = answer?.answer?.text || '';
  const followUpQuestions = answer?.answer?.followupQuestions;
  const sources = answer?.answer?.sources;

  return (
    <Container>
      {questionPossible && (
        <AskQuestionCard onClick={() => onChange(query)}>
          <IconAI />
          Ask for "{query}"
        </AskQuestionCard>
      )}
      {isLoading && <LoadingIndicator borderless />}
      {answer && (
        <VStack gap="5px">
          <Title>Answer</Title>
          <Devider bottomSpace="3px" />
          {foundNoAnswer && <AnswerText>Sorry, no answer found.</AnswerText>}
          {isNotEmptyString(answerText) && <AnswerText>{answerText}</AnswerText>}
        </VStack>
      )}
      {sources != null && sources.length > 0 && <ResultsList results={sources} title="Sources" />}
      {followUpQuestions != null && followUpQuestions.length > 0 && (
        <SuggestionsList
          suggestions={followUpQuestions}
          title="Related Questions"
          isLoading={isLoading}
          onSuggestionsClick={(suggestion) => onChange(suggestion)}
          limit={10}
          orderText="Ask"
        />
      )}
    </Container>
  );
};

const Title = styled.h2`
  margin: 0px 5px;
  color: ${RacemapColors.DarkGray};
  font-weight: 500;
  font-size: 1rem;
`;

const AnswerText = styled.div`
  padding: 5px 10px;
  width: 100%;
  color: ${RacemapColors.DarkDarkGray};
  font-weight: 400;
  font-size: 1rem;
  white-space: pre-wrap;
  margin-bottom: 10px;
`;

const Container = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const AskQuestionCard = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid ${RacemapColors.Gray};
    font-weight: 500;
    height: 35px;
    background-color: ${RacemapColors.LightGray};
    color: ${RacemapColors.DarkDarkGray};
    gap: 5px;
    cursor: pointer;

    &:hover {
        background-color: ${RacemapColors.CloudBlue};
    }
`;
