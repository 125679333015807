import styled from '@emotion/styled';
import {
  AuthorizationStates,
  EventTypes,
  VisibilityLabels,
  VisibilityStates,
} from '@racemap/utilities/consts/events';
import { compareNumber } from '@racemap/utilities/functions/compareNumber';
import { RacemapEvent } from '@racemap/utilities/types/events';
import { Alert, Button, Flex, Table as ATable } from 'antd';
import { Immutable } from 'immer';
import { DateTime } from 'luxon';
import React from 'react';
import { useCurrentEvent } from '../lib/customHooks';
import { EventLogo } from './BasicComponents/EventLogo';
import { EventLink } from './BasicComponents/Links';
import { TooltipWithUTCDatetime } from './BasicComponents/Tooltips/TooltipWithUTCDatetime';
import { EventInfoBadges } from './EventInfoBadges';
import { IconArrowDoubleDown, IconArrowDoubleUp, IconTrash } from './Icon';

type Props = {
  events: Immutable<Array<RacemapEvent>>;
  onEventRemove?: (event: Immutable<RacemapEvent>) => void;
  onSwapEvents?: (e1: Immutable<RacemapEvent>, e2: Immutable<RacemapEvent>) => void;
};

const { Column } = ATable;

export const EventList: React.FC<Props> = ({ onEventRemove, onSwapEvents, events }) => {
  const currentEvent = useCurrentEvent();
  const sortedEvents = [...events].sort((eA, eB) => compareNumber(eA.index, eB.index));

  if (currentEvent == null) return null;
  const noEventsActivatedOrVisible = events.every((e) => isNotActivated(e) || isInvisible(e));

  return (
    <Flex gap={7} vertical>
      <Table
        dataSource={sortedEvents}
        pagination={false}
        size="middle"
        rowKey={(record) => record.id}
        bordered
        rowClassName={(record) =>
          isInvisible(record) || isNotActivated(record) ? 'disabled-row' : ''
        }
        onRow={getRowProps}
      >
        <Column dataIndex="id" key="icon" render={(id) => renderImageCell(id)} width={50} />
        <Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(name, _text, index) => renderEventNameCell(name, events[index]?.id)}
        />
        <Column
          dataIndex="startTime"
          key="startTime"
          title="Starttime"
          render={(startTime) => renderDatetimeCell(startTime)}
        />
        <Column
          dataIndex="endTime"
          key="endTime"
          title="Endtime"
          render={(endTime) => renderDatetimeCell(endTime)}
        />
        <Column
          dataIndex="id"
          key="event_state"
          title="State"
          render={(_id, _text, index) => renderBadgeCellVisibility(events[index])}
        />
        {currentEvent?.type !== EventTypes.STAGE_GROUP && (
          <Column
            dataIndex="id"
            key="maintenance"
            className="maintenance-cell"
            title=""
            render={(_id, _text, index) =>
              renderMaintenceCell({ index, events, onEventRemove, onSwapEvents })
            }
            width={140}
          />
        )}
      </Table>
      {noEventsActivatedOrVisible && (
        <Alert
          message="No events are activated or visible! You will not see any events in the player of the group."
          type="error"
          showIcon
        />
      )}
    </Flex>
  );
};

const Table = styled(ATable<Immutable<RacemapEvent>>)` 
  .ant-table-content {
    overflow: auto;
  }

  .ant-table-tbody > tr.disabled-row > td > * {
    opacity: 0.5;
  }

  .ant-table-tbody > tr.disabled-row > .maintenance-cell > * {
    opacity: 1;
  }
`;

const isInvisible = (event: Immutable<RacemapEvent>) =>
  event.visibility === VisibilityStates.DRAFT || event.visibility === VisibilityStates.ARCHIVED;

const isNotActivated = (event: Immutable<RacemapEvent>) =>
  event.authorization === AuthorizationStates.NONE;

const getRowProps = (record: Immutable<RacemapEvent>): { title?: string } => {
  if (record == null) return {};
  if (isInvisible(record))
    return {
      title: `The Event is ${
        VisibilityLabels[record.visibility]
      } State. So it is not visibel for the spectators in the group.`,
    };
  if (isNotActivated(record))
    return {
      title: 'The Event is still trial. So it is not activated yet.',
    };

  return {};
};

const renderMaintenceCell = ({
  index,
  events,
  onEventRemove,
  onSwapEvents,
}: {
  index: number;
  events: Immutable<Array<RacemapEvent>>;
  onSwapEvents: Props['onSwapEvents'];
  onEventRemove: Props['onEventRemove'];
}) => {
  return (
    <Flex align="center" justify="center" gap={5} style={{ height: '100%' }}>
      <Button
        title={'Move event upwards!'}
        disabled={index === 0}
        onClick={() => onSwapEvents?.(events[index], events[index - 1])}
        icon={<IconArrowDoubleUp />}
      />
      <Button
        title={'Move event downwards!'}
        disabled={events.length - 1 === index}
        onClick={() => onSwapEvents?.(events[index], events[index + 1])}
        icon={<IconArrowDoubleDown />}
      />
      <Button
        title={'Remove this item!'}
        onClick={() => onEventRemove?.(events[index])}
        icon={<IconTrash />}
      />
    </Flex>
  );
};

const renderEventNameCell = (name: string, id?: string) => {
  if (id == null) return <span>{name}</span>;
  return <EventLink eventId={id} eventName={name} />;
};

const renderDatetimeCell = (datetime: string) => (
  <TooltipWithUTCDatetime datetime={datetime}>
    <span>{DateTime.fromISO(datetime).toLocaleString(DateTime.DATETIME_MED)}</span>
  </TooltipWithUTCDatetime>
);

const renderImageCell = (eventId: string) => <EventLogo eventId={eventId} size={40} />;

const renderBadgeCellVisibility = (event?: Immutable<RacemapEvent>) => (
  <EventInfoBadges event={event} />
);
