import styled from '@emotion/styled';
import { isEmptyString } from '@racemap/utilities/functions/utils';
import { Alert, Button, Flex, Result, Typography } from 'antd';
import { ResultStatusType } from 'antd/es/result';
import React, { FC } from 'react';
import {
  NavigateFunction,
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from 'react-router-dom';
import shortHash from 'shorthash2';
import { UnauthorizedError } from 'src/lib/Errors';

const { Text, Link } = Typography;

export const ErrorPage: FC = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  console.error(error);

  if (isRouteErrorResponse(error)) {
    return (
      <Result
        status={error.status as ResultStatusType}
        title={error.statusText}
        subTitle={error.data?.message || subTitles[error.status as ResultStatusType]}
        extra={getExtra(error.status as ResultStatusType, navigate)}
      />
    );
  } else if (error instanceof UnauthorizedError) {
    return (
      <Result
        status={403}
        title={error.name}
        subTitle={error.message}
        extra={getExtra(403, navigate)}
      />
    );
  } else if (error instanceof Error) {
    return (
      <Result
        status="error"
        title={error.name}
        subTitle={error.message}
        extra={getExtra('error', navigate, error)}
      />
    );
  } else {
    return (
      <Result
        status="error"
        title="An error occurred"
        subTitle="Unknown error. Please try again later."
        extra={getExtra('error', navigate)}
      />
    );
  }
};

const subTitles: Record<ResultStatusType, string> = {
  403: 'You are not authorized to view this page',
  404: 'The requested page could not be found',
  500: 'An unexpected error occurred',
  info: 'There was an informational message',
  success: 'The operation was successful',
  error: 'An error occurred',
  warning: 'There was a warning',
};

const getExtra = (errorStatus: ResultStatusType, navigate: NavigateFunction, error?: Error) => {
  switch (errorStatus) {
    case 403:
    case 404:
    case 500:
      return (
        <Button type="primary" onClick={() => navigate('/admin')}>
          Back Home
        </Button>
      );
    default:
      if (error) {
        return (
          <Flex justify="center">
            <Alert type="error" message={<ErrorMessage error={error} />} />
          </Flex>
        );
      }

      return (
        <Button type="primary" onClick={() => navigate('/admin')}>
          Back Home
        </Button>
      );
  }
};

const ErrorMessage: FC<{ error: Error }> = ({ error }) => {
  const { message, stack } = error;

  return (
    <StackBox vertical gap={15} align="flex-start">
      <InfoText>
        We apologize for the inconvenience caused by the software issue and try to fix that problem
        as fast as possible. You can help us, if you send us the content of that panel to our
        support mail{' '}
        <Link
          href={`mailto:support@racemap.com${encodeURI(
            `?subject=Frontend Error&body=${getTextVersionOfError(error)}`,
          )}`}
        >
          support@racemap.com
        </Link>
        .
      </InfoText>
      <ErrorMessageContainer strong>
        Error message: <Text code>{message}</Text>
      </ErrorMessageContainer>
      <Text strong>Error stack:</Text>
      <StackEntries>
        {stack?.split('\n').map((line, index) => {
          if (isEmptyString(line)) return null;

          const elements = line.split('@');
          return (
            <React.Fragment key={shortHash(line) + index}>
              <LineNumber>{index}</LineNumber>
              <ErrorMethod strong>{elements[0] || '<anonymous>'}</ErrorMethod>
              <Text>{(elements[1] || '').replace(`${window.origin}/`, '')}</Text>
            </React.Fragment>
          );
        })}
      </StackEntries>
    </StackBox>
  );
};

const getTextVersionOfError = (error: Error) => {
  const { message, stack } = error;
  const now = new Date().toISOString();

  return `
    Error message: ${message}
    Occured at: ${now}
    Error stack:
    ${stack}
  `;
};

const StackBox = styled(Flex)`
    text-align: start;
    max-height: 50vh;
    overflow: auto;
    padding: 10px;
    white-space: nowrap;
    max-width: 900px;
`;

const StackEntries = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 3ch 40ch 1fr;
    gap: 5px;
    overflow: auto;
    padding: 10px;
    white-space: nowrap;
    font-family: monospace;
    text-align: start;
    overflow: auto;
    white-space: nowrap;
`;

const ErrorMessageContainer = styled(Text)`
    white-space: normal;
    overflow: unset;
`;

const LineNumber = styled(Text)`
    font-family: monospace;
    white-space: nowrap;
    overflow: auto;
`;

const ErrorMethod = styled(Text)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const InfoText = styled(Text)`
    white-space: normal;
    overflow: unset;
`;
