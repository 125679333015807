import { simpleHashNumber } from '@racemap/utilities/functions/simpleHashNumber';
import { DeepPartial } from '@racemap/utilities/types/utils';
import type { Draft, Immutable } from 'immer';
import { create } from 'zustand';
import { PersistStorage, StorageValue, persist } from 'zustand/middleware';
import { deleteItem, getItem, setItem } from '../lib/indexed_DB/indexedDB';
import { advancedJSONParse, advancedJSONStringify } from './advancedJSONParseAndStringify';
import { BrandsState, createBrandsStore } from './brands';
import { EventsState, createEventStore } from './events/events_reducers';
import { initializeDB } from './initializeDB';
import { LeaderboardState, createLeaderboardStore } from './leaderboard/leaderboard_reducer';
import { ManageEventsState, createManageEventsStore } from './manageEventsStore';
import { MapsState, createMapStore } from './maps/maps_reducers';
import { MessageTemplatesState, createMessageTemplatesStore } from './messageTemplatesStore';
import { PredictionState, createPredictionStore } from './predictionStore';
import { TrackersState, createTrackerStore } from './trackers/trackers_reducers';
import { UserState, createUserStore } from './user/user_reducers';

// use the commit hash as the version number
declare const __COMMIT_HASH__: string;
const commitHash: string = __COMMIT_HASH__ ?? process.env.GIT_COMMIT_HASH;

console.log('Commit hash:', commitHash);
const commitHashNumber = simpleHashNumber(commitHash);

export type State = Immutable<
  MapsState &
    EventsState &
    TrackersState &
    UserState &
    ManageEventsState &
    PredictionState &
    BrandsState &
    LeaderboardState &
    MessageTemplatesState & {
      _storeRehydrated: boolean;
      setHasHydrated: (state: boolean) => void;
    }
>;
export type DraftState = Draft<State>;

// Storage for persisting the store, using indexedDB
export const storage: PersistStorage<DeepPartial<State>> = {
  getItem: async (name: string): Promise<StorageValue<DeepPartial<State>>> => {
    const item = (await getItem(name)) as string;
    if (!item) {
      return { state: {} };
    }
    return advancedJSONParse(item);
  },
  setItem: async (name: string, value: StorageValue<DeepPartial<State>>): Promise<void> => {
    if (Object.keys(value.state).length === 0) {
      return;
    }
    const jsonString = advancedJSONStringify(value);
    await setItem(name, jsonString);
  },
  removeItem: async (name: string): Promise<void> => {
    await deleteItem(name);
  },
};

// Initialize the indexedDB, and start hydrating the store
initializeDB();

export const useStore = create<State>()(
  persist(
    (set, get) => ({
      ...createMapStore(set, get),
      ...createEventStore(set, get),
      ...createTrackerStore(set, get),
      ...createUserStore(set, get),
      ...createPredictionStore(set, get),
      ...createManageEventsStore(set, get),
      ...createBrandsStore(set),
      ...createLeaderboardStore(set, get),
      ...createMessageTemplatesStore(set, get),
      _storeRehydrated: false,
      setHasHydrated: (state: boolean) => {
        set({ _storeRehydrated: state });
      },
    }),
    {
      name: 'tracker-store',
      partialize: (state) =>
        // Only persist the tracker state if the store has been rehydrated
        state._storeRehydrated
          ? {
              trackers: {
                sampleTrackerIds: state.trackers.sampleTrackerIds,
                selectedTrackerIds: {
                  sample: state.trackers.selectedTrackerIds.sample,
                  total: state.trackers.selectedTrackerIds.total,
                },
                selectedCols: state.trackers.selectedCols,
                showShippingCol: state.trackers.showShippingCol,
                shippingInfo: state.trackers.shippingInfo,
              },
            }
          : {},
      merge: (persistedState, currentState) => {
        // If the persisted state is empty, return the current state
        const persisted = persistedState as State;
        if (!persistedState || !persisted.trackers) {
          return currentState;
        }
        // Merge the persisted state with the current state
        return {
          ...currentState,
          trackers: {
            ...currentState.trackers,
            sampleTrackerIds: persisted.trackers.sampleTrackerIds,
            selectedCols: persisted.trackers.selectedCols,
            selectedTrackerIds: {
              sample: persisted.trackers.selectedTrackerIds?.sample,
              total: persisted.trackers.selectedTrackerIds?.total,
            },
            showShippingCol: persisted.trackers.showShippingCol,
            shippingInfo: persisted.trackers.shippingInfo,
          },
        };
      },
      // when the version changes, the storage is cleared
      version: commitHashNumber,
      storage,
      // skip hydration on first load
      skipHydration: true,
      onRehydrateStorage: () => (state, error) => {
        if (error) {
          console.error('An error occurred during hydration', error);
        } else {
          // if the store has been rehydrated, set the flag
          state?.setHasHydrated(true);
          console.log('Hydration finished.');
        }
      },
    },
  ),
);

const Store = {
  useStore,
};

console.log('Store initialized with number:', commitHashNumber);

export default Store;
