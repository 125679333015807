import { StripeProducts } from '@racemap/sdk/schema/billing';
import { OneMinuteInMillis } from '@racemap/utilities/consts/time';
import { Checkbox, Flex, Space } from 'antd';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { Badge, Col, Form, Row } from 'react-bootstrap';
import {
  useCurrentDateTime,
  useCurrentUser,
  useGetProductPrice,
  useGetProductPricePerUnit,
  useIsAdmin,
  usePriceList,
} from '../../lib/customHooks';
import { formatPrice } from '../../misc/pricing';
import { DatePicker } from '../BasicComponents/DateTimePicker';
import { BillingLink } from '../BasicComponents/Links';
import { LoadingIndicator } from '../BasicComponents/LoadingIndicator';
import { PopoverHint } from '../BasicComponents/PopoverHint';
import {
  IconDatafeed,
  IconDevices,
  IconKey,
  IconLeaderboard,
  IconLoads,
  IconMap,
  IconMonitor,
  IconSponsor,
  IconTag,
  IconUser,
} from '../Icon';
import { LoadingSpinner } from '../LoadingSpinner';
import SmartPanel from '../SmartPanel';
import { InputCountForm } from './InputCountForm';
import { ProductCostRow } from './ProductCostRow';
import { RangeCountForm } from './RangeCountForm';
import { getCurrentProducts } from './getCurrentProducts';
import { getTotalPrice } from './getTotalPrice';

export function CostCalculator() {
  const user = useCurrentUser();
  const isAdmin = useIsAdmin();
  const currentUserId = user?.id;
  const isChildAccount = user != null && user.parentId != null;
  const currentDateTime = useCurrentDateTime(OneMinuteInMillis);
  const [productCounts, setProductCounts] = useState<Partial<Record<StripeProducts, number>>>({});
  const [isReseller, setIsReseller] = useState<boolean>(user?.isReseller || false);
  const [dateTimeOverwrite, setDateTimeOverwrite] = useState<DateTime | null>(null);
  const products = useMemo(
    () => getCurrentProducts(dateTimeOverwrite),
    [dateTimeOverwrite, currentDateTime],
  );

  useEffect(() => {
    const newCounts = structuredClone(productCounts);

    // filter the products, that are gone
    for (const product of Object.keys(newCounts)) {
      if (!products.includes(product as StripeProducts))
        delete newCounts[product as StripeProducts];
    }

    for (const product of products) {
      if (newCounts[product] != null) continue;
      switch (product) {
        case StripeProducts.PAGE_VIEW:
        case StripeProducts.API_OR_LEADERBOARD_LOAD:
          newCounts[product] = 5000;
          break;
        case StripeProducts.MAP_OR_MONITOR_LOAD:
          newCounts[product] = 1000;
          break;
        case StripeProducts.STARTER:
          newCounts[product] = 500;
          break;
        case StripeProducts.GPS_DEVICE:
          newCounts[product] = 10;
          break;
        case StripeProducts.TRANSPONDER:
          newCounts[product] = 50;
          break;
        case StripeProducts.MAP:
          newCounts[product] = 1;
          break;
        default:
          newCounts[product] = 0;
          break;
      }
    }
    setProductCounts(newCounts);
  }, [products]);

  const { priceList, isLoading } = usePriceList({ isReseller });
  const getProductPrice = useGetProductPrice({ isReseller });
  const getPricePerUnit = useGetProductPricePerUnit({ isReseller });

  if (priceList == null && isLoading) return <LoadingSpinner />;
  if (getProductPrice == null || getPricePerUnit == null || isChildAccount) return <></>;

  const handleCountUpdate = (product: StripeProducts, count: number) => {
    setProductCounts({ ...productCounts, [product]: count });
  };
  const totalPrice = getTotalPrice(productCounts, priceList);

  return (
    <div className="p-3">
      {isAdmin && (
        <>
          <Flex justify="space-around" align="center" gap={10}>
            <Space>
              <Checkbox
                checked={isReseller}
                onChange={({ target }: { target: { checked: boolean } }) => {
                  setIsReseller(target.checked);
                }}
              >
                <IconUser style={{ marginRight: '10px' }} />{' '}
                <Badge variant="primary">{isReseller ? 'Reseller' : 'Normal User'}</Badge>
              </Checkbox>
            </Space>

            <Space>
              Referenze Date:
              <DatePicker
                value={dateTimeOverwrite}
                onChange={(date) => setDateTimeOverwrite(date as DateTime | null)}
                placeholder="Select date"
                allowClear
              />
            </Space>

            {isLoading && <LoadingIndicator />}
          </Flex>
          <hr />
        </>
      )}
      <Row className="my-3">
        <InputCountForm
          value={productCounts[StripeProducts.MAP] || 0}
          onChange={(value) => handleCountUpdate(StripeProducts.MAP, value)}
          label={
            <>
              <IconMap className="icon-svg-black icon-copy-btn" /> Tracking Maps
            </>
          }
        />
        {(isReseller || isAdmin) && (
          <>
            <Col sm={1} xs={4} className="cost-calculator-label">
              <Form.Check type="switch" label="" checked={isReseller} disabled />
            </Col>
            <Col sm={4} xs={8} className="cost-calculator-label" style={{ marginLeft: '1%' }}>
              <IconUser style={{ marginRight: '10px' }} />{' '}
              <Badge variant="primary">{isReseller ? 'Reseller' : 'Normal User'}</Badge>
            </Col>
          </>
        )}
      </Row>
      <h5>Add-Ons</h5>
      <Row className="mt-4 mb-3">
        <InputCountForm
          value={productCounts[StripeProducts.MONITOR_ADDON] || 0}
          onChange={(value) => handleCountUpdate(StripeProducts.MONITOR_ADDON, value)}
          max={productCounts[StripeProducts.MAP] || 1}
          label={
            <>
              <IconMonitor className="icon-svg-black icon-copy-btn" /> Monitor Addon
            </>
          }
        />
        <InputCountForm
          value={productCounts[StripeProducts.TIMING_ADDON] || 0}
          onChange={(value) => handleCountUpdate(StripeProducts.TIMING_ADDON, value)}
          max={productCounts[StripeProducts.MAP] || 1}
          label={
            <>
              <IconLeaderboard className="icon-svg-black icon-copy-btn" /> Leaderboards
            </>
          }
        />
      </Row>
      <Row className="mt-4 mb-3">
        <InputCountForm
          value={productCounts[StripeProducts.SPONSOR_ADDON] || 0}
          onChange={(value) => handleCountUpdate(StripeProducts.SPONSOR_ADDON, value)}
          max={productCounts[StripeProducts.MAP] || 1}
          label={
            <>
              <IconSponsor className="icon-svg-black icon-copy-btn" /> Sponsor Logos
            </>
          }
        />
        <InputCountForm
          value={productCounts[StripeProducts.API_ADDON] || 0}
          onChange={(value) => handleCountUpdate(StripeProducts.API_ADDON, value)}
          max={productCounts[StripeProducts.MAP] || 1}
          label={
            <>
              <IconDatafeed className="icon-svg-black icon-copy-btn" /> Data APIs
            </>
          }
        />
      </Row>
      <Row style={{ marginBottom: '1rem' }}>
        <InputCountForm
          value={productCounts[StripeProducts.KEY] || 0}
          max={
            productCounts[
              StripeProducts.STARTER || StripeProducts.GPS_DEVICE + StripeProducts.TRANSPONDER
            ]
          }
          onChange={(value) => handleCountUpdate(StripeProducts.KEY, value)}
          label={
            <>
              <IconKey className="icon-svg-black icon-copy-btn" /> Used Keys
            </>
          }
        />
      </Row>
      <Row>
        <Col>
          {productCounts[StripeProducts.STARTER] != null && (
            <RangeCountForm
              value={productCounts[StripeProducts.STARTER] || 0}
              onChange={(value) => handleCountUpdate(StripeProducts.STARTER, value)}
              max={7500}
              min={0}
              step={10}
              label={
                <>
                  <IconDevices className="icon-svg-black icon-copy-btn" /> Devices (first 500 are
                  free){' '}
                  <PopoverHint title="Devices per account">
                    Devices become chargeable with sending data related to start- and finish time of
                    an activated Tracking Map (base price has been paid). A device is anything that
                    causes a dot in your Tracking Map. A device may be a smart phone, a GPS tracker,
                    Timing equipment such as a transponder, a Track Box or a decoder. A device is
                    charged for a period of 10 days from the first timestamp. If there is more data
                    different from this period additional devices become chargeable.
                  </PopoverHint>
                </>
              }
            />
          )}
          {productCounts[StripeProducts.GPS_DEVICE] != null && (
            <RangeCountForm
              value={productCounts[StripeProducts.GPS_DEVICE] || 0}
              onChange={(value) => handleCountUpdate(StripeProducts.GPS_DEVICE, value)}
              max={1000}
              min={0}
              step={10}
              label={
                <>
                  <IconDevices className="icon-svg-black icon-copy-btn" /> GPS Devices (first 10 are
                  free){' '}
                  <PopoverHint title="Devices per account">
                    Devices become chargeable with sending data related to start- and finish time of
                    an activated Tracking Map (base price has been paid). A device is anything that
                    causes a dot in your Tracking Map. A device may be a smart phone, a GPS tracker,
                    Timing equipment such as a transponder, a Track Box or a decoder. A device is
                    charged for a period of 10 days from the first timestamp. If there is more data
                    different from this period additional devices become chargeable.
                  </PopoverHint>
                </>
              }
            />
          )}
          {productCounts[StripeProducts.TRANSPONDER] != null && (
            <RangeCountForm
              value={productCounts[StripeProducts.TRANSPONDER] || 0}
              onChange={(value) => handleCountUpdate(StripeProducts.TRANSPONDER, value)}
              max={10000}
              min={0}
              step={10}
              label={
                <>
                  <IconTag className="icon-svg-black icon-copy-btn" /> Transponder (first 50 are
                  free){' '}
                  <PopoverHint title="Devices per account">
                    Devices become chargeable with sending data related to start- and finish time of
                    an activated Tracking Map (base price has been paid). A device is anything that
                    causes a dot in your Tracking Map. A device may be a smart phone, a GPS tracker,
                    Timing equipment such as a transponder, a Track Box or a decoder. A device is
                    charged for a period of 10 days from the first timestamp. If there is more data
                    different from this period additional devices become chargeable.
                  </PopoverHint>
                </>
              }
            />
          )}
          {productCounts[StripeProducts.PAGE_VIEW] != null && (
            <RangeCountForm
              value={productCounts[StripeProducts.PAGE_VIEW] || 0}
              onChange={(value) => handleCountUpdate(StripeProducts.PAGE_VIEW, value)}
              max={100000}
              min={0}
              step={100}
              label={
                <>
                  <IconLoads /> Loads (first 5000 are free){' '}
                  <PopoverHint title="Loads per account">
                    Each page view of Tracking Maps and each load of Leaderboards and Data APIs in
                    your account is one load. You may archive your events in order to limit loads.
                  </PopoverHint>
                </>
              }
            />
          )}
          {productCounts[StripeProducts.MAP_OR_MONITOR_LOAD] != null && (
            <RangeCountForm
              value={productCounts[StripeProducts.MAP_OR_MONITOR_LOAD] || 0}
              onChange={(value) => handleCountUpdate(StripeProducts.MAP_OR_MONITOR_LOAD, value)}
              max={100000}
              min={0}
              step={100}
              label={
                <>
                  <IconLoads className="icon-svg-black icon-copy-btn" /> Map and Monitor Loads
                  (first 1000 are free){' '}
                  <PopoverHint title="Loads per account">
                    Each page view of Tracking Maps and each load of Leaderboards and Data APIs in
                    your account is one load. You may archive your events in order to limit loads.
                  </PopoverHint>
                </>
              }
            />
          )}
          {productCounts[StripeProducts.API_OR_LEADERBOARD_LOAD] != null && (
            <RangeCountForm
              value={productCounts[StripeProducts.API_OR_LEADERBOARD_LOAD] || 0}
              onChange={(value) => handleCountUpdate(StripeProducts.API_OR_LEADERBOARD_LOAD, value)}
              max={100000}
              min={0}
              step={100}
              label={
                <>
                  <IconLoads className="icon-svg-black icon-copy-btn" /> API or Leaderboard Loads
                  (first 5000 are free){' '}
                  <PopoverHint title="Loads per account">
                    Each page view of Tracking Maps and each load of Leaderboards and Data APIs in
                    your account is one load. You may archive your events in order to limit loads.
                  </PopoverHint>
                </>
              }
            />
          )}
        </Col>
      </Row>
      <Row className="mx-3 mb-4">
        <Col sm={4} xs={6}>
          Total Price: <br />
          <h5>{formatPrice(totalPrice)}</h5>
        </Col>
        <Col sm={4} xs={8}>
          Effective price per device: <br />
          <h5>{formatPrice(totalPrice / (productCounts.starter || 1))} </h5>
        </Col>
      </Row>
      {currentUserId != null && (
        <div className="mx-3 mb-4">
          Find your current billing overview on your{' '}
          <BillingLink userId={currentUserId} label="billing page" />.
        </div>
      )}
      <SmartPanel title="Specific Costs" withActivation={false}>
        {Object.entries(productCounts)
          .filter(([_p, c]) => c !== 0)
          .map(([product, count], index, countArray) => (
            <ProductCostRow
              key={product}
              product={product as StripeProducts}
              quantity={count || 0}
              price={priceList?.[product as StripeProducts]}
              getPricePerUnit={getPricePerUnit}
              getProductPrice={getProductPrice}
              lastEntry={index === countArray.length - 1}
            />
          ))}
      </SmartPanel>
    </div>
  );
}
