import { FC } from 'react';
import { Navigate, Outlet, RouteProps, useLocation } from 'react-router';
import { useStore } from '../../store/reducers';

export const BillingView: FC<RouteProps> = () => {
  const location = useLocation();
  const currentUser = useStore((s) => s.users.getter.currentUser());
  const userIdMatch = location.pathname.match(/\/admin\/billing\/([a-f0-9]{24})/);
  const search = location.search;

  if (currentUser == null) return <></>;
  // redirect for legacy route TODO: remove in the future
  if (location.pathname === '/admin/billing' || userIdMatch != null) {
    const userId = userIdMatch?.[1] || currentUser.id;
    return <Navigate to={`/admin/account/${userId}/billing${search}`} />;
  }

  return <Outlet />;
};
