export class PasswordMissmatchError extends Error {
  constructor() {
    super('The passwords are not identical.');
    this.name = 'PasswordMissmatchError';
  }
}

export class EmptyFieldsError extends Error {
  constructor() {
    super('Please fill out all fields.');
    this.name = 'EmptyFieldsError';
  }
}

export class UnauthorizedError extends Error {
  constructor() {
    super('You are not authorized to view this page');
    this.name = 'UnauthorizedError';
  }
}

export default {
  PasswordMissmatchError,
  EmptyFieldsError,
};
