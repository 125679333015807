import styled from '@emotion/styled';
import { ReactElement, useRef } from 'react';
import ReactSelect, { GroupBase, Props as SelectProps, StylesConfig } from 'react-select';
import { useElementPosition } from '../../../lib/customHooks';

export type Props<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = SelectProps<Option, IsMulti, Group>;

export function getRacemapSelectStyles<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(containerBoundings: DOMRect): StylesConfig<Option, IsMulti, Group> {
  return {
    container: (provided) => ({
      ...provided,
      position: 'relative',
      height: '33.5px',
      minHeight: '33.5px',
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      height: '33.5px',
      minHeight: '33.5px',
      maxHeight: '33.5px',
      alignItems: 'baseline',
      borderColor: '#ced4da',
    }),
    menuList: (provided) => ({ ...provided, padding: 0, overflowX: 'hidden' }),
    menu: (provided) => ({
      ...provided,
      margin: 0,
      zIndex: 3,
      position: 'fixed',
      top: `${containerBoundings.bottom}px`,
      right: `${window.innerWidth - containerBoundings.right}px`,
      width: `${containerBoundings.width}px`,
      minWidth: '200px',
    }),
    option: (provided) => ({
      ...provided,
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
    }),
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '31.5px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '31px',
      minHeight: '31px',
      gridTemplateRows: '100%',
      overflow: 'hidden',
      maxWidth: '100%',
    }),
  };
}

export const Select = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  styles,
  ...props
}: Props<Option, IsMulti, Group>): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null);
  const containerPosition = useElementPosition(containerRef);

  const racemapSelectStyles = getRacemapSelectStyles<Option, IsMulti, Group>(containerPosition);
  const combinedStyles = {
    ...racemapSelectStyles,
    ...styles,
  };

  return (
    <Container ref={containerRef}>
      <ReactSelect styles={combinedStyles} {...props} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;
