import styled from '@emotion/styled';
import React, { FC } from 'react';
import { OverlayTrigger, OverlayTriggerProps, Popover } from 'react-bootstrap';
import { Center } from '../MetaComponent';
import { type Props as StringListEditorProps, StringListEditor } from './StringListEditor';

export interface Props extends StringListEditorProps {
  title: string;
  placement?: OverlayTriggerProps['placement'];
}

export const StringListPopover: FC<Props> = ({
  placement = 'top',
  value,
  title,
  onChange,
  ...props
}) => {
  const popover = (
    <Popover id="popover-basic" style={{ minWidth: '400px' }}>
      <Popover.Title as="h5">{title}</Popover.Title>
      <Popover.Content>
        <StringListEditor value={value} onChange={onChange} {...props} />
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement={placement}
      overlay={popover}
      rootClose
      rootCloseEvent="mousedown"
    >
      <Container>{value.filter((v) => v != null && v !== '').length} entries</Container>
    </OverlayTrigger>
  );
};

const Container = styled(Center)`
  height: 100%;
  width: 100%;
`;
