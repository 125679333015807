import styled from '@emotion/styled';
import { groupBy } from '@racemap/utilities/functions/utils';
import React, { FC, ReactNode } from 'react';
import { type Props as SidebarSectionProps, SidebarSection } from './SidebarSection';
import { getSection } from './utils';

export enum TabState {
  NONE = 'NONE',
  ACTIVATED = 'ACTIVATED',
  ENABLED = 'ENABLED',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface Props {
  tabs: Tabs;
  sections?: Sections;
  onTabChoosing?: SidebarSectionProps['onTabChoosing'];
}

export interface Section {
  key: string | number;
  label: string;
  status?: 'success' | 'error' | 'primary' | 'secondary';
}
export type Sections = Array<Section>;

export interface Tab {
  title: string;
  path: string;
  icon: React.ReactNode;
  state?: TabState;
  sectionKey?: string | number;
  badge?: ReactNode;
}
export type Tabs = Array<Tab>;

export const Sidebar: FC<Props> = ({ tabs, sections = [], onTabChoosing }) => {
  const tabsGrouped = groupBy(tabs, (tab) => getSection(tab.sectionKey, sections)?.key);
  const tabsSorted = Array.from(tabsGrouped.entries()).sort(
    ([k1], [k2]) =>
      sections.findIndex((s) => s.key === k1) - sections.findIndex((s) => s.key === k2),
  );

  return (
    <Container>
      {tabsSorted.map(([k, sectionTabs]) => {
        const section = sections.find((s) => s.key === k);
        return (
          <SidebarSection
            key={k == null ? 'no-section' : k.toString()}
            tabs={sectionTabs}
            section={section}
            onTabChoosing={onTabChoosing}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  a:hover {
    color: unset;
  }
`;
