import styled from '@emotion/styled';
import React, { FC, useEffect, useState } from 'react';
import { FormCheck } from '../../FormComponents';
import { HStack } from '../MetaComponent';

interface Props {
  id: string;
  children: React.ReactNode;
  value?: boolean;
  onChange?: (newValue: boolean) => void;
}

export const FormCheckText: FC<Props> = ({ id, value = false, onChange, children }) => {
  const [cacheValue, setCacheValue] = useState(value);

  useEffect(() => {
    setCacheValue(value);
  }, [value]);

  const handleChange = (newValue: boolean) => {
    if (newValue !== cacheValue) {
      setCacheValue(newValue);
      onChange?.(newValue);
    }
  };

  return (
    <Container>
      <FormCheck id={id} value={cacheValue} type="checkbox" onChange={handleChange} />
      <Text checked={cacheValue}>{children}</Text>
    </Container>
  );
};

const Container = styled(HStack)`
  justify-content: flex-start;
`;

const Text = styled.div<{ checked: boolean }>`
  margin-left: 10px;
`;
