class FileDialog {
  show({
    multiple = false,
    accept,
  }: { multiple?: boolean; accept?: string } = {}): Promise<FileList> {
    return new Promise((resolve, reject) => {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.multiple = multiple;
      if (accept != null) {
        fileInput.accept = accept;
      }
      fileInput.onchange = () => {
        if (fileInput.files == null) return [];

        if (fileInput.files.length > 0) {
          resolve(fileInput.files);
        } else {
          resolve(new FileList());
        }
      };

      fileInput.oncancel = () => {
        reject(new Error('CANCELLED'));
      };

      fileInput.onerror = (e) => {
        reject(e);
      };

      fileInput.click();
    });
  }
}

export default new FileDialog();
