import { formatTimeDuration, timeDurationToMilliseconds } from '@racemap/utilities/formatting';
import { isNoBoolean } from '@racemap/utilities/functions/utils';
import { isDefined, isNotNull } from '@racemap/utilities/functions/validation';
import { Flex, Space } from 'antd';
import { Immutable } from 'immer';
import { isArray } from 'lodash';
import moment from 'moment';
import React, {
  CSSProperties,
  ComponentType,
  FocusEventHandler,
  InputHTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  ButtonGroup,
  FormCheck as BtFormCheck,
  FormCheckProps as BSFormCheckProps,
  FormControl,
  FormLabel,
  InputGroup,
} from 'react-bootstrap';
import {
  GroupBase,
  InputProps,
  Options,
  ReactSelectProps,
  Select,
  SingleValue,
  components,
} from '../components/BasicComponents/Select';
import { TooltipWithUTCDatetime } from './BasicComponents/Tooltips/TooltipWithUTCDatetime';
import { toastError } from './utils/Utils';

function createLabel(label?: React.ReactNode, required?: boolean, hint?: React.ReactNode) {
  if (label == null) return <></>;
  switch (typeof label) {
    case 'string':
    case 'boolean':
    case 'number':
      return (
        <FormLabel>
          <Space size="small">
            {label}
            {required ? '*' : ''}
            {hint}
          </Space>
        </FormLabel>
      );
    case 'object':
      return (
        <>
          {label}
          {hint}
        </>
      );
    default:
      return <></>;
  }
}

// workaround, in the popover links seems to get blocket. that component takes the same props,
// but trigger the location change with javascript
export function PopoverLink({
  href,
  target = '',
  rel = '',
  children,
}: {
  href: string;
  target?: string;
  rel?: string;
  children: React.ReactNode;
}) {
  return (
    <a href={href} target={target} rel={rel}>
      {children}
    </a>
  );
}

type FormGroupProps = React.HTMLProps<HTMLDivElement>;

export function FormGroup({ children, ...props }: FormGroupProps) {
  return (
    <div className="form-group" {...props}>
      {children}
    </div>
  );
}

export interface TextInputProps<T>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'size' | 'value'> {
  value?: T;
  hint?: React.ReactNode;
  label?: React.ReactNode;
  onChange?: (arg0: T, arg1?: boolean) => void;
  onDetailsChange?: (arg0: { value: T; name: string }, arg1?: boolean) => void;
  groupStyle?: CSSProperties;
  size?: 'sm' | 'lg';
  unit?: string;
  rows?: number;
}

export function FormInput<T extends string | number | Array<string> | undefined = string>({
  type = 'text',
  hint,
  label,
  onChange = noop,
  onDetailsChange = noop,
  required = false,
  disabled = false,
  className = '',
  style = {},
  groupStyle = {},
  unit,
  ...props
}: TextInputProps<T>) {
  return (
    <FormGroup style={groupStyle}>
      <Flex gap={5} align="center">
        {createLabel(label, required, hint)}
      </Flex>
      <InputGroup>
        <FormControl
          className={className}
          type={type}
          onChange={({ target }) => {
            const {
              validity: { valid },
              value,
              name,
            } = target;
            if (value != null) onChange(value as unknown as T, valid);
            if (value != null) onDetailsChange({ value: value as unknown as T, name }, valid);
          }}
          required={required}
          disabled={disabled}
          style={style}
          {...props}
        />
        {unit && (
          <InputGroup.Append>
            <InputGroup.Text>{unit}</InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
    </FormGroup>
  );
}

export function FormNumberInput(props: TextInputProps<string>): React.ReactElement {
  return (
    <FormInput
      type="number"
      {...props}
      value={props.value?.toString() || ''}
      onChange={(newValue: string, valid) => {
        const { pattern } = props;
        const isPatternCheckValid = pattern == null || new RegExp(pattern).test(newValue);
        props.onChange?.(newValue, isPatternCheckValid && valid);
      }}
    />
  );
}

type TextInputWithButtonProps<T> = TextInputProps<T> & {
  buttonLabel: React.ReactNode;
  onButtonClick: () => void;
};

export function FormInputWithButton({
  type = 'text',
  label,
  buttonLabel,
  onButtonClick,
  onChange = noop,
  required = false,
  disabled = false,
  className = '',
  ...props
}: TextInputWithButtonProps<string>) {
  return (
    <FormGroup>
      {createLabel(label)}
      <InputGroup>
        <FormControl
          className={className}
          type={type}
          onChange={(event) => onChange(event.target.value)}
          required={required}
          disabled={disabled}
          {...props}
        />
        <InputGroup.Append>
          <Button onClick={onButtonClick}>{buttonLabel}</Button>
        </InputGroup.Append>
      </InputGroup>
    </FormGroup>
  );
}

export function FormTextarea({
  hint,
  label,
  onChange = noop,
  required = false,
  groupStyle = {},
  ...props
}: TextInputProps<string>) {
  return (
    <FormGroup style={groupStyle}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {createLabel(label, required, hint)}
      </div>
      <textarea
        className="form-control"
        onChange={(event) => onChange(event.target.value)}
        required={required}
        {...props}
      />
    </FormGroup>
  );
}

export function FormInputWithAddon({
  type = 'text',
  iconComponent: IconComponent,
  onChange = noop,
  required = false,
  ...props
}: TextInputProps<string> & {
  iconComponent: ComponentType;
}) {
  return (
    <FormGroup>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <IconComponent />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          type={type}
          onChange={(event) => onChange(event.target.value)}
          required={required}
          {...props}
        />
      </InputGroup>
    </FormGroup>
  );
}

export interface FormInputDateTimeProps extends TextInputProps<string> {
  value: string;
  onChange?: (arg0: string) => void;
  startOfUnit?: 'second' | 'minute' | 'day';
  withTooltip?: boolean;
  tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
  localizeTimeLocation?: [number, number] | null;
  type?: 'datetime-local' | 'datetime' | 'date' | 'time' | 'month';
}

export function FormInputDateTime({
  value,
  onChange = noop,
  startOfUnit = 'second',
  withTooltip = false,
  tooltipPosition = 'top',
  localizeTimeLocation = null,
  type = 'datetime-local',
  ...props
}: FormInputDateTimeProps) {
  const step =
    startOfUnit === 'second'
      ? 1
      : startOfUnit === 'minute'
      ? 60
      : startOfUnit === 'day'
      ? 86400
      : 1;

  const formattedValue =
    type === 'date'
      ? moment(value).format('YYYY-MM-DD')
      : type === 'time'
      ? moment(value).format('HH:mm:ss')
      : type === 'month'
      ? moment(value).format('YYYY-MM')
      : moment(value).format('YYYY-MM-DDTHH:mm:ss');

  const formInput = useMemo(
    () => (
      <BlurFormInput
        type={type}
        value={formattedValue}
        step={String(step)}
        onChange={(newValue: string) => {
          onChange(
            moment(newValue).isValid()
              ? moment(newValue).startOf(startOfUnit).toISOString()
              : newValue,
          );
        }}
        {...props}
      />
    ),
    [startOfUnit, value, onChange],
  );

  if (!withTooltip) {
    return formInput;
  }

  return (
    <TooltipWithUTCDatetime
      datetime={value}
      tooltipPosition={tooltipPosition}
      localizeTimeLocation={localizeTimeLocation}
    >
      {formInput}
    </TooltipWithUTCDatetime>
  );
}

export function FormInputTime({
  value,
  onChange,
  ...props
}: {
  value: number;
  onChange: (arg0: number) => void;
}) {
  const formInput = (
    <BlurFormInput
      type="text"
      value={value != null && value === -1 ? '-1' : value != null ? formatTimeDuration(value) : ''}
      step={1}
      onChange={(newValue) =>
        newValue !== '' && newValue === '-1'
          ? onChange(-1)
          : newValue !== '' && isNaN(timeDurationToMilliseconds(newValue))
          ? toastError('The format for the manual result have to be hh:mm:ss.')
          : onChange(timeDurationToMilliseconds(newValue))
      }
      {...props}
    />
  );

  return formInput;
}

const getSelectCustomStyles = function (isMulti: boolean) {
  const styles: Partial<Styles<OptionTypeBase, boolean>> = {
    container: (provided) => ({
      ...provided,
      width: '100%',
      padding: 0,
      transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      height: isMulti ? undefined : 33.5,
    }),
    control: (provided, state) => {
      const out = {
        ...provided,
        minHeight: '33.5px',
        alignItems: 'center',
        borderColor: state.isFocused ? '#7eb1d2' : '#ced4da',
        boxShadow: state.isFocused && '0 0 0 0.2rem rgb(54 115 154 / 25%)',
        ':hover': {
          borderColor: state.isFocused ? '#7eb1d2' : '#ced4da',
        },
      };

      return out;
    },
    menuList: (provided) => ({ ...provided }),
    menu: (provided) => ({ ...provided, zIndex: 3 }),
    option: (provided) => ({
      ...provided,
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '1px',
    }),
  };

  return styles;
};

interface SelectProps<T extends string | null> extends Omit<ReactSelectProps, 'onChange'> {
  hint?: string | React.ReactNode;
  label?: string | React.ReactNode;
  onChange?: (arg0: T) => void;
  value?: T;
  children: Array<React.ReactElement<HTMLOptionElement> | null | undefined | boolean>;
  isClearable?: boolean;
  containerStyle?: CSSProperties;
  required?: boolean;
}

interface SelectOptionType {
  readonly label: string;
  readonly value: string;
}

export const FormSelect = <T extends string | null = string | null>({
  children,
  hint,
  label,
  value,
  required,
  onChange = noop,
  id,
  containerStyle = {},
  ...props
}: SelectProps<T>) => {
  const validChildren = children
    .filter(isDefined)
    .filter(isNotNull)
    .filter(isNoBoolean)
    .filter((c) => c.props != null);
  let options: Options<SelectOptionType> = [];
  if (isArray(children)) {
    options = validChildren.map((c) => ({
      value: c.props.value,
      label: String(c.props.children),
    }));
  }
  const choosedValue = options.find((o: SelectOptionType) => o.value === value);

  return (
    <div style={{ width: '100%', marginBottom: '1rem', ...containerStyle }}>
      <Flex gap={5}>{createLabel(label, required, hint)}</Flex>
      <Select<SelectOptionType, false, GroupBase<SelectOptionType>>
        onChange={(choosedOption) => onChange(choosedOption?.value || null)}
        options={options}
        value={choosedValue}
        styles={getSelectCustomStyles(false)}
        classNamePrefix="list"
        required={required}
        inputId={id}
        components={{ Input }}
        {...props}
      />
    </div>
  );
};

const Input = (props: InputProps<SelectOptionType, false, GroupBase<SelectOptionType>>) => {
  const value = props.selectProps.value as SingleValue<SelectOptionType>;

  return (
    <components.Input
      {...props}
      required={props.selectProps.required}
      value={value?.label || props.selectProps.inputValue}
    />
  );
};

interface MultiSelectProps extends Omit<ReactSelectProps, 'onChange'> {
  label?: string;
  required?: boolean;
  children: Array<React.ReactElement<HTMLOptionElement>>;
  value: Array<string> | Immutable<Array<string>>;
  onChange: (newValues: Array<string>) => void;
  hint?: React.ReactNode;
  containerStyle?: CSSProperties;
}

export function FormMultiSelect({
  value,
  label,
  onChange = noop,
  closeMenuOnSelect = false,
  required = false,
  children,
  hint,
  containerStyle = {},
  ...props
}: MultiSelectProps) {
  const options = children
    .filter((c) => c != null)
    .map((c) => ({
      value: c.props.value,
      label: String(c.props.children),
    }));
  const choosedValues = options.filter((o) => value?.includes(o.value));

  return (
    <div style={{ width: '100%', marginBottom: '1rem', ...containerStyle }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {createLabel(label, required, hint)}
      </div>
      <Select
        isClearable
        isMulti
        closeMenuOnSelect={closeMenuOnSelect}
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={(newValue) =>
          onChange(newValue != null ? newValue.map((i: OptionTypeBase) => i.value) : [])
        }
        value={choosedValues}
        styles={getSelectCustomStyles(true)}
        {...props}
      />
    </div>
  );
}

interface FormCheckProps extends Omit<BSFormCheckProps, 'onChange' | 'value' | 'label'> {
  onChange?: (checked: boolean) => void;
  value: boolean;
  required?: boolean;
  label?: React.ReactNode | string;
  labelstyle?: CSSProperties;
  hint?: React.ReactNode;
}

export function FormCheck({
  onChange = noop,
  value,
  required = false,
  label,
  labelstyle,
  hint,
  ...props
}: FormCheckProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(!!event.target.checked);
  };
  const labelWithRequiredFlag = (
    <div style={labelstyle}>
      {label}
      {required ? '*' : ''}
      {hint}
    </div>
  );

  return (
    <div>
      <BtFormCheck
        style={{ cursor: 'pointer', marginRight: '2px' }}
        type={props.type || 'switch'}
        label={labelWithRequiredFlag}
        checked={value}
        onChange={handleChange}
        required={required}
        {...props}
      />
    </div>
  );
}

export interface BlurFormInputProps<T> extends TextInputProps<T> {
  onChange?: (newValue: T, valid?: boolean) => void;
  value?: T;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  children: React.ReactElement;
}

export const BlurFormInputWrapper = <T,>({
  children,
  onChange,
  value,
  ...rest
}: BlurFormInputProps<T>) => {
  const [intermediateValue, setIntermediateValue] = useState<T | undefined>(value);
  const [isValid, setValidity] = useState<boolean>(true);

  const handleValueChange = (newValue?: T, valid = true) => {
    setIntermediateValue(newValue);
    setValidity(valid);
  };

  useEffect(() => {
    handleValueChange(value, true);
  }, [value]);

  return (
    <>
      {React.cloneElement(children, {
        ...rest,
        onChange: (newValue: T, valid = true) => {
          handleValueChange(newValue, valid);
        },
        onBlur: () => {
          if (!isValid) {
            setIntermediateValue(value);
            return toastError('Your input is invalid. Please change!');
          }
          if (onChange != null && intermediateValue != null) {
            onChange(intermediateValue, isValid);
          }
        },
        value: intermediateValue,
      })}
    </>
  );
};

export const BlurFormInput = function <T extends string | null | undefined = string>(
  props: TextInputProps<T>,
) {
  return (
    <BlurFormInputWrapper<T> {...props}>
      <FormInput />
    </BlurFormInputWrapper>
  );
};

export const BlurFormTextarea = function <T extends string | null | undefined = string>(
  props: TextInputProps<string>,
) {
  return (
    <BlurFormInputWrapper<string> {...props}>
      <FormTextarea />
    </BlurFormInputWrapper>
  );
};

export const BlurFormNumberInput = function <T extends string | null | undefined = string>(
  props: TextInputProps<string>,
) {
  return (
    <BlurFormInputWrapper<string> {...props}>
      <FormNumberInput />
    </BlurFormInputWrapper>
  );
};

export function FormInputUrl(props: TextInputProps<string>) {
  const { onChange, ...rest } = props;

  function makeUrl(value: string) {
    if (value) {
      if (value.match(/^https?:\/\//)) {
        return value;
      } else {
        return `http://${value}`;
      }
    } else {
      return value;
    }
  }

  return <BlurFormInput onChange={(newValue: string) => onChange(makeUrl(newValue))} {...rest} />;
}

type RadioButtonValue<T> = {
  value: T;
  title: React.ReactNode;
  disabled?: boolean;
};

export function RadioButtons<T>({
  options,
  value,
  label,
  onHover,
  onChange,
  groupStyle = {},
  style = {},
  buttonStyle = {},
  ...restProps
}: {
  value: T;
  options: Array<RadioButtonValue<T>>;
  label?: string;
  onChange: (arg0: T) => void;
  onHover?: (arg0: T | null | undefined) => void;
  groupStyle?: CSSProperties;
  style?: CSSProperties;
  buttonStyle?: CSSProperties;
}) {
  // This uses a dirty hack to use <a> tags, because they permit
  // pointer events even on disabled elements
  return (
    <FormGroup style={{ width: '100%', ...style }}>
      {label != null && createLabel(label)}
      <ButtonGroup style={{ width: '100%', background: 'white', ...groupStyle }} {...restProps}>
        {options.map((a: RadioButtonValue<T>) => (
          <Button
            href="#"
            key={String(a.value)}
            variant={value === a.value ? 'primary' : 'outline-primary'}
            disabled={a.disabled}
            style={{ pointerEvents: 'auto', width: `${~~(100 / options.length)}%`, ...buttonStyle }}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (!a.disabled) {
                onChange(a.value);
              }
            }}
            onMouseOver={() => {
              if (onHover != null) {
                onHover(a.value);
              }
            }}
            onMouseOut={() => {
              if (onHover != null) {
                onHover(null);
              }
            }}
          >
            {a.title}
          </Button>
        ))}
      </ButtonGroup>
    </FormGroup>
  );
}

function noop() {}
