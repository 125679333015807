import { EventTypes } from '@racemap/utilities/consts/events';
import { exists } from '@racemap/utilities/functions/utils';
import { RacemapEvent } from '@racemap/utilities/types/events';
import { Immutable } from 'immer';
import { FC } from 'react';
import { Col, FormLabel, Row } from 'react-bootstrap';
import { useBrands } from '../lib/customHooks';
import { useStore } from '../store/reducers';
import { StringListEditor } from './BasicComponents/StringListEditor';
import { FormGroup } from './FormComponents';
import { getApplicationName } from './utils/helpers';

interface Props {
  event: Immutable<RacemapEvent>;
}

export const AddEventToCustomBrands: FC<Props> = ({ event }) => {
  const { brands } = useBrands();
  const { updateEvent, loadEvent } = useStore((s) => s.events.actions);
  if (!brands) return null;

  const possibleBrands: Array<string> = brands
    .map((brand) => getApplicationName(brand))
    .filter(exists);

  const currentEventBrands = event.modules.brandedAppIds.map((brandedAppId) =>
    brands.find((brand) => brandedAppId === brand.id),
  );
  const currentEventBrandNames = currentEventBrands
    .map((brand) => getApplicationName(brand))
    .filter(exists);

  const onBrandsChange = (newValue: Array<string>) => {
    const brandIds = newValue
      .map((appName) => {
        const brand = brands.find((brand) => appName === getApplicationName(brand));
        return brand?.id;
      })
      .filter(exists);
    updateEvent(event.id, [{ key: 'modules.brandedAppIds', newValue: brandIds }]);
    if (event.type === EventTypes.CONTEST_GROUP) {
      loadEvent(event.id);
    }
  };

  return (
    <div>
      {possibleBrands.length > 0 && (
        <Row>
          <Col>
            <FormGroup id="racemap_custom_brands">
              <FormLabel>Show in Custom Apps</FormLabel>
              <StringListEditor
                value={currentEventBrandNames}
                onChange={onBrandsChange}
                options={possibleBrands}
                allowOnlyOptions
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </div>
  );
};
