import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { AuthorizationStates } from '@racemap/utilities/consts/events';
import { isEmptyString, sleep } from '@racemap/utilities/functions/utils';
import { User_Legacy } from '@racemap/utilities/types/users';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button } from 'antd';
import { Immutable } from 'immer';
import { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useInterval } from 'usehooks-ts';
import { CurrentEvent } from '../../../store/events/events_reducers';
import { useStore } from '../../../store/reducers';
import { LoadingIndicator } from '../../BasicComponents/LoadingIndicator';
import { IconArrowLeft, IconOk } from '../../Icon';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const publicKey =
  window.location.origin !== 'https://racemap.com'
    ? 'pk_test_a5YN3NS4wfpVtSwHbOx67CEb00xRzNlVCW'
    : 'pk_live_IHKLCoVN3GEWqkTfBijqv1Kh00MhcgtToA';
const stripePromise = loadStripe(publicKey);

interface Props {
  user: Immutable<User_Legacy>;
  event: Immutable<CurrentEvent>;
  onBack: () => void;
  onCancel: () => void;
  onNext: () => void;
}

export const EmbeddedStripeView: FC<Props> = ({ event, onCancel, onBack, onNext }) => {
  const [clientSecret, setClientSecret] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [sessionState, setSessionState] = useState<'complete' | 'open' | 'unknown'>('unknown');
  const [error, setError] = useState('');
  const { loadCurrentEvent } = useStore((s) => s.events.actions);
  const isSessionComplet = sessionState === 'complete';
  const isEventPaid = event.authorization === AuthorizationStates.PAID;

  const createCheckoutSession = async () => {
    setError('');
    const state = await checkSessionState();
    if (state === 'complete') return;

    const response = await fetch('/api/billing/action/create_checkout_session', {
      method: 'POST',
      body: JSON.stringify({ eventId: event.id, returnTo: window.location.pathname }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      setError('Failed to create checkout session');
    }

    const data = await response.json();
    setClientSecret(data.clientSecret);
    setSessionId(data.sessionId);
  };

  const checkSessionState = async (): Promise<'open' | 'complete'> => {
    if (isEmptyString(sessionId)) return 'open';
    const response = await fetch(
      `/api/billing/action/check_session_state?sessionId=${sessionId}&eventId=${event.id}`,
    );
    const data = await response.json();
    setSessionState(data.status);

    return data.status;
  };

  const handleSessionComplete = async () => {
    console.log('Session completed');
    await sleep(2000);
    await checkSessionState();
  };

  useEffect(() => {
    createCheckoutSession();
  }, []);

  useInterval(
    () => {
      checkSessionState();
    },
    sessionState !== 'complete' ? 5000 : null,
  );

  useInterval(
    () => {
      if (sessionState === 'complete') {
        loadCurrentEvent(event.id);
      }
    },
    event.authorization !== 'PAID' ? 10000 : null,
  );

  return (
    <>
      <Modal.Header>
        <Modal.Title>2. Pay base price</Modal.Title>
      </Modal.Header>

      <ModalBody>
        {clientSecret ? (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret, onComplete: handleSessionComplete }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        ) : error ? (
          <ErrorContainer>
            {error}
            <Button onClick={createCheckoutSession}>Retry</Button>
          </ErrorContainer>
        ) : (
          <LoadingContainer>
            <LoadingIndicator borderless />
          </LoadingContainer>
        )}
      </ModalBody>

      <Modal.Footer>
        <Button onClick={onCancel}>Cancel</Button>
        {!isSessionComplet || !isEventPaid ? (
          <Button onClick={onBack} icon={<IconArrowLeft />} type="default">
            Back
          </Button>
        ) : !isEventPaid ? (
          <Button loading type="primary">
            Processing
          </Button>
        ) : (
          <Button onClick={onNext} icon={<IconOk />} type="primary">
            Complete
          </Button>
        )}
      </Modal.Footer>
    </>
  );
};

const ModalBody = styled(Modal.Body)`
  width: 450px
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 500px;
`;

const ErrorContainer = styled.div`  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: 500px;
  font-size: 1.3em;
  color: ${RacemapColors.DarkGray};
  gap: 10px;
`;
